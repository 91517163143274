import React from 'react';
import { Input } from '@amzn/awsui-components-react';
import HelpInfoLink from '../help/HelpInfoLink';
import { replacementTokensHelp } from '../help/HelpContent';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

class DisplayText extends React.Component<Props> {
    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveValue = 'col-xxxs-12 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Display Text</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Primary Text:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="pri_txt"
                            value={this.props.interlude.pri_txt}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Secondary Text:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="sec_txt"
                            value={this.props.interlude.sec_txt}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="awsui-util-container-footer">
                    You can use replacement tokens.
                    <HelpInfoLink content={replacementTokensHelp()} />
                </div>
            </div>
        );
    }
}

export default DisplayText;
