import { ColumnLayout, Container, FormField, Grid, Select, SelectProps } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { Interlude, InterludeSchedule } from '../../data-types';
import { PropsWithDataStage } from '../StageContext';
import _ from 'lodash';
import { FieldError, SectionHeader, TimeField, ToggleField } from '../form/Fields';
import SelectDateTime from '../form/SelectDateTime';
import { ALL_DAYS, ScheduleForm, ALL_DAYS_FIELD_MAPPING, formToModel, modelToForm } from '../form/ScheduleForm';
import { Suite } from 'vest';

export interface ScheduleTabProps extends PropsWithDataStage {
    current: Interlude;
    initial?: Interlude;
    onChange: (schedule: InterludeSchedule) => void;
    validator: Suite<(input: { schedule: ScheduleForm }) => void>;
}

class ScheduleTab extends React.Component<ScheduleTabProps, ScheduleForm> {
    constructor(props: ScheduleTabProps) {
        super(props);
        this.state = this.createState(props);
    }

    createState = (props: ScheduleTabProps) => {
        const updatedState = modelToForm(props.current.schedule);
        this.validate(updatedState);
        return { ...updatedState };
    };

    onChange = (field: any) => {
        this.validate({ ...this.state, ...field });
        this.setState({ ...field }, () => {
            //notify parent of updated state
            const schedule = formToModel({ ...this.state });
            this.props.onChange(schedule);
        });
    };

    validate = (schedule: ScheduleForm) => {
        this.props.validator({ schedule });
    };

    getFieldError = (field: string): string => {
        const fieldError = this.props.validator.get().getErrors(field);
        return _.first(fieldError) ?? '';
    };

    render(): JSX.Element {
        return (
            <div>
                <Grid gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                    <Container header={<SectionHeader header="Eligible Dates" />}>
                        <FieldError error={this.getFieldError('startAndEnd')} />
                        <ColumnLayout columns={2}>
                            <Container
                                header={
                                    <ToggleField
                                        checked={this.state.startEnabled}
                                        onChange={(startEnabled) => this.onChange({ startEnabled })}
                                        label={<SectionHeader header="Start Date and Time" />}
                                    />
                                }
                            >
                                <SelectDateTime
                                    enabled={this.state.startEnabled}
                                    date={this.state.startDate}
                                    time={this.state.startTime}
                                    dateError={this.getFieldError('startDate')}
                                    timeError={this.getFieldError('startTime')}
                                    onChange={(startDate, startTime) => this.onChange({ startDate, startTime })}
                                />
                            </Container>
                            <Container
                                header={
                                    <ToggleField
                                        checked={this.state.endEnabled}
                                        onChange={(endEnabled) => this.onChange({ endEnabled })}
                                        label={<SectionHeader header="End Date and Time" />}
                                    />
                                }
                            >
                                <SelectDateTime
                                    enabled={this.state.endEnabled}
                                    date={this.state.endDate}
                                    time={this.state.endTime}
                                    dateError={this.getFieldError('endDate')}
                                    timeError={this.getFieldError('endTime')}
                                    onChange={(endDate, endTime) => this.onChange({ endDate, endTime })}
                                />
                            </Container>
                        </ColumnLayout>
                    </Container>
                    <Container header={<SectionHeader header="Available Days of Week" />}>
                        <FieldError error={this.getFieldError('daysOfWeek')} />
                        {ALL_DAYS.map((value) => {
                            return (
                                <ColumnLayout key={value} columns={2}>
                                    <ToggleField
                                        label={ALL_DAYS_FIELD_MAPPING[value]}
                                        checked={this.state.daysOfWeek.includes(value)}
                                        onChange={(enabled) => {
                                            const adjust = enabled ? _.concat : _.without;
                                            const daysOfWeek = adjust(this.state.daysOfWeek, value);
                                            this.onChange({ daysOfWeek });
                                        }}
                                    />
                                </ColumnLayout>
                            );
                        })}
                    </Container>
                    <Container
                        header={
                            <ToggleField
                                checked={this.state.hoursEnabled}
                                label={<SectionHeader header="Available Hours of Day" />}
                                onChange={(hoursEnabled) => this.onChange({ hoursEnabled })}
                            />
                        }
                    >
                        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                            <TimeField
                                label="Start Time"
                                value={this.state.hoursStartTime}
                                error={this.getFieldError('hoursStartTime') || this.getFieldError('hoursOfDay')}
                                disabled={!this.state.hoursEnabled}
                                onChange={(hoursStartTime) => {
                                    this.onChange({ hoursStartTime });
                                }}
                            />

                            <TimeField
                                label="End Time"
                                value={this.state.hoursEndTime}
                                error={this.getFieldError('hoursEndTime')}
                                disabled={!this.state.hoursEnabled}
                                onChange={(hoursEndTime) => {
                                    this.onChange({ hoursEndTime });
                                }}
                            />
                        </Grid>
                    </Container>

                    <Container id="options" header={<SectionHeader header="Options" />}>
                        Timezone:
                        <FormField
                            constraintText={this.state.useLocalTime === false ? 'Dates and times are shown in UTC' : ''}
                        >
                            <Select
                                options={[relativeTimezone, absoluteTimezone]}
                                selectedOption={this.state.useLocalTime ? relativeTimezone : absoluteTimezone}
                                onChange={({ detail }) => {
                                    this.onChange({
                                        useLocalTime: detail.selectedOption === relativeTimezone ? true : false,
                                    });
                                }}
                            />
                        </FormField>
                    </Container>
                </Grid>
            </div>
        );
    }
}
const relativeTimezone: SelectProps.Option = {
    label: 'Relative to customer',
    value: 'relative',
    description: 'Date and time values are relative to the region/timezone of the customer',
};
const absoluteTimezone: SelectProps.Option = {
    label: 'Absolute time',
    value: 'absolute',
    description: `Absolute date and time values used across all regions/timezones`,
};
export default ScheduleTab;
