import { FormField, Input, Toggle } from '@amzn/awsui-components-react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import { Blur as BlurOptions } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';

export interface BlurProps {
    options: BlurOptions;
    errors?: StringMap;
    onChange: (changed: BlurOptions) => void;
}

function Blur(props: BlurProps): JSX.Element {
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail | Toggle.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <div className="awsui-grid awsui-util-ml-xl">
            <div className="awsui-row">
                <FormField className="col-3" label="Pixel radius:" errorText={errors['blur']} stretch={true}>
                    <Input id="blur" value={props.options.blur.toString()} onChange={handleChange}></Input>
                </FormField>
            </div>
        </div>
    );
}

export default Blur;
