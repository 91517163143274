import { ServerPoll } from '../data-types';
import { getStatusFromSchedule } from './dateTimeUtil';
import Papa from 'papaparse';

const POLLS_ROW_BOUNDARY = 500;

export function transformPollDataForSearch(poll: ServerPoll): void {
    /** Needed to be able to search by status, prompt and choices*/
    poll.status = getStatusFromSchedule(
        poll.eligibility?.schedule?.start_date ?? '',
        poll.eligibility?.schedule?.expiration_date ?? '',
    );
    poll.promptValue = poll.prompt?.value;
    if (poll.choices) {
        poll.choice_1 = poll.choices[0]?.value;
        poll.choice_2 = poll.choices[1]?.value;
        poll.choice_3 = poll.choices[2]?.value;
        poll.choice_4 = poll.choices[3]?.value;
        poll.choice_5 = poll.choices[4]?.value;
    }
}

interface CSVRow {
    group_number: number;
    artist_asin: string;
    artist_name: string;
    locale: string;
    start_date: string;
    end_date: string;
    question_text: string;
    choice_1_text: string;
    choice_2_text: string;
    choice_3_text: string;
    choice_4_text: string;
    choice_5_text: string;
}

export function splitLargePollFiles(file: File): Promise<File[]> {
    return new Promise((resolve, reject) => {
        const splitFiles: File[] = [];

        let currentGroup: number | undefined = undefined;
        let currentFileRows: CSVRow[] = [];
        let rowCount = 0;
        let header: string;

        Papa.parse<CSVRow>(file, {
            header: true,
            worker: true,
            step: function (row) {
                if (rowCount === 0) {
                    // Store header from the first row
                    header = row.meta.fields?.join(',') + '\n';
                }

                if (rowCount >= POLLS_ROW_BOUNDARY) {
                    const groupNumber = row.data.group_number;
                    // Once we reach the row boundary, mark the current group
                    if (currentGroup === undefined) {
                        currentGroup = groupNumber;
                    } else if (currentGroup !== groupNumber) {
                        // We've encountered a different group number. Process currentFileRows into a new file and push it to the return list
                        const csvContent = header + Papa.unparse(currentFileRows, { header: false });
                        splitFiles.push(convertCSVToFile(csvContent, file.name, splitFiles.length + 1));
                        currentGroup = undefined;
                        // Reset our counters
                        currentFileRows = [];
                        rowCount = 0;
                    }
                }

                currentFileRows.push(row.data);
                rowCount++;
            },
            complete: function () {
                // Handle the last chunk of data if rows remain
                if (currentFileRows.length > 0) {
                    const csvContent = header + Papa.unparse(currentFileRows, { header: false });
                    splitFiles.push(convertCSVToFile(csvContent, file.name, splitFiles.length + 1));
                }
                resolve(splitFiles); // Resolve the promise with the list of files
            },
            error: function (error) {
                reject(error); // Reject the promise if an error occurs
            },
        });
    });
}

function convertCSVToFile(csvContent: string, fileName: string, splitNumber: number): File {
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a File from the Blob
    const splitFileName = `${fileName}_split#${splitNumber}.csv`;
    return new File([blob], splitFileName, { type: 'text/csv' });
}

export function saveFileToLocal(file: File) {
    // Create a temporary URL for the file
    const url = URL.createObjectURL(file);

    // Create a hidden anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name; // Set the desired file name for download

    // Append the anchor to the document body and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up: remove the anchor element and revoke the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

// Delay bulk upload execution to avoid overloading the backend
export function delayPollBulkUpload(ms = 3000): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
