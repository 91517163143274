import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ensureAuthenticated } from './auth/AuthGenerator';
import './styles/index.scss';

ensureAuthenticated().then(() => {
    const container = document.getElementById('root');
    if (!container) throw new Error('Failed to find the root element');

    const root = createRoot(container);
    root.render(
        <div className="awsui">
            <Router>
                <App />
            </Router>
        </div>,
    );
});
