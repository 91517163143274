import React from 'react';
import {
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
    COLUMN_DEFINITIONS,
    QueueTableItem,
} from '../configs/queue-table-config';
import {
    Flashbar,
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSorting,
    TableWrapLines,
} from '@amzn/awsui-components-react';
import { TableNoMatchState } from './commons/common-components';
import { createPropertyStorage } from '../utils/jsonStorage';
import { addToColumnDefinitions, ColumnSetting, mapWithColumnDefinitionIds } from '../utils/tableUtil';
import { FlashbarItem, itemError } from './commons/flash-messages';
import { fetchGetQueueItems } from '../utils/fetchUtil';

const widthsStorage = createPropertyStorage<ColumnSetting[]>('Queue-Table-Widths');

export interface State {
    columnDefinitions: Table.ColumnDefinition[];
    items: QueueTableItem[];
    pageSize: number;
    loading: boolean;
    filteringText: string;
    flashbar: FlashbarItem[];
}

export class QueueTable extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            columnDefinitions: COLUMN_DEFINITIONS,
            items: [],
            pageSize: 15,
            loading: false,
            flashbar: [],
            filteringText: '',
        };
    }

    saveWidths = (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
    };
    loadWidths = (): Promise<Table.ColumnDefinition[]> => {
        return widthsStorage.load().then((columns) => addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns));
    };

    componentDidMount() {
        this.loadWidths().then((columnDefinitions) => {
            this.setState({
                columnDefinitions,
            });
        });
    }

    loadItems() {
        this.setState({
            loading: true,
        });
        const { filteringText } = this.state;
        fetchGetQueueItems(filteringText)
            .then((items) => {
                // Ignore the results if the filtering text has already changed while fetching these.
                if (filteringText && filteringText !== this.state.filteringText) {
                    return;
                }
                this.setState({
                    loading: false,
                    items: items,
                });
            })
            .catch((error) => {
                // Ignore the results if the filtering text has already changed while fetching these.
                if (filteringText && filteringText !== this.state.filteringText) {
                    return;
                }
                this.setState({
                    loading: false,
                    flashbar: [itemError('Failed to load assets', error)],
                });
            });
    }

    onFilteringChange(evt: CustomEvent<TableFiltering.FilteringChangeDetail>) {
        this.setState({
            filteringText: evt.detail.filteringText,
            loading: false,
        });
    }

    onFilteringDelayed() {
        this.loadItems();
    }

    render() {
        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.items}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveWidths}
                    header={<h2>STORM Queue</h2>}
                    loading={this.state.loading}
                    loadingText="Loading Queue info"
                    empty={<TableNoMatchState />}
                >
                    <TableFiltering
                        filteringPlaceholder="Queue ID"
                        filteringFunction={null}
                        filteringText={this.state.filteringText}
                        onFilteringChange={this.onFilteringChange.bind(this)}
                        onFilteringDelayedInput={this.onFilteringDelayed.bind(this)}
                    />
                    <TablePagination disabled={this.state.loading} pageSize={this.state.pageSize} />
                    <TableSorting sortableColumns={SORTABLE_COLUMNS} disabled={this.state.loading} />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={this.state.loading}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={CONTENT_SELECTOR_OPTIONS} />
                    </TablePreferences>
                </Table>
            </div>
        );
    }
}

export default QueueTable;
