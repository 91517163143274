import React from 'react';
import {
    QcarTableItem,
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    COLUMN_DEFINITIONS,
    SORTABLE_COLUMNS,
    QCAR_COLUMN_ID,
} from '../../configs/qcar-table-config';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSorting,
    TableWrapLines,
    Flashbar,
} from '@amzn/awsui-components-react';
import { QcarTableButtons } from './QcarTableHeader';
import { TableNoMatchState } from '../commons/common-components';
import { createPropertyStorage } from '../../utils/jsonStorage';
import { fetchGetAllQcars } from '../../utils/fetchUtil';
import { FlashbarItem, itemError } from '../commons/flash-messages';
import { RouteStateComponent, Props } from '../RouteState';
import {
    DEFAULT_TABLE_OPTIONS,
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    ColumnSetting,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
    TableOptions,
} from '../../utils/tableUtil';
import { PropsWithDataStage, withDataStage } from '../StageContext';

const columnStorage = createPropertyStorage<ColumnSetting[]>('Qcar-Table-Settings');
const optionsStorage = createPropertyStorage<TableOptions>('Qcar-Table-Options');

export interface State extends TableOptions {
    columnDefinitions: Table.ColumnDefinition[];
    contentSelector: TableContentSelector.ContentDescriptionGroup[];
    templates: QcarTableItem[];
    loading: boolean;
    flashbar: FlashbarItem[];
    filteringText?: string;
    sortingDetail: TableSorting.SortingChangeDetail;
}

export class QcarsTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: COLUMN_DEFINITIONS,
            contentSelector: CONTENT_SELECTOR_OPTIONS,
            templates: [],
            loading: true,
            flashbar: [],
            sortingDetail: {
                sortingColumn: QCAR_COLUMN_ID.last_updated_time,
                sortingDescending: true,
            },
            ...DEFAULT_TABLE_OPTIONS,
        };
    }

    unloaded = false;
    historyState = new RouteStateComponent(this.props, this.state);

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths, existing));
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(mapWithContentSelectionValues(COLUMN_DEFINITIONS, e.detail.contentSelection, existing));
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems();
        this.loadSettings();
    }

    componentWillUnmount(): void {
        this.unloaded = true;
    }

    componentDidUpdate(prevProps: PropsWithDataStage): void {
        if (this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadItems();
        }
    }

    loadItems = async () => {
        this.setState({
            loading: true,
        });
        this.props.history.replace({ ...this.props.location });
        try {
            const qcars = await fetchGetAllQcars(this.props.dataStage);
            this.setState({
                templates: qcars,
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
                flashbar: [itemError('Failed to load QCARs', error)],
            });
        }
    };

    render() {
        const disabled = this.state.loading;
        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Table
                    items={this.state.templates}
                    columnDefinitions={this.state.columnDefinitions}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    stickyHeader
                    resizableColumns
                    header={
                        <QcarTableButtons
                            totalItems={this.state.templates.length}
                            loading={this.state.loading}
                            onReload={this.loadItems}
                        />
                    }
                    loading={this.state.loading}
                    loadingText="Loading QCARs"
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        disabled={disabled}
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Search"
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                    <TablePagination pageSize={this.state.pageSize} onPaginationChange={this.paginationChanged} />
                    <TableSorting
                        sortableColumns={SORTABLE_COLUMNS}
                        disabled={disabled}
                        sortingColumn={this.state.sortingDetail.sortingColumn}
                        sortingDescending={this.state.sortingDetail.sortingDescending}
                        onSortingChange={this.sortingChanged}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={this.state.contentSelector} />
                    </TablePreferences>
                </Table>
            </div>
        );
    }
}

export default withDataStage(QcarsTable);
