import React from 'react';
import { TableHeader } from '../commons/common-components';
import { Button } from '@amzn/awsui-components-react';

export interface Props {
    totalItems: number;
    loading: boolean;
    onReload: () => void;
}

export const QcarTableButtons: React.FC<Props> = (props: Props) => {
    return (
        <TableHeader
            {...props}
            title="Station Sequencing"
            actionButtons={
                <div className="awsui-util-action-stripe-group">
                    <Button
                        icon="refresh"
                        disabled={props.loading}
                        loading={props.loading}
                        onClick={() => props.onReload()}
                    ></Button>
                </div>
            }
        />
    );
};
