import Jimp from 'jimp';
import { formatImage } from '../imageUtil';
import { Preset } from '@amzn/forge-image-processing-types';

export default async function formatAlbumImage(image: Jimp, format: Preset, asin?: string): Promise<File> {
    const rawImage = await image.getBufferAsync(image.getMIME());
    const fileName = !!asin ? `${asin}.${image.getExtension()}` : '';
    const formatted = await formatImage(rawImage, format);
    return new File([await formatted.getBufferAsync(formatted.getMIME())], fileName);
}
