import { Button, Modal } from '@amzn/awsui-components-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { StringMap } from '../../../data-types';
import { validatePreset } from '../../../utils/imageUtil';
import { DefaultArtistPreset, Preset, ImageLayer } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';
import Layer from './Layer';

export interface FormatProps {
    format: Preset;
    visible: boolean;
    onApply: (format: Preset) => void;
    onHide: () => void;
}

export interface Expandable {
    expanded?: boolean;
}

const inlineErrors = ['type', 'minimum', 'maximum', 'exclusiveMinimum', 'exclusiveMaximum'];

function ImageFormat(props: FormatProps): JSX.Element {
    const [format, setFormat] = useState(props.format);
    const [errors, setErrors] = useState<StringMap>({});
    useEffect(() => setFormat(props.format), [props.format]);
    const apply = () => {
        const final = deepCopy(format);
        validatePreset(final, false);
        props.onApply(final);
        props.onHide();
    };
    const dismiss = () => {
        setFormat(props.format);
        props.onHide();
    };
    const dismissed = () => {
        setFormat(props.format);
    };
    const moveUp = (index: number) => {
        const changed = deepCopy(format);
        const removed = changed.layers.splice(index, 1);
        changed.layers.splice(index - 1, 0, removed[0]);
        setFormat(changed);
    };
    const moveDown = (index: number) => {
        const changed = deepCopy(format);
        const removed = changed.layers.splice(index, 1);
        changed.layers.splice(index + 1, 0, removed[0]);
        setFormat(changed);
    };
    const remove = (index: number) => {
        const changed = deepCopy(format);
        changed.layers.splice(index, 1);
        setFormat(changed);
    };
    const layerChange = (index: number, layer: ImageLayer) => {
        const changed = deepCopy(format);
        changed.layers[index] = layer;
        const schemaErrors = validatePreset(changed, true);
        const errors: StringMap = {};
        schemaErrors.forEach((error) => {
            if (inlineErrors.indexOf(error.keyword) >= 0) {
                const keys = error.instancePath.split('/');
                let current: string | StringMap = errors;
                for (let idx = 1; idx < keys.length; ++idx) {
                    const key = keys[idx];
                    if (idx + 1 == keys.length) {
                        current[key] = error.message || 'unknown error';
                    } else {
                        current = current[key] = current[key] || {};
                        if (typeof current === 'string') {
                            break;
                        }
                    }
                }
            }
        });
        setFormat(changed);
        setErrors((errors['layers'] as StringMap) || {});
    };
    const addLayer = () => {
        const changed = deepCopy(format) as Preset;
        const layer: ImageLayer & Expandable = { sourceId: 'default', operations: [], expanded: true };
        changed.layers.push(layer);
        setFormat(changed);
    };
    return (
        <Modal
            visible={props.visible}
            onDismiss={dismissed}
            size="large"
            header={
                <div className="awsui-util-action-stripe">
                    <div className="awsui-util-action-stripe-title">
                        <h2>Image Format</h2>
                    </div>
                    <div className="awsui-util-action-stripe-group awsui-util-mr-l">
                        <Button variant="link" onClick={() => setFormat(DefaultArtistPreset)}>
                            Defaults
                        </Button>
                        <Button icon="add-plus" onClick={addLayer}>
                            New Layer
                        </Button>
                    </div>
                </div>
            }
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="link" onClick={dismiss}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => apply()} disabled={Object.keys(errors).length > 0}>
                        Apply
                    </Button>
                </span>
            }
        >
            {format.layers.map((layer, index) => (
                <Layer
                    key={index}
                    layer={layer}
                    index={index}
                    total={format.layers.length}
                    errors={errors[index] as StringMap}
                    onMoveUp={() => moveUp(index)}
                    onMoveDown={() => moveDown(index)}
                    onRemove={() => remove(index)}
                    onChange={(changed) => layerChange(index, changed)}
                />
            ))}
        </Modal>
    );
}

export default ImageFormat;
