import { Badge, Table, TableSorting } from '@amzn/awsui-components-react';

import { ColumnDefinitionWithId } from '../utils/tableUtil';
import { Link } from 'react-router-dom';
import { ServerQcar } from '../data-types';
import { convertShortDateFromUtcToLocalTime } from '../utils/dateTimeUtil';

export interface QcarTableItem extends ServerQcar, Table.Item {}

export const QCAR_COLUMN_ID = {
    id: 'id',
    station_name: 'station_name',
    station_key: 'station_key',
    marketplaces: 'marketplaces',
    experience: 'experience',
    last_updated_time: 'last_updated_time',
    last_updated_by: 'last_updated_by',
};

export const COLUMN_DEFINITIONS: ColumnDefinitionWithId<QcarTableItem>[] = [
    {
        id: QCAR_COLUMN_ID.id,
        header: () => 'ID',
        cell: (item) => (
            <div>
                <Link to={`/qcars/${item.qcar?.id}`}>{item.qcar?.id}</Link>
            </div>
        ),
        minWidth: '180px',
        allowLineWrap: true,
    },
    {
        id: QCAR_COLUMN_ID.station_name,
        cell: (item) => item.station_metadata?.station_name,
        header: () => 'Station Name',
        minWidth: '160px',
        allowLineWrap: true,
    },
    {
        id: QCAR_COLUMN_ID.station_key,
        cell: (item) => item.station_metadata?.station_key,
        header: () => 'Station Key',
        minWidth: '160px',
        allowLineWrap: true,
    },
    {
        id: QCAR_COLUMN_ID.marketplaces,
        cell: (item) =>
            item.station_metadata?.marketplaces.map((marketplace) => (
                <div key={marketplace} style={{ marginRight: 5, display: 'inline-block' }}>
                    <Badge>{marketplace}</Badge>
                </div>
            )),
        header: () => 'Marketplaces',
        minWidth: '160px',
        allowLineWrap: true,
    },
    {
        id: QCAR_COLUMN_ID.last_updated_time,
        cell: (item) => convertShortDateFromUtcToLocalTime(item.qcar?.lastModifyDate),
        header: () => 'Last Updated',
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: QCAR_COLUMN_ID.last_updated_by,
        header: () => 'Last Updated By',
        cell: (item) => item.qcar?.lastModifyBy,
        minWidth: '100px',
        allowLineWrap: true,
    },
];

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<QcarTableItem>[] = [
    { id: QCAR_COLUMN_ID.id, comparator: sortQcarId },
    { id: QCAR_COLUMN_ID.last_updated_time, comparator: sortLastUpdatedDate },
];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'QCAR',
        options: [{ id: QCAR_COLUMN_ID.id, label: 'Qcar ID', editable: false, visible: true }],
    },
    {
        label: 'Station Metadata',
        options: [
            { id: QCAR_COLUMN_ID.station_name, label: 'Station Name', editable: true, visible: true },
            { id: QCAR_COLUMN_ID.station_key, label: 'Station Key', editable: true, visible: true },
            { id: QCAR_COLUMN_ID.marketplaces, label: 'Marketplaces', editable: true, visible: true },
        ],
    },
    {
        label: 'Update Information',
        options: [
            { id: QCAR_COLUMN_ID.last_updated_time, label: 'Last Updated Time', editable: true, visible: true },
            { id: QCAR_COLUMN_ID.last_updated_by, label: 'Last Updated By', editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Qcars' },
    { value: 15, label: '15 Qcars' },
    { value: 30, label: '30 Qcars' },
    { value: 100, label: '100 Qcars' },
    { value: 500, label: '500 Qcars' },
];

function sortLastUpdatedDate(item1: QcarTableItem, item2: QcarTableItem): number {
    if (item1.qcar?.lastModifyDate === item2.qcar?.lastModifyDate) return 0;

    if (!item1.qcar?.lastModifyDate) {
        return -1;
    } else if (!item2.qcar?.lastModifyDate) {
        return 1;
    }

    const date1 = new Date(Date.parse(item1.qcar?.lastModifyDate));
    const date2 = new Date(Date.parse(item2.qcar?.lastModifyDate));

    if (date1 && date2) {
        return date1 < date2 ? -1 : 1;
    }

    return 0;
}

function sortQcarId(item1: QcarTableItem, item2: QcarTableItem): number {
    return item1.qcar?.id.localeCompare(item2.qcar?.id);
}
