import { BreadcrumbGroup, SideNavigation } from '@amzn/awsui-components-react';
import { useHistory, useLocation, useParams } from 'react-router';
import URI from 'urijs';

const urlPartNames: { [index: string]: string } = {
    interludes: 'Interludes',
    'create-interlude': 'Create interlude',
    'upload-interludes': 'Bulk upload',
    assets: 'Assets',
    'create-asset': 'Create asset',
    'upload-assets': 'Bulk upload',
    queue: 'Queue',
    changes: 'Staged changes',
    reports: 'Reports',
    'create-report': 'Create report',
};

function Breadcrumbs(): JSX.Element {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<any>();

    const getItems = (): BreadcrumbGroup.Item[] => {
        const items: BreadcrumbGroup.Item[] = [];
        const urlParts = location.pathname.split('/');
        while (urlParts.length > 0) {
            let urlPart = urlParts.pop() as string;
            if (!urlPart) {
                continue;
            }
            if (urlPart.startsWith(':')) {
                urlPart = params[urlPart.substring(1)];
            } else {
                urlPart = urlPartNames[urlPart] || urlPart;
            }
            items.unshift({
                text: urlPart,
                href: `${URI(window.location).origin()}${urlParts.join('/')}/${urlPart}`,
            });
        }
        return items.length > 1 ? items : [];
    };

    const follow = (e: CustomEvent<SideNavigation.FollowDetail>): void => {
        e.preventDefault();
        history.push(URI(e.detail.href).path());
    };

    return <BreadcrumbGroup items={getItems()} onFollow={follow} />;
}

export default Breadcrumbs;
