import { ServerInterlude } from '../data-types';
import { InterludeAssociationOutput } from '@amzn/amazon-music-interlude-catalog-manager-client';
import _ from 'lodash';

export function addAssociations(
    interludes: ServerInterlude[],
    associations: InterludeAssociationOutput[],
): ServerInterlude[] {
    _(associations)
        // map into a dict of string(interludeId) -> InterludeAssociationOutput[]
        .groupBy((assn) => assn.interludeId)
        // map into a dict of string(interludeId) -> AssociationRule[]
        .mapValues((assns) => _.flatMap(assns, (assn) => assn.rules))
        // for each entry in the dict...
        .forEach((rules, interludeId) =>
            // IIF the interlude exists, set its associations to the list of rule.value
            {
                const interlude = interludes.find((il) => il.id == interludeId);
                if (interlude) {
                    interlude.associations = rules.map((rule) => rule.value).join();
                }
            },
        );
    // Return a shallow clone of the array to satisfy Polaris table requirements
    return _.clone(interludes);
}
