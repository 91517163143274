import { Input, Select } from '@amzn/awsui-components-react';
import { available_locale_options, getSelection } from '../../configs/multi-select-config';

import { Poll } from '../../data-types';
import React from 'react';

export interface Props {
    poll: Poll;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

class PollMetadata extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveField = 'col-xxxs-12 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Metadata</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Poll ID:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="id"
                            value={this.props.poll.id}
                            onChange={this.handleChange}
                            disabled
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Locale</h4>
                        <Select
                            className={responsiveField}
                            options={available_locale_options}
                            selectedLabel="Selected"
                            selectedOption={getSelection(available_locale_options, this.props.poll.locale)}
                            id="locale"
                            onChange={this.handleChange}
                            disabled={this.props.isEdit}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Artist Association:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="association"
                            value={this.props.poll.association}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Poll title:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="title"
                            value={this.props.poll.title}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Creation time:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="created_at"
                            value={this.props.poll.created_at}
                            disabled
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Last updated:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="last_updated"
                            value={this.props.poll.last_updated}
                            disabled
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default PollMetadata;
