import { PresignedUrl } from '../data-types';
import { fetchJson } from './fetchUtil';
import { prepareFormData } from './jsonUtil';

export interface UploadResult {
    url: string;
    filename: string;
    extension: string;
}

export enum AssetDestination {
    FORGE = 'FORGE',
    MOUSAI = 'MOUSAI',
}

function getApiPathFromAssetDestination(dest: AssetDestination, extension: string): string {
    switch (dest) {
        case AssetDestination.FORGE:
            return '/api/assets/creates3presignedurl';
        case AssetDestination.MOUSAI:
            return `/api/commentary/creates3uploadurl?extension=${extension}`;
        default:
            return '';
    }
}

export function uploadMedia(assetDestination: AssetDestination, file?: File): Promise<UploadResult> {
    if (!file) {
        return Promise.reject('Cannot upload without selecting a file first.');
    }
    const extension = file.name.split('.').pop() || '';
    let presignedUrl: PresignedUrl;
    return fetchJson<PresignedUrl>('GET', getApiPathFromAssetDestination(assetDestination, extension))
        .then((result) => {
            presignedUrl = result;
            const formData = prepareFormData(presignedUrl);
            formData.append('file', file as Blob);
            return fetch(presignedUrl.url, { method: 'POST', body: formData });
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to upload asset file: ' + response.statusText);
            }
            return {
                url: presignedUrl.url,
                filename: presignedUrl.file_name,
                extension: extension,
            };
        });
}
