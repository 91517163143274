import { FormField, Input, SegmentedControl } from '@amzn/awsui-components-react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import {
    HorizontalAlignment,
    Resize as ResizeOptions,
    ResizeStyle,
    VerticalAlignment,
} from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';

export interface ResizeProps {
    options: ResizeOptions;
    errors?: StringMap;
    onChange: (changed: ResizeOptions) => void;
}

const resizeStyles = [
    {
        text: 'Cover',
        id: ResizeStyle.Cover,
    },
    {
        text: 'Contain',
        id: ResizeStyle.Contain,
    },
    {
        text: 'Stretch',
        id: ResizeStyle.Stretch,
    },
];
const verticalAlignment = [
    {
        text: 'Top',
        id: VerticalAlignment.Top,
    },
    {
        text: 'Middle',
        id: VerticalAlignment.Middle,
    },
    {
        text: 'Bottom',
        id: VerticalAlignment.Bottom,
    },
];
const horizontalAlignment = [
    {
        text: 'Left',
        id: HorizontalAlignment.Left,
    },
    {
        text: 'Center',
        id: HorizontalAlignment.Center,
    },
    {
        text: 'Right',
        id: HorizontalAlignment.Right,
    },
];

function Resize(props: ResizeProps): JSX.Element {
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail | SegmentedControl.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <div className="awsui-grid awsui-util-ml-xl">
            <div className="awsui-row">
                <FormField className="col-3" label="Height:" errorText={errors['height']} stretch={true}>
                    <Input id="height" value={props.options.height.toString()} onChange={handleChange}></Input>
                </FormField>
                <FormField className="col-3" label="Width:" errorText={errors['width']} stretch={true}>
                    <Input id="width" value={props.options.width.toString()} onChange={handleChange}></Input>
                </FormField>
                <FormField className="col-6" label="Style:" errorText={errors['style']} stretch={true}>
                    <SegmentedControl
                        id="style"
                        options={resizeStyles}
                        selectedId={props.options.style}
                        onChange={handleChange}
                    ></SegmentedControl>
                </FormField>
            </div>
            {props.options.style !== ResizeStyle.Stretch && (
                <div className="awsui-row">
                    <FormField
                        className="col-6"
                        label="Vertical alignment:"
                        errorText={errors['vertical']}
                        stretch={true}
                    >
                        <SegmentedControl
                            id="vertical"
                            options={verticalAlignment}
                            selectedId={props.options.vertical}
                            onChange={handleChange}
                        ></SegmentedControl>
                    </FormField>
                    <FormField
                        className="col-6"
                        label="Horizontal alignment:"
                        errorText={errors['horizontal']}
                        stretch={true}
                    >
                        <SegmentedControl
                            id="horizontal"
                            options={horizontalAlignment}
                            selectedId={props.options.horizontal}
                            onChange={handleChange}
                        ></SegmentedControl>
                    </FormField>
                </div>
            )}
        </div>
    );
}

export default Resize;
