import React from 'react';
import { FormField, Input } from '@amzn/awsui-components-react';
import { StationSettings } from '../../utils/qcar/qcarTransformer';
import { useEffect } from 'react';
import { convertShortDateFromUtcToLocalTime } from '../../utils/dateTimeUtil';

type StationSettingsProps = {
    stationSettings: StationSettings;
    setIsValid: (isValid: boolean) => void;
    onEdit: (stationSettings: StationSettings) => void;
};

const EditStationSettings: React.FC<StationSettingsProps> = ({
    stationSettings,
    setIsValid,
    onEdit,
}: StationSettingsProps) => {
    const defaultRelativeHistoryErrorMessage = 'Default Relative History must be a positive integer';
    const customerHistoryErrorMessage = 'Customer History must be a positive integer';
    const minFrequencyErrorMessage = 'Minimum frequency must be positive and less than or equal to Maximum Frequency';
    const maxFrequencyErrorMessage =
        'Maximum frequency must be positive and greater than or equal to Minimum Frequency';

    useEffect(() => {
        setIsValid(isStationSettingsValid());
    });

    const updateMinimumFrequency = (newMinimumFrequency: number | undefined) => {
        if (stationSettings) {
            stationSettings.sequencing.freqMin = newMinimumFrequency as number;
            onEdit(stationSettings);
        }
    };

    const updateMaximumFrequency = (newMaximumFrequency: number | undefined) => {
        if (stationSettings) {
            stationSettings.sequencing.freqMax = newMaximumFrequency as number;
            onEdit(stationSettings);
        }
    };

    const updateDefaultCustomerHistory = (newDefaultCustomerHistory: number | undefined) => {
        if (stationSettings) {
            stationSettings.sequencing.defaultCustomerHistory = newDefaultCustomerHistory;
            onEdit(stationSettings);
        }
    };

    const updateDefaultRelativeHistory = (newDefaultRelativeHistory: number | undefined) => {
        if (stationSettings) {
            stationSettings.sequencing.defaultRelativeHistory = newDefaultRelativeHistory;
            onEdit(stationSettings);
        }
    };

    const isStationSettingsValid = () => {
        return (
            defaultHistoryIsValid(stationSettings) &&
            customerHistoryIsValid(stationSettings) &&
            minAndMaxFrequencyAreValid(stationSettings)
        );
    };

    const defaultHistoryIsValid = (stationSettings: StationSettings) => {
        return (
            (stationSettings?.sequencing.defaultRelativeHistory !== undefined &&
                stationSettings?.sequencing.defaultRelativeHistory >= 0 &&
                Number.isInteger(stationSettings?.sequencing.defaultRelativeHistory)) ||
            stationSettings?.sequencing.defaultRelativeHistory === undefined
        );
    };

    const customerHistoryIsValid = (stationSettings: StationSettings) => {
        return (
            (stationSettings?.sequencing.defaultCustomerHistory !== undefined &&
                stationSettings?.sequencing.defaultCustomerHistory >= 0 &&
                Number.isInteger(stationSettings?.sequencing.defaultCustomerHistory)) ||
            stationSettings?.sequencing.defaultCustomerHistory === undefined
        );
    };

    const minAndMaxFrequencyAreBothUndefined = (stationSettings: StationSettings) => {
        return stationSettings?.sequencing.freqMin === undefined && stationSettings?.sequencing.freqMax === undefined;
    };

    const minAndMaxFrequencyAreValid = (stationSettings: StationSettings) => {
        return (
            minAndMaxFrequencyAreBothUndefined(stationSettings) ||
            (stationSettings?.sequencing.freqMin <= stationSettings?.sequencing.freqMax &&
                stationSettings?.sequencing.freqMin >= 0 &&
                stationSettings?.sequencing.freqMax >= 0)
        );
    };

    const responsiveLabel = 'col-xxxs-12 col-xxs-4';
    const responsiveField = 'col-xxxs-12 col-xxs-8';
    const numberFieldStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '10px',
        position: 'relative',
    };
    const numberFieldLabelStyle: React.CSSProperties = {
        position: 'absolute',
        top: '-28px',
        color: 'GrayText',
    };
    const numberFieldRow: React.CSSProperties = {
        marginTop: '25px',
    };
    return (
        <div>
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">General</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Station:</h4>
                        <Input className={responsiveField} value={stationSettings?.general.stationName} disabled />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Marketplace(s):</h4>
                        <Input
                            className={responsiveField}
                            value={stationSettings?.general.marketplaces.join(', ')}
                            disabled
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Tier(s):</h4>
                        <Input className={responsiveField} value={stationSettings?.general.tiers.join(', ')} disabled />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Last Updated:</h4>
                        <Input
                            className={responsiveField}
                            value={convertShortDateFromUtcToLocalTime(stationSettings?.general.lastUpdatedTime)}
                            disabled
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Last Updated By:</h4>
                        <Input className={responsiveField} value={stationSettings?.general.lastUpdatedBy} disabled />
                    </div>
                </div>
            </div>
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Sequencing</div>
                <div className="awsui-grid">
                    <div className="awsui-row" style={numberFieldRow}>
                        <h4 className={responsiveLabel}>Interlude Injection Rate:</h4>
                        <div className={responsiveField} style={{ display: 'flex' }}>
                            <div style={numberFieldStyle}>
                                <h4 style={numberFieldLabelStyle}>Min:</h4>
                                <FormField
                                    errorText={!minAndMaxFrequencyAreValid(stationSettings) && minFrequencyErrorMessage}
                                >
                                    <Input
                                        type="number"
                                        value={stationSettings?.sequencing.freqMin?.toString()}
                                        onChange={(event) => {
                                            const newMinimumFrequency =
                                                event.detail.value.length > 0 ? Number(event.detail.value) : undefined;
                                            updateMinimumFrequency(newMinimumFrequency);
                                        }}
                                    />
                                </FormField>
                            </div>
                            <div style={numberFieldStyle}>
                                <h4 style={numberFieldLabelStyle}>Max:</h4>
                                <FormField
                                    errorText={!minAndMaxFrequencyAreValid(stationSettings) && maxFrequencyErrorMessage}
                                >
                                    <Input
                                        type="number"
                                        value={stationSettings?.sequencing.freqMax?.toString()}
                                        onChange={(event) => {
                                            const newMaxiumumFrequency =
                                                event.detail.value.length > 0 ? Number(event.detail.value) : undefined;
                                            updateMaximumFrequency(newMaxiumumFrequency);
                                        }}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div className="awsui-row" style={numberFieldRow}>
                        <h4 className={responsiveLabel}>Customer History:</h4>
                        <div className={responsiveField} style={{ display: 'flex' }}>
                            <div style={numberFieldStyle}>
                                <h4 style={numberFieldLabelStyle}>Seconds:</h4>
                                <FormField
                                    errorText={!customerHistoryIsValid(stationSettings) && customerHistoryErrorMessage}
                                >
                                    <Input
                                        type="number"
                                        value={stationSettings?.sequencing.defaultCustomerHistory?.toString()}
                                        onChange={(event) => {
                                            const newDefaultCustomerHistory =
                                                event.detail.value.length > 0 ? Number(event.detail.value) : undefined;
                                            updateDefaultCustomerHistory(newDefaultCustomerHistory);
                                        }}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div className="awsui-row" style={numberFieldRow}>
                        <h4 className={responsiveLabel}>Default Relative History:</h4>
                        <div className={responsiveField} style={{ display: 'flex' }}>
                            <div style={numberFieldStyle}>
                                <h4 style={numberFieldLabelStyle}>Interlude Breaks:</h4>
                                <FormField
                                    errorText={
                                        !defaultHistoryIsValid(stationSettings) && defaultRelativeHistoryErrorMessage
                                    }
                                >
                                    <Input
                                        type="number"
                                        value={stationSettings?.sequencing.defaultRelativeHistory?.toString()}
                                        onChange={(event) => {
                                            const newDefaultRelativeHistory =
                                                event.detail.value.length > 0 ? Number(event.detail.value) : undefined;
                                            updateDefaultRelativeHistory(newDefaultRelativeHistory);
                                        }}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditStationSettings;
