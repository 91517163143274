import { Flashbar } from '@amzn/awsui-components-react';

export const successfulCreation = [
    { type: 'success', content: 'Resource created successfully', dismissLabel: 'Dismiss message', dismissible: true },
];

export class FlashbarItem implements Flashbar.MessageDefinition {
    type = 'info';
    loading = false;
    dismissible = true;
    header?: string;
    content?: string;
    buttonText?: string;
    isDismissed? = false;
    buttonClick?: () => void;
    dismiss = () => {
        this.isDismissed = true;
    };

    constructor(header: string, content?: string) {
        this.header = header;
        this.content = content;
    }
    isSuccess = (): FlashbarItem => {
        this.type = 'success';
        return this;
    };
    isLoading = (): FlashbarItem => {
        this.loading = true;
        this.dismissible = false;
        return this;
    };
    isWarning = (): FlashbarItem => {
        this.type = 'warning';
        return this;
    };
    isError = (error?: unknown): FlashbarItem => {
        this.type = 'error';
        if (error) {
            if (error instanceof Error) {
                this.content = error.message;
            } else if (typeof error === 'object') {
                this.content = JSON.stringify(error);
            } else if (typeof error === 'string') {
                this.content = error;
            }
        }
        return this;
    };
    isDismissible = (dismissible: boolean): FlashbarItem => {
        this.dismissible = dismissible;
        return this;
    };
    withButton = (text: string, handler: () => void): FlashbarItem => {
        this.buttonText = text;
        this.buttonClick = handler;
        return this;
    };
}

export function itemInfo(header: string, content?: string): FlashbarItem {
    return new FlashbarItem(header, content);
}

export function itemWarning(header: string, content?: string): FlashbarItem {
    return new FlashbarItem(header, content).isWarning();
}

export function itemSuccess(header: string, content?: string): FlashbarItem {
    return new FlashbarItem(header, content).isSuccess();
}

export function itemError(header: string, error?: unknown): FlashbarItem {
    return new FlashbarItem(header).isError(error);
}

export function itemLoading(header: string, content?: string): FlashbarItem {
    return new FlashbarItem(header, content).isLoading();
}
