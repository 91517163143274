import stage from './stage';

const FORGE_APIG_ENDPOINTS = {
    test: 'https://api.beta.forge.music.amazon.dev',
    beta: 'https://api.beta.forge.music.amazon.dev',
    gamma: 'https://api.gamma.forge.music.amazon.dev',
    prod: 'https://api.forge.music.amazon.dev'
};

export function getAPIGEndpoint() {
    if (process.env.REACT_APP_FORGE_API_URL) {
        return process.env.REACT_APP_FORGE_API_URL;
    }
    const current = stage();
    return FORGE_APIG_ENDPOINTS[current];
}
