import { Button, ColumnLayout, ExpandableSection } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { StringMap } from '../../../data-types';
import { deepCopy } from '../../../utils/jsonUtil';
import Operation from './Operation';
import Overlay from './Overlay';
import { Expandable } from './ImageFormat';
import { ImageLayer, Operation as ImageOperation, Overlay as ImageOverlay } from '@amzn/forge-image-processing-types';

export interface LayerProps {
    layer: ImageLayer;
    index: number;
    total: number;
    errors?: StringMap;
    onMoveUp: () => void;
    onMoveDown: () => void;
    onRemove: () => void;
    onChange: (layer: ImageLayer) => void;
}

const DefaultOverlay: ImageOverlay = {
    offsetX: 0,
    offsetY: 0,
};

function Layer(props: LayerProps): JSX.Element {
    const isFirst = props.index == 0;
    const isLast = props.index + 1 == props.total;
    const canRemove = props.total > 1;
    const errors = props.errors || {};
    const operationErrors = (errors['operations'] as StringMap) || {};
    const expandable = props.layer as Expandable;
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        setExpanded(expandable.expanded || false);
    });
    const expandedChange = (e: CustomEvent<ExpandableSection.ChangeDetail>) => {
        expandable.expanded = e.detail.expanded;
        setExpanded(expandable.expanded);
    };
    const compositionChange = (options: ImageOverlay) => {
        const changed = deepCopy(props.layer);
        changed.overlay = options;
        props.onChange(changed);
    };
    const operationChange = (index: number, options: ImageOperation) => {
        const changed = deepCopy(props.layer);
        changed.operations[index] = options;
        props.onChange(changed);
    };
    const moveUp = (e: CustomEvent<Button.ClickDetail>) => {
        e.stopPropagation();
        props.onMoveUp();
    };
    const moveDown = (e: CustomEvent<Button.ClickDetail>) => {
        e.stopPropagation();
        props.onMoveDown();
    };
    const remove = (e: CustomEvent<Button.ClickDetail>) => {
        e.stopPropagation();
        props.onRemove();
    };
    const operationUp = (index: number) => {
        const changed = deepCopy(props.layer);
        const removed = changed.operations.splice(index, 1);
        changed.operations.splice(index - 1, 0, removed[0]);
        props.onChange(changed);
    };
    const operationDown = (index: number) => {
        const changed = deepCopy(props.layer);
        const removed = changed.operations.splice(index, 1);
        changed.operations.splice(index + 1, 0, removed[0]);
        props.onChange(changed);
    };
    const removeOperation = (index: number) => {
        const changed = deepCopy(props.layer);
        changed.operations.splice(index, 1);
        props.onChange(changed);
    };
    const addOperation = (e: CustomEvent<Button.ClickDetail>) => {
        e.stopPropagation();
        const changed = deepCopy(props.layer);
        const operation: any = { type: '', expanded: true };
        changed.operations.push(operation as ImageOperation);
        props.onChange(changed);
    };
    return (
        <ExpandableSection
            expanded={expanded}
            onChange={expandedChange}
            header={
                <div className="awsui-util-action-stripe">
                    <div className="awsui-util-action-stripe-title">
                        <h2>{`Layer ${props.index + 1}`}</h2>
                    </div>
                    <div className="awsui-util-action-stripe-group">
                        {expanded && (
                            <Button icon="add-plus" onClick={addOperation}>
                                Add operation
                            </Button>
                        )}
                        <Button variant="icon" icon="angle-up" onClick={moveUp} disabled={isFirst}></Button>
                        <Button variant="icon" icon="angle-down" onClick={moveDown} disabled={isLast}></Button>
                        <Button variant="icon" icon="close" onClick={remove} disabled={!canRemove}></Button>
                    </div>
                </div>
            }
            variant="container"
        >
            <ColumnLayout columns={1}>
                <div data-awsui-column-layout-root="true">
                    {!isFirst && (
                        <Overlay
                            options={props.layer.overlay || DefaultOverlay}
                            errors={errors['overlay'] as StringMap}
                            onChange={compositionChange}
                        />
                    )}
                    {props.layer.operations.map((options, index) => (
                        <Operation
                            key={index}
                            index={index}
                            total={props.layer.operations.length}
                            options={options}
                            errors={operationErrors[index] as StringMap}
                            onChange={(changed) => operationChange(index, changed)}
                            onMoveUp={() => operationUp(index)}
                            onMoveDown={() => operationDown(index)}
                            onRemove={() => removeOperation(index)}
                        />
                    ))}
                </div>
            </ColumnLayout>
        </ExpandableSection>
    );
}

export default Layer;
