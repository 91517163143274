import React from 'react';
import { TableHeader } from './commons/common-components';
import { Button } from '@amzn/awsui-components-react';

export interface Props {
    totalItems: number;
    selectedItems: any[];
    loading: boolean;
    changing: boolean;
    onReload: () => void;
    showRevertModal: () => void;
    showPromoteModal: () => void;
}

export const ChangesTableButtons: React.FC<Props> = (props: Props) => {
    const emptySelection = props.selectedItems.length === 0;
    const disabled = props.loading || props.changing;

    return (
        <TableHeader
            {...props}
            title="Staged changes"
            actionButtons={
                <div className="awsui-util-action-stripe-group">
                    <Button
                        icon="refresh"
                        disabled={disabled}
                        loading={props.loading}
                        onClick={() => props.onReload()}
                    ></Button>
                    <Button
                        text="Unstage"
                        disabled={emptySelection || disabled}
                        loading={props.changing}
                        onClick={() => props.showRevertModal()}
                    ></Button>
                    <Button
                        text="Promote"
                        variant="primary"
                        disabled={emptySelection || disabled}
                        loading={props.changing}
                        onClick={() => props.showPromoteModal()}
                    ></Button>
                </div>
            }
        />
    );
};
