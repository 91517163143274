import React from 'react';
import { Button, Flashbar, Input, Modal, Spinner, TokenGroup } from '@amzn/awsui-components-react';
import {
    fetchCreateAsset,
    fetchGetArtistMetadata,
    fetchGetAsset,
    fetchGetDefaultArtistImageAsset,
} from '../../utils/fetchUtil';
import { itemError, itemInfo, itemLoading, itemSuccess } from '../commons/flash-messages';
import { PropsWithDataStage } from '../StageContext';
import { Asset, AssetCategories, Interlude, ServerAsset } from '../../data-types';
import { deepCopy } from '../../utils/jsonUtil';
import { findLargestImage, formatImage } from '../../utils/imageUtil';
import Jimp from 'jimp';
import { DefaultArtistPreset } from '@amzn/forge-image-processing-types';
import { AssetDestination, uploadMedia } from '../../utils/assetUtil';
import HelpInfoLink from '../help/HelpInfoLink';
import { interludeDefaultImageHelp } from '../help/HelpContent';

export interface Props extends PropsWithDataStage {
    interlude: Interlude;
    initial?: Interlude;
    isEdit?: boolean;
    fillAssetDetails: (asset: any) => boolean;
    onUpdate: (interlude: Interlude) => void;
}

interface LoadableAsset extends ServerAsset {
    failed?: boolean;
}

export interface State {
    flashbar: Flashbar.MessageDefinition[];
    assets: LoadableAsset[];
    images: LoadableAsset[];
    loading: boolean;
    loadingAsset: boolean;
    loadingImage: boolean;
    assetInput: string;
    imageInput: string;
    assetError: boolean;
    imageError: boolean;
    loadingDefault: boolean;
    showDefaultDialog: boolean;
}

class AssetsSimple extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            flashbar: [],
            assets: [],
            images: [],
            loading: false,
            loadingAsset: false,
            loadingImage: false,
            loadingDefault: false,
            assetInput: '',
            assetError: false,
            imageInput: '',
            imageError: false,
            showDefaultDialog: false,
        };
    }

    loadedAssets: ServerAsset[] = [];

    componentDidMount() {
        this.loadAssets(true);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.interlude.all_asset_ids !== this.props.interlude.all_asset_ids ||
            prevProps.interlude.all_imgs !== this.props.interlude.all_imgs
        ) {
            this.loadAssets(false);
        }
    }

    addAsset = async () => {
        const assetId = this.state.assetInput;
        this.setState({ loadingAsset: true });
        const asset = await this.loadAsset(assetId);
        if (asset.failed) {
            this.setState({ loadingAsset: false, assetError: true });
        } else {
            this.setState({ loadingAsset: false, assetInput: '' });
            this.updateAssets(this.state.assets.map((a) => a.id).concat(assetId));
        }
    };

    removeAsset = (e: CustomEvent<TokenGroup.DismissDetail>) => {
        const assets = deepCopy(this.state.assets);
        assets.splice(e.detail.itemIndex, 1);
        this.updateAssets(assets.map((a) => a.id));
    };

    addImage = async () => {
        const assetId = this.state.imageInput;
        this.setState({ loadingImage: true });
        const asset = await this.loadAsset(assetId);
        if (asset.failed) {
            this.setState({ loadingImage: false, imageError: true });
        } else {
            this.setState({ loadingImage: false, imageInput: '' });
            this.updateImages(this.state.images.map((a) => a.id).concat(assetId));
        }
    };

    removeImage = (e: CustomEvent<TokenGroup.DismissDetail>) => {
        const images = deepCopy(this.state.images);
        images.splice(e.detail.itemIndex);
        this.updateImages(images.map((a) => a.id));
    };

    getDefaultDetails() {
        const firstAsin = this.props.interlude.artist_asin?.split(',')?.concat([])?.shift()?.trim();
        if (!firstAsin || firstAsin.length == 0) {
            const error = itemError(
                'Missing artist ASIN',
                'At least one ASIN must be associated with this interlude to load its default image',
            );
            this.setState({ flashbar: [error] });
            return {};
        }
        // TODO: Should we create individual assets for each marketplace associated with the interlude?
        const firstMtr = this.props.interlude.mtr_list?.concat([])?.shift();
        if (!firstMtr || firstMtr.length == 0) {
            const error = itemError(
                'Missing marketplace',
                'At least one marketplace must be associated with this interlude to load its default image',
            );
            this.setState({ flashbar: [error] });
            return {};
        }
        return { asin: firstAsin, mtr: firstMtr };
    }

    addDefault = async () => {
        const { asin } = this.getDefaultDetails();
        if (!asin) {
            return;
        }
        this.setState({ loadingDefault: true });
        try {
            // Try to load the default artist image asset if one exists
            const asset = await fetchGetDefaultArtistImageAsset(asin, this.props.dataStage);
            this.loadedAssets.push(asset);
            this.updateImages(this.state.images.map((a) => a.id).concat(asset.id));
            this.setState({ flashbar: [itemSuccess('', `Default image asset ${asset.id} associated to interlude`)] });
        } catch {
            // Looks like one does not exists yet - ask the user if one should be created
            this.setState({ showDefaultDialog: true });
        } finally {
            this.setState({ loadingDefault: false });
        }
    };

    createDefault = async () => {
        const { asin, mtr } = this.getDefaultDetails();
        if (!asin || !mtr) {
            return;
        }
        this.setState({ loadingDefault: true, showDefaultDialog: false });
        try {
            const metadata = await fetchGetArtistMetadata(asin, mtr);
            const source = findLargestImage(metadata);
            if (!source) {
                throw Error('Artist metadata does not include images');
            }
            const image = await Jimp.read(source.url);
            const buffer = await image.getBufferAsync(image.getMIME());
            const formatted = await formatImage(buffer, DefaultArtistPreset);
            const filename = `${asin}.${image.getExtension()}`;
            const file = new File([await formatted.getBufferAsync(formatted.getMIME())], filename);
            const uploaded = await uploadMedia(AssetDestination.FORGE, file);
            const height_pixels = formatted.getHeight();
            const width_pixels = formatted.getWidth();
            const asset: Asset = {
                type: 'IMAGE',
                category: AssetCategories.DEFAULT_ARTIST_IMAGE,
                asin,
                descr: 'Default artist image',
                auto_values: true,
                mtr,
                origfile: filename,
                author: metadata.name,
                fileName: uploaded.filename,
                extension: uploaded.extension,
                width_pixels,
                height_pixels,
            };
            const id = await fetchCreateAsset(asset, this.props.dataStage);
            this.loadedAssets.push({ id, ...asset });
            this.updateImages(this.state.images.map((a) => a.id).concat(id));
            this.setState({ flashbar: [itemSuccess(`Default image asset ${id} created and associated to interlude`)] });
        } catch (error) {
            this.setState({ flashbar: [itemError('Failed to create asset', error)] });
        } finally {
            this.setState({ loadingDefault: false });
        }
    };

    loadAssets(firstLoad: boolean) {
        this.setState({ loading: firstLoad, assets: [], images: [] }, () => {
            const promises: Promise<void>[] = [];
            const assetIds = this.props.interlude.assets?.text.concat(this.props.interlude.assets?.media) || [];
            const assetPromises = assetIds.map((id) => this.loadAsset(id));
            const imageIds = this.props.interlude.assets?.images.map((img) => img.id) || [];
            const imagePromises = imageIds.map((id) => this.loadAsset(id));
            promises.push(Promise.all(assetPromises).then((assets) => this.setState({ assets })));
            promises.push(Promise.all(imagePromises).then((images) => this.setState({ images })));
            Promise.allSettled(promises).then(() => this.setState({ loading: false }));
        });
    }

    updateAssets(assetIds: string[]) {
        const updated = deepCopy(this.props.interlude);
        updated.all_asset_ids = assetIds.join(',');
        updated.assets = updated.assets || { text: [], media: [], images: [] };
        updated.assets.text = assetIds.map((id) => (id.startsWith('TXT-') ? id : '')).filter((id) => id.length > 0);
        updated.assets.media = assetIds
            .map((id: string) => (id.startsWith('MED-') ? id : ''))
            .filter((id) => id.length > 0);
        this.props.onUpdate(updated);
    }

    updateImages(assetIds: string[]) {
        const updated = deepCopy(this.props.interlude);
        updated.all_imgs = assetIds.join(',');
        updated.assets = updated.assets || { text: [], media: [], images: [] };
        updated.assets.images = assetIds
            .map((id) => (id.startsWith('MED-') ? { id } : { id: '' }))
            .filter((asset) => asset.id.length > 0);
        this.props.onUpdate(updated);
    }

    async loadAsset(id: string): Promise<LoadableAsset> {
        const cached = this.loadedAssets.find((a) => a.id == id);
        if (cached) {
            return cached;
        }
        let loaded: LoadableAsset;
        try {
            loaded = await fetchGetAsset(id, this.props.dataStage);
        } catch {
            loaded = { id: id, failed: true };
        }
        this.loadedAssets.push(loaded);
        return loaded;
    }

    validateAssets(str: string) {
        const assets = str.split(',');
        const promises: Promise<any>[] = [];
        const flashbar: Flashbar.MessageDefinition[] = [];
        assets.forEach((asset) => {
            asset = asset.trim();
            if (asset.length == 0) {
                return;
            }
            const promise = fetchGetAsset(asset, this.props.dataStage).catch((error) =>
                flashbar.push(itemError(`Failed to validate asset: ${asset}`, error)),
            );
            promises.push(promise);
        });
        if (promises.length == 0) {
            return;
        }
        this.setState({ flashbar: [itemLoading('Validating assets ...')] });
        Promise.all(promises).then((assets) => {
            let filled = false;
            const state = { flashbar: flashbar };
            assets.forEach((asset) => (filled = filled || this.props.fillAssetDetails(asset)));
            if (filled) {
                flashbar.push(itemInfo('Display text information was updated from asset metadata'));
            }
            this.setState(state);
        });
    }

    render() {
        const assetToToken = (asset: LoadableAsset): TokenGroup.Item => {
            return {
                label: asset.id,
                description: asset.descr,
                iconName: asset.failed ? 'status-warning' : '',
            };
        };
        const assetItems = this.state.assets.map(assetToToken);
        const imageItems = this.state.images.map(assetToToken);
        return (
            <div>
                <Modal
                    visible={this.state.showDefaultDialog}
                    header="Create new asset"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDefaultDialog: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => this.createDefault()}>
                                Ok
                            </Button>
                        </span>
                    }
                >
                    <p>
                        A new default artist image asset will be created and it will be associated with this interlude.
                        This operation will take several seconds to complete.
                    </p>
                    <p>Do you want to continue?</p>
                </Modal>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <div className="awsui-util-container">
                    <div className="awsui-util-container-header">Assets</div>
                    <div className="awsui-grid">
                        <div className="awsui-row awsui-util-no-gutters">
                            <h4 className="col-xxxs-12 col-xxs-4">Asset ID:</h4>
                            <Input
                                className="col-xxxs-6 col-xxs-3"
                                type="text"
                                value={this.state.assetInput}
                                invalid={this.state.assetError}
                                onInput={(e) => this.setState({ assetInput: e.detail.value, assetError: false })}
                            />
                            <Button
                                icon="add-plus"
                                className="col-xxxs-6 col-xxs-5"
                                onClick={this.addAsset}
                                disabled={this.state.loading || this.state.assetInput.length == 0}
                                loading={this.state.loadingAsset}
                            >
                                Add
                            </Button>
                        </div>
                        <div className="awsui-row">
                            {this.state.loading && <Spinner className="col-xxxs-12 offset-xxs-4 col-xxs-8" />}
                            {!this.state.loading && (
                                <TokenGroup
                                    className="col-xxxs-12 offset-xxs-4 col-xxs-8"
                                    items={assetItems}
                                    onDismiss={this.removeAsset}
                                ></TokenGroup>
                            )}
                        </div>
                        <div className="awsui-row awsui-util-no-gutters">
                            <h4 className="col-xxxs-12 col-xxs-4">Image Asset ID:</h4>
                            <Input
                                className="col-xxxs-6 col-xxs-3"
                                type="text"
                                value={this.state.imageInput}
                                invalid={this.state.imageError}
                                onInput={(e) => this.setState({ imageInput: e.detail.value, imageError: false })}
                            />
                            <div className="col-xxxs-6 col-xxs-5">
                                <Button
                                    icon="add-plus"
                                    onClick={this.addImage}
                                    disabled={this.state.loading || this.state.imageInput.length == 0}
                                    loading={this.state.loadingImage}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={this.addDefault}
                                    disabled={this.state.loading || this.state.images.length > 0}
                                    loading={this.state.loadingDefault}
                                >
                                    Default
                                </Button>
                            </div>
                        </div>
                        <div className="awsui-row">
                            {this.state.loading && <Spinner className="col-xxxs-12 offset-xxs-4 col-xxs-8" />}
                            {!this.state.loading && (
                                <TokenGroup
                                    className="col-xxxs-12 offset-xxs-4 col-xxs-8"
                                    items={imageItems}
                                    onDismiss={this.removeImage}
                                ></TokenGroup>
                            )}
                        </div>
                    </div>
                    <div className="awsui-util-container-footer">
                        Setting image asset ID to <span style={{ fontWeight: 'bold' }}>Default</span> requires an ASIN
                        association.
                        <HelpInfoLink content={interludeDefaultImageHelp()} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AssetsSimple;
