import React from 'react';
import { Button, Flashbar } from '@amzn/awsui-components-react';
import Media from '../media/Media';
import Data from '../assets/Data';
import { fetchGetAsset } from '../../utils/fetchUtil';
import { itemError, itemLoading } from '../commons/flash-messages';
import { deepCopy } from '../../utils/jsonUtil';
import { PropsWithDataStage } from '../StageContext';
import { ServerAsset } from '../../data-types';

export interface Props extends PropsWithDataStage {
    assetId: string;
    className?: string;
}

export interface State {
    asset?: ServerAsset;
    flashbar: Flashbar.MessageDefinition[];
    loading: boolean;
}

/**
 * Component used from EditInterlude as a read-only view of the asset
 */
class ViewAsset extends React.Component<Props, State> {
    asset?: ServerAsset;

    constructor(props: Props) {
        super(props);
        this.state = {
            asset: undefined,
            flashbar: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.loadAssetDetails(this.props.assetId);
    }

    loadAssetDetails(assetId: string) {
        if (!assetId) {
            throw new Error('Invalid asset ID passed to ViewAsset');
        }
        this.setState({
            loading: true,
            flashbar: this.asset ? [] : [itemLoading(`Loading asset ${this.props.assetId}`)],
        });
        fetchGetAsset(assetId, this.props.dataStage)
            .then((asset) => {
                this.asset = asset;
                this.setState({ flashbar: [], asset: deepCopy(this.asset) });
            })
            .catch((error) => {
                let item = itemError(`Failed to load asset ${this.props.assetId}`, error);
                if (this.asset) {
                    item = item.withButton('Retry', () => this.loadAssetDetails(assetId));
                }
                this.setState({ flashbar: [item] });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    refresh = () => {
        this.loadAssetDetails(this.props.assetId);
    };

    render() {
        const responsiveColumn = 'col-xxxs-12 col-xs-6';
        return (
            <div className={this.props.className || ''}>
                <Flashbar items={this.state.flashbar}></Flashbar>
                {this.state.asset && (
                    <div className="awsui-util-container">
                        <div className="awsui-util-container-header">
                            <div className="awsui-util-action-stripe">
                                <div className="awsui-util-action-stripe-title">
                                    <h2>Asset: {this.props.assetId}</h2>
                                </div>
                                <div className="awsui-util-action-stripe-group">
                                    <Button icon="refresh" onClick={this.refresh} loading={this.state.loading}></Button>
                                </div>
                            </div>
                        </div>
                        {!this.state.loading && (
                            <div className="awsui-grid">
                                <div className="awsui-row">
                                    <Media
                                        className={responsiveColumn}
                                        presignedUrl={this.asset?.presigned_url}
                                        type={this.asset?.type}
                                    />
                                    <Data className={responsiveColumn} asset={this.state.asset} readonly={true} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ViewAsset;
