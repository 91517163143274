import React from 'react';
import { HelpContext } from './HelpContext';

export interface Props {
    /** The content to load in the tools panel when the entering the page */
    content: React.ReactNode;
    /** Whether or not to force the tools panel open when entering the page. Default: false */
    openPanel?: boolean;
}

/**
 * Use the PageHelp component to declaratively load the selected help content into the tools panel
 * when entering the page that contains the component
 * See: https://code.amazon.com/packages/ForgeToolWebsiteContents/blobs/mainline/--/DEVELOPMENT.md#help-content
 */
class PageHelp extends React.Component<Props> {
    static contextType = HelpContext;
    context!: HelpContext;

    componentDidMount() {
        try {
            this.context.setHelpContent(this.props.content, true, !!this.props.openPanel);
        } catch (error) {
            console.error('Could not set help content', error);
        }
    }

    render() {
        return null;
    }
}

export default PageHelp;
