import React from 'react';
import {
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
    COLUMN_DEFINITIONS,
    AssetTableItem,
} from '../configs/assets-table-config';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    Modal,
    Button,
    TableWrapLines,
    Flashbar,
} from '@amzn/awsui-components-react';
import { AssetTableButtons } from './AssetsTableHeader';
import { TableNoMatchState } from './commons/common-components';
import { fetchDeleteAsset, fetchExportAssets, CsvExport, fetchGetAssetPage } from '../utils/fetchUtil';
import { CSVLink } from 'react-csv';
import { createPropertyStorage } from '../utils/jsonStorage';
import { RouteStateComponent, Props } from './RouteState';
import {
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
    TABLE_PAGE_SIZE,
    TableOptions,
} from '../utils/tableUtil';
import { FlashbarItem, itemError } from './commons/flash-messages';
import { ServerAsset } from '../data-types';
import { DataStage, PropsWithDataStage, withDataStage } from './StageContext';
import { RouteComponentProps } from 'react-router';
import { CatalogStage } from '@amzn/amazon-music-interlude-catalog-manager-client';

const columnStorage = createPropertyStorage<ColumnSetting[]>('Assets-Table-Columns');
const optionsStorage = createPropertyStorage<TableOptions>('Assets-Table-Options');

let globalCachedAssets: ServerAsset[];

export interface State extends TableOptions {
    columnDefinitions: Table.ColumnDefinition[];
    contentSelector: TableContentSelector.ContentDescriptionGroup[];
    selectedAssets: AssetTableItem[];
    templates: AssetTableItem[];
    showDeleteModal: boolean;
    loading: boolean;
    dataToDownload: CsvExport;
    exporting: boolean;
    deleting: boolean;
    flashbar: FlashbarItem[];
    filteringText?: string;
    sortingDetail: TableSorting.SortingChangeDetail;
    firstPageDone: boolean;
}

export class AssetsTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: COLUMN_DEFINITIONS,
            contentSelector: CONTENT_SELECTOR_OPTIONS,
            selectedAssets: [],
            templates: [],
            showDeleteModal: false,
            loading: true,
            dataToDownload: [],
            exporting: false,
            deleting: false,
            flashbar: [],
            sortingDetail: { sortingColumn: COLUMN_DEFINITIONS[0].id, sortingDescending: true },
            firstPageDone: false,
            ...DEFAULT_TABLE_OPTIONS,
        };
    }

    csvLink = React.createRef<any>();
    historyState = new RouteStateComponent(this.props, this.state);
    unloaded = false;

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths, existing));
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(mapWithContentSelectionValues(COLUMN_DEFINITIONS, e.detail.contentSelection, existing));
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    componentWillUnmount(): void {
        // Use this flag to signal any background tasks that the component is being
        // destroyed (i.e. user navigated away from the page)
        this.unloaded = true;
    }

    componentDidUpdate(prevProps: RouteComponentProps & PropsWithDataStage): void {
        if (prevProps.dataStage !== this.props.dataStage) {
            this.loadItems();
        }
    }

    loadItems = async (useCache?: boolean) => {
        if (useCache && globalCachedAssets) {
            this.setState({
                templates: globalCachedAssets,
                loading: false,
                firstPageDone: true,
            });
            return;
        }
        this.setState({
            loading: true,
            firstPageDone: false,
        });
        this.props.history.replace({ ...this.props.location });

        let cachedAssets: ServerAsset[] = [];
        // Load SANDBOX first in order for the dedupe logic to work as expected
        if (this.props.dataStage == DataStage.Sandbox) {
            cachedAssets = await this.retrieveAssetsWithPagination(CatalogStage.SANDBOX, cachedAssets);
        }
        cachedAssets = await this.retrieveAssetsWithPagination(CatalogStage.LIVE, cachedAssets);

        this.setState({
            firstPageDone: true,
            templates: cachedAssets,
            loading: false,
        });

        // Only store the results in the global cache if the user did not navigate away while loading
        if (!this.unloaded) {
            globalCachedAssets = cachedAssets;
        }
    };

    // Enable paginated retrieval of assets
    retrieveAssetsWithPagination = async (stage: CatalogStage, cachedAssets: ServerAsset[]): Promise<ServerAsset[]> => {
        let nextToken: string | undefined;
        do {
            const result = await fetchGetAssetPage(stage, nextToken);
            // Deduplicate LIVE assets from SANDBOX
            const deduped = result.assets.filter((a) => !cachedAssets.find((dup) => dup.id === a.id));
            cachedAssets = cachedAssets.concat(...deduped);
            if (this.state.pageSize <= TABLE_PAGE_SIZE) {
                this.setState({
                    firstPageDone: true,
                    templates: cachedAssets,
                });
            }
            nextToken = result.nextToken;
        } while (nextToken && !this.unloaded);
        return cachedAssets;
    };

    onSelectionChange(evt: CustomEvent<TableSelection.SelectionChangeDetail<AssetTableItem>>) {
        this.setState({
            selectedAssets: evt.detail.selectedItems,
        });
    }

    openDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    // Handler for Items delete
    onItemsDelete = () => {
        this.setState({ deleting: true });
        const deleteIds = this.state.selectedAssets.map((asset) => asset.id);
        const deletePromises = deleteIds.map((id) => fetchDeleteAsset(id || '', this.props.dataStage));
        Promise.all(deletePromises)
            .then(() => {
                const remainingTemplates = this.state.templates.filter(
                    (template) => deleteIds.indexOf(template.id) < 0,
                );
                this.setState({ templates: remainingTemplates });
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to delete one or more assets', error)] });
            })
            .finally(() => {
                this.setState({ deleting: false });
            });
    };

    // Handler for Export to csv
    onItemsExport = () => {
        this.setState({ exporting: true });
        const assetIds = this.state.selectedAssets.map((asset) => asset.id);
        fetchExportAssets(this.props.dataStage, assetIds)
            .then((dataToDownload) => {
                this.setState({ dataToDownload: dataToDownload }, () => {
                    // Use a timeout to allow the state to propagate to the CSVLink control before clicking the button
                    window.setTimeout(() => {
                        const link = this.csvLink.current?.link as HTMLLinkElement;
                        link.click();
                    }, 100);
                });
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to export interludes', error)] });
            })
            .finally(() => this.setState({ exporting: false }));
    };

    render() {
        const disabled = this.state.loading || this.state.exporting || this.state.deleting || !this.state.firstPageDone;
        const csvFilename = `Assets-${this.props.dataStage}.csv`;
        return (
            <div>
                <Modal
                    visible={this.state.showDeleteModal}
                    header="Delete Asset?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeleteModal: false })}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.onItemsDelete();
                                    this.setState({ showDeleteModal: false });
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will delete Asset.
                </Modal>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.templates}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    header={
                        <AssetTableButtons
                            showDeleteModal={this.openDeleteModal}
                            exportAssets={this.onItemsExport}
                            totalItems={this.state.templates.length}
                            selectedItems={this.state.selectedAssets}
                            exporting={this.state.exporting}
                            loading={this.state.loading}
                            deleting={this.state.deleting}
                            onReload={this.loadItems}
                        />
                    }
                    loading={this.state.loading && !this.state.firstPageDone}
                    loadingText="Loading Assets info"
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        disabled={disabled}
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Find Assets"
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                    <TablePagination
                        disabled={disabled}
                        pageSize={this.state.pageSize}
                        onPaginationChange={this.paginationChanged}
                    />
                    <TableSorting
                        sortableColumns={SORTABLE_COLUMNS}
                        disabled={disabled}
                        sortingColumn={this.state.sortingDetail.sortingColumn}
                        sortingDescending={this.state.sortingDetail.sortingDescending}
                        onSortingChange={this.sortingChanged}
                    />
                    <TableSelection
                        selectedItems={this.state.selectedAssets}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={this.state.contentSelector} />
                    </TablePreferences>
                </Table>
                <CSVLink
                    data={this.state.dataToDownload}
                    filename={csvFilename}
                    target="_blank"
                    className="awsui-util-hide"
                    ref={this.csvLink}
                />
            </div>
        );
    }
}

export default withDataStage(AssetsTable);
