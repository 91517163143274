import React from 'react';
import { Interlude } from '../../data-types';
import { PropsWithDataStage } from '../StageContext';
import AssetsSimple from './AssetsSimple';
import Associations from './Associations';
import DisplayText from './DisplayText';
import Eligibility from './Eligibility';
import Internal from './Internal';
import Sequencing from './Sequencing';

export interface EditDataTabProps extends PropsWithDataStage {
    current: any;
    initial: any;
    isEdit: boolean;
    fillAssetDetails: (asset: any) => boolean;
    onChange: (e: CustomEvent) => void;
    onUpdate: (interlude: Interlude) => void;
}

class EditDataTab extends React.Component<EditDataTabProps> {
    render(): JSX.Element {
        return (
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-xxxs-12 col-xs-6">
                        <Sequencing
                            interlude={this.props.current}
                            initial={this.props.initial}
                            isEdit={this.props.isEdit}
                            onChange={this.props.onChange}
                        ></Sequencing>
                        <Associations
                            interlude={this.props.current}
                            initial={this.props.initial}
                            isEdit={this.props.isEdit}
                            onChange={this.props.onChange}
                        ></Associations>
                        <AssetsSimple
                            dataStage={this.props.dataStage}
                            interlude={this.props.current}
                            initial={this.props.initial}
                            isEdit={this.props.isEdit}
                            onUpdate={this.props.onUpdate}
                            fillAssetDetails={this.props.fillAssetDetails}
                        ></AssetsSimple>
                    </div>
                    <div className="col-xxxs-12 col-xs-6">
                        <Eligibility
                            interlude={this.props.current}
                            initial={this.props.initial}
                            isEdit={this.props.isEdit}
                            onChange={this.props.onChange}
                        ></Eligibility>
                        <DisplayText
                            interlude={this.props.current}
                            initial={this.props.initial}
                            isEdit={this.props.isEdit}
                            onChange={this.props.onChange}
                        ></DisplayText>
                        <Internal
                            interlude={this.props.current}
                            initial={this.props.initial}
                            isEdit={this.props.isEdit}
                            onChange={this.props.onChange}
                        ></Internal>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditDataTab;
