import React from 'react';
import { Textarea } from '@amzn/awsui-components-react';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

class Internal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveValue = 'col-xxxs-12 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Internal</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Description:</h4>
                        <Textarea
                            className={responsiveValue}
                            id="descr"
                            rows={2}
                            value={this.props.interlude.descr}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Internal;
