import { Poll, PollChoice } from '../../data-types';

import { Input } from '@amzn/awsui-components-react';
import React from 'react';
import { deepCopy } from '../../utils/jsonUtil';
import { getPollChoice } from '../../configs/fan-poll-table-config';

export interface Props {
    poll: Poll;
    isEdit?: boolean;
    onPollChanged: (poll: Poll) => void;
}

class PollContent extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handlePromptUpdate = (pollPrompt?: string) => {
        const updatedPoll = deepCopy(this.props.poll);
        if (updatedPoll.prompt) {
            updatedPoll.prompt.value = pollPrompt;
        } else {
            updatedPoll.prompt = { value: pollPrompt };
        }
        this.props.onPollChanged(updatedPoll);
    };

    handleChoiceUpdate = (choiceNum: number, choiceText?: string) => {
        const updatedPoll = deepCopy(this.props.poll);
        if ((choiceText == '' || !choiceText) && updatedPoll.choices) {
            delete updatedPoll.choices[choiceNum];
        }
        if (updatedPoll.choices) {
            updatedPoll.choices[choiceNum] = {
                ...updatedPoll.choices[choiceNum],
                value: choiceText,
            };
        } else {
            const choice: PollChoice[] = [{ value: choiceText }];
            updatedPoll.choices = choice;
        }
        this.props.onPollChanged(updatedPoll);
    };

    renderChoices = (responsiveLabel: string, responsiveField: string): JSX.Element[] => {
        const choiceElements = [];
        for (let i = 0; i < 5; i++) {
            const choiceNumber = i + 1;
            choiceElements.push(
                <div className="awsui-row">
                    <h4 className={responsiveLabel}>{`Poll Choice ${choiceNumber}:`}</h4>
                    <Input
                        className={responsiveField}
                        type="text"
                        id={`poll_choice_${choiceNumber}`}
                        value={getPollChoice(i, this.props.poll.choices)}
                        onChange={(e) => this.handleChoiceUpdate(i, e.detail.value)}
                    />
                </div>,
            );
        }
        return choiceElements;
    };
    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveField = 'col-xxxs-12 col-xxs-8';
        const choiceElements: JSX.Element[] = this.renderChoices(responsiveLabel, responsiveField);
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Poll Content</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Prompt:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="prompt"
                            value={this.props.poll.prompt?.value}
                            onChange={(e) => this.handlePromptUpdate(e.detail.value)}
                        />
                    </div>
                    {choiceElements}
                </div>
            </div>
        );
    }
}

export default PollContent;
