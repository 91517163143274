import { Box, DatePicker, FormField, Icon, TimeInput, Toggle } from '@amzn/awsui-components-react-v3';
import React from 'react';

/**
 * Wrapped polaris v3 components for reusable and consistent interactions for this app
 */

export interface SectionHeaderProps {
    header: string;
}
export const SectionHeader: React.FunctionComponent<SectionHeaderProps> = (props) => (
    <Box variant="span" fontSize="heading-l">
        {props.header}
    </Box>
);
export interface ToggleFieldProps {
    label: React.ReactNode;
    description?: React.ReactNode;
    checked: boolean;
    onChange: (enabled: boolean) => void;
}

export const ToggleField: React.FunctionComponent<ToggleFieldProps> = (props) => (
    <Toggle
        checked={props.checked}
        onChange={(event) => {
            props.onChange(event.detail.checked);
        }}
        description={props.description}
    >
        {props.label}
    </Toggle>
);

export interface TimeFieldProps {
    label: string;
    disabled: boolean;
    value: string;
    error?: string;
    onChange: (time: string) => void;
}

export const TimeField: React.FunctionComponent<TimeFieldProps> = (props) => (
    <FormField label={props.label} constraintText="In 24H format" errorText={props.error}>
        <TimeInput
            format="hh:mm"
            placeholder="hh:mm"
            value={props.value}
            onChange={(event) => {
                props.onChange(event.detail.value);
            }}
            disabled={props.disabled}
        />
    </FormField>
);

export interface DateFieldProps {
    label: string;
    disabled: boolean;
    value: string;
    error?: string;
    onChange: (date: string) => void;
}
export const DateField: React.FunctionComponent<DateFieldProps> = (props) => (
    <FormField label={props.label} constraintText="YYYY/MM/DD" errorText={props.error}>
        <DatePicker
            placeholder="YYYY/MM/DD"
            todayAriaLabel="Today"
            nextMonthAriaLabel="Next month"
            value={props.value}
            onChange={(event) => {
                props.onChange(event.detail.value);
            }}
            previousMonthAriaLabel="Previous month"
            disabled={props.disabled}
        />
    </FormField>
);
interface FieldErrorProps {
    error?: string;
}
export const FieldError: React.FunctionComponent<FieldErrorProps> = (props) => {
    if (props.error) {
        return (
            <Box margin={{ bottom: 's' }} color="text-status-error">
                <Icon name="status-warning" /> {props.error}
            </Box>
        );
    }
    return null;
};
