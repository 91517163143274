import { Button, Modal } from '@amzn/awsui-components-react';

export interface Props {
    visible: boolean;
    fileNames: string[];
    validFormats: string[];
    onExit: () => void;
}

const UnsupportedFileModal = (props: Props): JSX.Element => {
    return (
        <Modal
            visible={props.visible}
            header="Unsupported file type"
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="primary" onClick={props.onExit}>
                        Ok
                    </Button>
                </span>
            }
        >
            {`The following file(s) are unsupported: ${props.fileNames.join(', ')}.
             Only these formats are supported: ${props.validFormats.join(', ')}.`}
        </Modal>
    );
};

export default UnsupportedFileModal;
