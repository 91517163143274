import React from 'react';
import { Input } from '@amzn/awsui-components-react';
import HelpInfoLink from '../help/HelpInfoLink';
import { interludeAssociationsHelp } from '../help/HelpContent';
import { Interlude } from '../../data-types';

export interface Props {
    interlude: Interlude;
    initial?: Interlude;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

export interface State {
    trackError: boolean;
    titlesetError: boolean;
    albumError: boolean;
    artistError: boolean;
    stationKeyError: boolean;
}

class Associations extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            trackError: false,
            titlesetError: false,
            albumError: false,
            artistError: false,
            stationKeyError: false,
        };
    }

    // Wildcards '*' are no longer supported for associations
    handleChange = (e: CustomEvent) => {
        this.setState({ trackError: false });
        this.setState({ albumError: false });
        this.setState({ artistError: false });
        this.setState({ stationKeyError: false });

        const target: any = e.target;
        const id: string = target.id;
        if (e.detail.value.includes('*')) {
            switch (id) {
                case 'track_asin':
                    this.setState({ trackError: true });
                    break;
                case 'titleset_asin':
                    this.setState({ titlesetError: true });
                    break;
                case 'album_asin':
                    this.setState({ albumError: true });
                    break;
                case 'artist_asin':
                    this.setState({ artistError: true });
                    break;
                case 'stn_key':
                    this.setState({ stationKeyError: true });
                    break;
                default:
                    break;
            }
        }
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveValue = 'col-xxxs-12 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Associations</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Track ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="track_asin"
                            invalid={this.state.trackError}
                            value={this.props.interlude.track_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Titleset ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="titleset_asin"
                            invalid={this.state.titlesetError}
                            value={this.props.interlude.titleset_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Album ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="album_asin"
                            invalid={this.state.albumError}
                            value={this.props.interlude.album_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Artist ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="artist_asin"
                            invalid={this.state.artistError}
                            value={this.props.interlude.artist_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Station Keys:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="stn_key"
                            invalid={this.state.stationKeyError}
                            value={this.props.interlude.stn_key}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="awsui-util-container-footer">
                    Associations restrict Interlude playback.
                    <HelpInfoLink content={interludeAssociationsHelp()} />
                </div>
            </div>
        );
    }
}

export default Associations;
