import React from 'react';
import { Input, Select, Toggle } from '@amzn/awsui-components-react';
import { position_list, assembly_type, content_type, getSelection } from '../../configs/multi-select-config';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

class Sequencing extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveField = 'col-xxxs-12 col-xxs-8';
        const fixedLabel = 'col-xxxs-5 col-xxs-4';
        const fixedField = 'col-xxxs-7 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Sequencing</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Asssembly Type:</h4>
                        <Select
                            className={responsiveField}
                            options={assembly_type}
                            selectedLabel="Selected"
                            selectedOption={getSelection(assembly_type, this.props.interlude.a_type)}
                            id="a_type"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Content Source:</h4>
                        <Input
                            className={responsiveField}
                            type="text"
                            id="src"
                            value={this.props.interlude.src}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Content Type:</h4>
                        <Select
                            className={responsiveField}
                            options={content_type}
                            selectedLabel="Selected"
                            selectedOption={getSelection(content_type, this.props.interlude.c_type)}
                            id="c_type"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Position:</h4>
                        <Select
                            className={responsiveField}
                            options={position_list}
                            selectedLabel="Selected"
                            selectedOption={getSelection(position_list, this.props.interlude.pos)}
                            id="pos"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={fixedLabel}>Rank:</h4>
                        <Input
                            className={fixedField}
                            type="number"
                            id="rank"
                            value={this.props.interlude.rank ? this.props.interlude.rank.toString() : ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={fixedLabel}>Is Skippable:</h4>
                        <Toggle
                            className={fixedField}
                            id="is_skippable"
                            checked={!!this.props.interlude.is_skippable}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={fixedLabel}>Is Explicit:</h4>
                        <Toggle
                            className={fixedField}
                            id="is_explicit"
                            checked={!!this.props.interlude.is_explicit}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={fixedLabel}>Is Mixable:</h4>
                        <Toggle
                            className={fixedField}
                            id="is_mixable"
                            checked={!!this.props.interlude.is_mixable}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={fixedLabel}>Is Editorial:</h4>
                        <Toggle
                            className={fixedField}
                            id="is_edtrl"
                            checked={!!this.props.interlude.is_edtrl}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Sequencing;
