import {
    Condition,
    Conditional,
    ConditionalModification,
    QcarOutput,
    QcarInput,
    Modification,
    Frequency,
    History,
    MiniSequence as ServerMiniSequence,
    QcarBasePin,
    PinSequence,
} from '@amzn/amazon-music-interlude-catalog-manager-client';
import { ServerQcar, StationMetadata } from '../../data-types';
import { deepCopy } from '../../utils/jsonUtil';
import * as _ from 'lodash';

interface QcarVariation {
    mod: ConditionalModification;
    qcar: QcarOutput;
}

export interface WebQcar {
    id: string;
    stage: string;
    sequencing: any; // passthrough
    variations: WebQcarVariation[];
}

export interface WebQcarVariation {
    id: number;
    label: string;
    qcarDescription?: string; // passthrough
    mod: ConditionalModification; // passthrough
    stationSettings: StationSettings;
    sequencedInterludes: MiniSequence[];
    pinnedInterludes: PinnedInterludes;
}

export interface GeneralStationSettings {
    stationName: string;
    marketplaces: string[];
    tiers: string[];
    lastUpdatedTime: string;
    lastUpdatedBy: string;
}

export interface StationSequencingSettings {
    freqMin: number;
    freqMax: number;
    chance?: number; // passthrough
    defaultCustomerHistory?: number;
    defaultRelativeHistory?: number;
}

export interface StationSettings {
    general: GeneralStationSettings;
    sequencing: StationSequencingSettings;
}

export interface MiniSequence {
    name?: string; // passthrough
    rank?: number;
    contentType?: string;
    position?: string;
    assemblyType?: string;
    relativeHistory?: number;
    customerHistory?: number;
}

export interface PinnedInterludes {
    pinnedSequences: PinnedSequence[];
    preSelectedInterludes: PreSelectedInterlude[];
}

export interface PinnedSequence {
    contentType?: string;
    position?: string;
    location: number[];
}

export interface PreSelectedInterlude {
    interludes: string[];
    location: number[];
}

const DEFAULT_VARIATION_LABEL = 'DEFAULT';
const EQ = 'eq';

/** Server to Web transformation */
export function serverToWeb(qcarWrapper: ServerQcar): WebQcar {
    const qcar = qcarWrapper.qcar;
    console.log(`Transforming Server QCAR: ${JSON.stringify(qcar)}`);

    // Add an empty modification so we have a "default" variation
    if (!qcar.hasOwnProperty('conditionalModifications') || !qcar.conditionalModifications) {
        qcar.conditionalModifications = [];
    }
    qcar.conditionalModifications.unshift({
        description: DEFAULT_VARIATION_LABEL,
        conditional: {} as Conditional,
        modifications: [],
    } as ConditionalModification);

    // Create variation QCARs for each modification
    const variations = qcar.conditionalModifications.map((mod) => createVariationQcar(qcar, mod));

    // Format each variation for the web view. The default variation will have variation id 0
    return {
        id: qcar.id,
        variations: variations.map((variation, idx) => formatForWebView(variation, idx, qcarWrapper.station_metadata)),
        stage: qcar.stage,
        sequencing: qcar.sequencing,
    } as WebQcar;
}

function createVariationQcar(qcar: QcarOutput, mod: ConditionalModification): QcarVariation {
    const variationQcar = deepCopy(qcar);
    variationQcar.conditionalModifications = [];
    mod.modifications
        .filter((modification) => modification.type == 'OVERRIDE')
        .forEach((modification) => applyModification(modification, variationQcar));
    return {
        mod: mod,
        qcar: variationQcar,
    } as QcarVariation;
}

/**
 * Traverse the qcar object through the specified key sequence, and apply the modification onto the leaf node
 */
function applyModification(modification: Modification, qcar: QcarOutput) {
    let currentNode: any = qcar;
    for (const key of modification.keySequence) {
        // If key doesn't exist in the object, exit
        if (!currentNode || !currentNode[key]) {
            console.log(`Key ${key} not found in QCAR. Unable to apply modification.`);
            break;
        }
        // If this is the last key in the sequence, apply the change
        if (key === modification.keySequence.at(-1)) {
            currentNode[key] = modification.value;
        }
        // If this is not the last key in the sequence, continue traversing the object
        currentNode = currentNode[key];
    }
}

function formatForWebView(
    variation: QcarVariation,
    variationId: number,
    stationMetadata?: StationMetadata,
): WebQcarVariation {
    const qcar = variation.qcar;

    const stationSettings = {
        general: {
            stationName: stationMetadata?.station_name,
            marketplaces: stationMetadata?.marketplaces,
            tiers: stationMetadata?.tiers,
            lastUpdatedBy: qcar.lastModifyBy,
            lastUpdatedTime: qcar.lastModifyDate,
        } as GeneralStationSettings,
        sequencing: {
            freqMin: qcar.frequency?.min,
            freqMax: qcar.frequency?.max,
            chance: qcar.frequency?.chance,
            defaultCustomerHistory: qcar.history?.absolute?.default,
            defaultRelativeHistory: qcar.history?.relative?.default,
        } as StationSequencingSettings,
    } as StationSettings;

    // Note: Only supports mini-sequences with only 1 conditional, and only 'ALL' conditionals
    const miniSequences = qcar.miniSequences?.map((miniseq) => {
        const contentType = miniseq.interludes
            .at(0)
            ?.allCondition?.find((condition) => conditionMatchesOn(condition, 'content_type'))?.value;
        const position = miniseq.interludes
            .at(0)
            ?.allCondition?.find((condition) => conditionMatchesOn(condition, 'position'))?.value;
        const assemblyType = miniseq.interludes
            .at(0)
            ?.allCondition?.find((condition) => conditionMatchesOn(condition, 'assembly_type'))?.value;
        return {
            name: miniseq.name,
            rank: miniseq.weight,
            contentType: contentType,
            position: position,
            assemblyType: assemblyType,
            relativeHistory: contentType
                ? qcar.history?.relative
                    ? qcar.history?.relative[contentType]
                    : undefined
                : undefined,
            customerHistory: contentType
                ? qcar.history?.absolute
                    ? qcar.history?.absolute[contentType]
                    : undefined
                : undefined,
        } as MiniSequence;
    });

    const pinnedInterludes = {
        // Note: only supports pinned sequences for interlude content types, using the ALL conditional and absolute positions
        pinnedSequences: qcar.pin?.pinSequences
            ?.filter((pin) => pin.interludes.at(0)?.allCondition)
            .map((pinSequence) => {
                return {
                    contentType: pinSequence.interludes
                        .at(0)
                        ?.allCondition?.find((condition) => conditionMatchesOn(condition, 'content_type'))?.value,
                    position: pinSequence.interludes
                        .at(0)
                        ?.allCondition?.find((condition) => conditionMatchesOn(condition, 'position'))?.value,
                    location: pinSequence.position.absolute,
                } as PinnedSequence;
            }),
        preSelectedInterludes: qcar.pin?.pinSequences
            ?.filter((pin) => pin.interludes.at(0)?.preSelected)
            .map((pinSequence) => {
                return {
                    interludes: pinSequence.interludes.at(0)?.preSelected,
                    location: pinSequence.position.absolute,
                } as PreSelectedInterlude;
            }),
    } as PinnedInterludes;

    return {
        id: variationId,
        label: getVariationLabel(variation),
        qcarDescription: qcar.description,
        mod: variation.mod,
        stationSettings: stationSettings,
        sequencedInterludes: miniSequences,
        pinnedInterludes: pinnedInterludes,
    } as WebQcarVariation;
}

/** Check if the provided condition is matching on the provided field */
function conditionMatchesOn(condition: Condition, field: string): boolean {
    return condition.field == field && condition.itemType == 'interlude' && condition.comparison == EQ;
}

function getVariationLabel(variation: QcarVariation): string | undefined {
    if (variation.mod.description) {
        return variation.mod.description;
    }
    const conditions = variation.mod.conditional.allCondition ?? variation.mod.conditional.anyCondition;
    const operand = variation.mod.conditional.allCondition ? 'and' : 'or';

    return conditions
        ?.map((condition) => `${condition.value}`)
        .reduce(function (result, value) {
            if (!result) {
                return value;
            } else {
                return `${result} ${operand} ${value}`;
            }
        });
}

/** Web to Server transformation */
export function webToServer(webQcar: WebQcar): QcarInput {
    console.log(`Transforming Web QCAR: ${JSON.stringify(webQcar)}`);
    if (!webQcar.variations.find((variation) => variation.label === DEFAULT_VARIATION_LABEL)) {
        throw Error('QCAR needs to have at least the default variation');
    }

    // For each variation, make a server QCAR
    const serverVariations = webQcar.variations.map((webVariation) => {
        return {
            mod: webVariation.mod,
            qcar: {
                ...webVariationToServerQcar(webVariation),
                id: webQcar.id,
            } as QcarInput,
        } as QcarVariation;
    });
    const defaultVariation = serverVariations.shift(); // Assumes that the first variation is the default

    // Use the QCAR made from the default variation as the base, and for each of the other variation QCARs compute a diff and return the diff as conditional modifications
    const res = {
        ...defaultVariation?.qcar,
        id: webQcar.id,
        sequencing: webQcar.sequencing,
        stage: webQcar.stage,
        ...(serverVariations.length > 0 && {
            conditionalModifications: serverVariations.map((variation) => {
                return {
                    conditional: getCleanConditional(variation.mod.conditional),
                    ...(variation.mod.description && { description: variation.mod.description }),
                    modifications: computeDiff(variation.qcar, defaultVariation?.qcar),
                };
            }),
        }),
    } as QcarInput;
    console.log(`Returning Server QCAR: ${JSON.stringify(res)}`);
    return res;
}

function getCleanConditional(conditional: Conditional): Conditional {
    return {
        ...(conditional.allCondition && { allCondition: conditional.allCondition }),
        ...(conditional.anyCondition && { anyCondition: conditional.anyCondition }),
    };
}

function computeDiff(newObject: any, oldObject: any): Modification[] {
    function makeModification(keySequence: string[], value: any): Modification {
        return {
            keySequence: keySequence,
            value: value,
            type: 'OVERRIDE',
        } as Modification;
    }

    const result: Modification[] = [];
    Object.keys(newObject).forEach((key) => {
        if (!oldObject.hasOwnProperty(key) || !_.isEqual(newObject[key], oldObject[key])) {
            result.push(makeModification([key], newObject[key]));
        }
    });
    return result;
}

function webVariationToServerQcar(variation: WebQcarVariation): QcarOutput {
    return {
        description: variation?.qcarDescription,
        lastModifyBy: variation?.stationSettings.general.lastUpdatedBy,
        lastModifyDate: variation?.stationSettings.general.lastUpdatedTime,
        frequency: {
            chance: variation?.stationSettings.sequencing.chance,
            min: variation?.stationSettings.sequencing.freqMin,
            max: variation?.stationSettings.sequencing.freqMax,
        } as Frequency,
        history: {
            absolute: getAbsoluteHistory(variation),
            relative: getRelativeHistory(variation),
        } as History,
        miniSequences: variation?.sequencedInterludes.map((miniseq) => {
            return {
                name: miniseq.name ?? makeMiniseqName(miniseq),
                interludes: [
                    { allCondition: getConditions(miniseq.contentType, miniseq.position, miniseq.assemblyType) },
                ],
                weight: miniseq.rank,
            } as ServerMiniSequence;
        }),
        pin: {
            pinSequences: getPinSequences(variation),
            exclusion: undefined,
        } as QcarBasePin,
    } as QcarOutput;
}

function getPinSequences(variation: WebQcarVariation) {
    const pinSequences = variation?.pinnedInterludes?.pinnedSequences
        ?.map(getPinSequence)
        .concat(variation.pinnedInterludes.preSelectedInterludes?.map(getPinSequenceFromPre));
    return pinSequences?.length > 0 ? pinSequences : undefined;
}

function getAbsoluteHistory(variation: WebQcarVariation) {
    const absolute = variation?.sequencedInterludes
        .filter((miniseq) => miniseq.customerHistory !== undefined && miniseq.contentType)
        .reduce((res, miniseq) => ({ ...res, [miniseq.contentType as string]: miniseq.customerHistory }), {} as any);
    absolute.default = variation.stationSettings.sequencing.defaultCustomerHistory;
    return absolute;
}

function getRelativeHistory(variation: WebQcarVariation) {
    const relative = variation?.sequencedInterludes
        .filter((miniseq) => miniseq.relativeHistory !== undefined && miniseq.contentType)
        .reduce((res, miniseq) => ({ ...res, [miniseq.contentType as string]: miniseq.relativeHistory }), {} as any);
    relative.default = variation.stationSettings.sequencing.defaultRelativeHistory;
    return relative;
}

function getPinSequence(webPinnedSequence: PinnedSequence): PinSequence {
    return {
        position: {
            absolute: webPinnedSequence.location,
        },
        interludes: [
            {
                allCondition: getConditions(webPinnedSequence.contentType, webPinnedSequence.position, undefined),
            },
        ],
    };
}

function getPinSequenceFromPre(webPreSelected: PreSelectedInterlude): PinSequence {
    return {
        position: {
            absolute: webPreSelected.location,
        },
        interludes: [
            {
                preSelected: webPreSelected.interludes,
            },
        ],
    };
}

function getConditions(contentType?: string, position?: string, assemblyType?: string): Condition[] {
    return Object.entries({
        content_type: contentType,
        position: position,
        assembly_type: assemblyType,
    })
        .filter(([_, v]) => v)
        .map(([k, v]) => {
            return {
                comparison: EQ,
                field: k,
                value: v,
                itemType: 'interlude',
            } as Condition;
        });
}

/**
 * Auto-generate a minisequence name based on content type, position, assembly type
 * Expected to be used for new minisequences created on the UI, since the UI doesn't have an input field for name
 */
function makeMiniseqName(miniseq: MiniSequence) {
    return [miniseq.contentType, miniseq.position, miniseq.assemblyType]
        .filter((x) => x !== undefined)
        .reduce((res, prop) => `${res}#${prop}`);
}
