import React from 'react';
import {
    DatePicker,
    FormField,
    Checkbox,
    ExpandableSection,
    Icon,
    Input,
    Select,
    Spinner,
    Tooltip,
} from '@amzn/awsui-components-react';
import {
    asset_category_options,
    asset_type,
    available_language_options,
    available_market_options,
    getSelection,
    voice_asset_category_options,
} from '../../configs/multi-select-config';
import { fetchGetArtistMetadata } from '../../utils/fetchUtil';
import { Asset, AssetCategories } from '../../data-types';
import HelpInfoLink from '../help/HelpInfoLink';
import {
    assetCategoryHelp,
    voiceAssetCategoryHelp,
    voiceAssetBatchNumberHelp,
    voiceAssetMetadataHelp
} from '../help/HelpContent';

const InfoStatus = {
    display: 'block',
    marginTop: '5px',
};

const AuthorInvalid = (
    <span className="awsui-util-status-negative" style={InfoStatus}>
        <Icon name="status-warning" /> Provide an ASIN and marketplace
    </span>
);
const AuthorError = (
    <span className="awsui-util-status-negative" style={InfoStatus}>
        <Icon name="status-negative" /> Failed to get artist metadata
    </span>
);
const AuthorLoading = (
    <span className="awsui-util-status-inactive" style={InfoStatus}>
        <Spinner /> Loading
    </span>
);
const AuthorLoaded = (
    <span className="awsui-util-status-positive" style={InfoStatus}>
        <Icon name="status-positive" /> Loaded successfully
    </span>
);

interface State {
    authorInfo: JSX.Element | string;
    fileInfo: JSX.Element | string;
}

interface Props {
    asset: Asset;
    className?: string;
    isEdit?: boolean;
    hasBatch?: boolean;
    readonly: boolean;
    displayFileName?: boolean;
    onChange?: (e: CustomEvent) => void;
    onAuthorUpdate?: (name: string) => void;
}

class Data extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            authorInfo: '',
            fileInfo: '',
        };
    }

    handleChange = (e: CustomEvent): void => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.asset.auto_values !== this.props.asset.auto_values) {
            if (this.props.asset.auto_values) {
                this.fetchArtistName();
            } else {
                this.setState({ authorInfo: '' });
            }
        } else if (
            this.props.asset.auto_values &&
            (prevProps.asset.mtr !== this.props.asset.mtr || prevProps.asset.asin !== this.props.asset.asin)
        ) {
            this.fetchArtistName();
        }
    }

    fetchArtistName(): void {
        if (!this.props.asset.asin || !this.props.asset.mtr) {
            this.setState({ authorInfo: AuthorInvalid });
            return;
        }
        this.setState({ authorInfo: AuthorLoading });
        fetchGetArtistMetadata(this.props.asset.asin, this.props.asset.mtr)
            .then((metadata) => {
                if (metadata.name && this.props.onAuthorUpdate) {
                    this.props.onAuthorUpdate(metadata.name);
                }
                this.setState({ authorInfo: AuthorLoaded });
            })
            .catch(() => this.setState({ authorInfo: AuthorError }));
    }

    onAssetSaved = (): void => {
        this.setState({ fileInfo: '', authorInfo: '' });
    };

    enableBatchNumberInput() {
        return !(this.props.readonly || (this.props.isEdit && this.props.hasBatch));
    }

    render(): JSX.Element {
        const readonly = !!this.props.readonly;
        const isText = this.props.asset?.type === 'TTS';
        const isImage = this.props.asset?.type === 'IMAGE';
        const isAudio = this.props.asset?.type === 'AUDIO';
        const isVoiceUpsell = this.props.asset?.category?.startsWith('VOICE_UPSELL');
        const assetCategories = [...asset_category_options];
        const voiceAssetCategories = [...voice_asset_category_options];
        if (this.props.asset.category) {
            // Add an option to disassociate the asset from a category if one is already selected
            assetCategories.unshift({ label: 'NONE', id: '' });
            voiceAssetCategories.unshift({ label: 'NONE', id: '' });
        }
        const forceAutoValues = this.props.asset?.category === AssetCategories.DEFAULT_ARTIST_IMAGE;
        const responsiveFieldHalf = 'col-xxxs-6 col-xxs-4';
        const responsiveLabel = 'col-xxxs-12 col-xxs-3';
        const responsiveField = 'col-xxxs-12 col-xxs-9';
        // 1/4th width in big and small screen
        const responsiveField_25 = 'col-xxxs-3  col-xxs-2';
        // 1/3rd width on big screen, full width on small screen
        const responsiveField_33 = 'col-xxxs-12 col-xxs-3';
        // 2/3rd width on big screen, full width on small screen
        const responsiveField_66 = 'col-xxxs-12 col-xxs-6';
        return (
            <div className={this.props.className || ''}>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Type:</h4>
                        <Select
                            className={responsiveField}
                            options={asset_type}
                            selectedOption={getSelection(asset_type, this.props.asset.type)}
                            selectedLabel="Selected"
                            id="type"
                            onChange={this.handleChange}
                            disabled={!!this.props.isEdit || readonly}
                        />
                    </div>
                    {this.props.asset.type && (
                        <React.Fragment>
                            {isImage && (
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>Category:</h4>
                                    <div className={responsiveField}>
                                        <Select
                                            options={assetCategories}
                                            selectedOption={getSelection(
                                                asset_category_options,
                                                this.props.asset.category,
                                            )}
                                            selectedLabel="Selected"
                                            id="category"
                                            onChange={this.handleChange}
                                            disabled={readonly}
                                        />
                                        <div style={InfoStatus}>
                                            <HelpInfoLink content={assetCategoryHelp()} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isText && !isImage && (
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>Category:</h4>
                                    <div className={responsiveField}>
                                        <Select
                                            options={voiceAssetCategories}
                                            selectedOption={getSelection(
                                                voice_asset_category_options,
                                                this.props.asset.category,
                                            )}
                                            selectedLabel="Selected"
                                            id="category"
                                            onChange={this.handleChange}
                                            disabled={readonly}
                                        />
                                        <div style={InfoStatus}>
                                            <HelpInfoLink content={voiceAssetCategoryHelp()} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isText && (
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>Artist ASIN:</h4>
                                    <Input
                                        className={responsiveField}
                                        type="text"
                                        id="asin"
                                        value={this.props.asset.asin}
                                        onChange={this.handleChange}
                                        disabled={readonly}
                                    />
                                </div>
                            )}
                            {!isVoiceUpsell && (
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>{isImage ? 'Subject' : 'Speaker'}:</h4>
                                    <div className={!isText ? responsiveField_66 : responsiveField}>
                                        <Input
                                            type="text"
                                            value={this.props.asset.author}
                                            id="author"
                                            onChange={this.handleChange}
                                            disabled={this.props.asset.auto_values || readonly}
                                        />
                                        {this.state.authorInfo}
                                    </div>
                                    {!isText && !isVoiceUpsell && (
                                        <Checkbox
                                            className={responsiveField_33}
                                            id="auto_values"
                                            checked={!!this.props.asset.auto_values}
                                            onChange={this.handleChange}
                                            disabled={forceAutoValues || readonly}
                                        >
                                            Use artist ASIN
                                        </Checkbox>
                                    )}
                                </div>
                            )}
                            <div className="awsui-row">
                                <h4 className={responsiveLabel}>Description:</h4>
                                <Input
                                    className={responsiveField}
                                    type="text"
                                    value={this.props.asset.descr}
                                    id="descr"
                                    onChange={this.handleChange}
                                    disabled={readonly}
                                />
                            </div>
                            {isAudio && isVoiceUpsell && (
                                <div className="awsui-row">
                                    <h4 className={responsiveLabel}>
                                        <Tooltip text="This field represent the expiration for voice upsell for this audio, format should be YYYY/MM/DD">
                                            Expiration:
                                        </Tooltip>
                                    </h4>
                                    <FormField className={responsiveFieldHalf} stretch={true}>
                                        <DatePicker
                                            placeholder="YYYY/MM/DD"
                                            todayLabel="Today"
                                            id="exp_date"
                                            value={this.props.asset?.exp_date ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </FormField>
                                </div>
                            )}
                            <div className="awsui-row">
                                <ExpandableSection
                                    className="col-12"
                                    header="Additional Details"
                                    variant="borderless"
                                    expanded={!readonly}
                                >
                                    <div className="awsui-grid">
                                        <div className="awsui-row">
                                            <h4 className={responsiveLabel}>Language:</h4>
                                            <Select
                                                className={responsiveField}
                                                options={available_language_options}
                                                selectedOption={getSelection(
                                                    available_language_options,
                                                    this.props.asset.lang,
                                                )}
                                                selectedLabel="Selected"
                                                id="lang"
                                                onChange={this.handleChange}
                                                disabled={readonly}
                                            />
                                        </div>
                                        <div className="awsui-row">
                                            <h4 className={responsiveLabel}>Marketplace:</h4>
                                            <Select
                                                className={responsiveField}
                                                options={available_market_options}
                                                selectedOption={getSelection(
                                                    available_market_options,
                                                    this.props.asset.mtr,
                                                )}
                                                selectedLabel="Selected"
                                                id="mtr"
                                                onChange={this.handleChange}
                                                disabled={readonly}
                                            />
                                        </div>
                                        {isAudio && isVoiceUpsell && (
                                            <div className="awsui-row">
                                                <h4 className={responsiveLabel}>Batch Number:</h4>
                                                <div className={responsiveField}>
                                                    <Input
                                                        type="number"
                                                        value={this.props.asset.batch_number?.toString()}
                                                        id="batch_number"
                                                        onChange={this.handleChange}
                                                        disabled={!this.enableBatchNumberInput()}
                                                    />
                                                    <div style={InfoStatus}>
                                                        <HelpInfoLink content={voiceAssetBatchNumberHelp()} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {isAudio && isVoiceUpsell && (
                                            <div className="awsui-row">
                                                <h4 className={responsiveLabel}>
                                                    <Tooltip text="Additional metadata in JSON format.">
                                                        Metadata:
                                                    </Tooltip>
                                                </h4>
                                                <div className={responsiveField}>
                                                    <Input
                                                        type="text"
                                                        value={this.props.asset.media_metadata}
                                                        id="media_metadata"
                                                        onChange={this.handleChange}
                                                        disabled={readonly}
                                                    />
                                                    <div style={InfoStatus}>
                                                        <HelpInfoLink content={voiceAssetMetadataHelp()} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.props.displayFileName != false && (
                                            <div className="awsui-row">
                                                <h4 className={responsiveLabel}>
                                                    <Tooltip text="This field is not displayed in a multi-modal device. It will default to the file name used during bulk uploads.">
                                                        File Name:
                                                    </Tooltip>
                                                </h4>
                                                <Input
                                                    className={responsiveField}
                                                    type="text"
                                                    value={this.props.asset.origfile}
                                                    id="origfile"
                                                    onChange={this.handleChange}
                                                    disabled={true}
                                                />
                                            </div>
                                        )}
                                        {this.props.asset.type === 'IMAGE' && (
                                            <div className="awsui-row">
                                                <h4 className={responsiveLabel}>Dimension (pixels):</h4>
                                                <h5 className={responsiveField_25}>Height:</h5>
                                                <Input
                                                    className={responsiveField_25}
                                                    type="number"
                                                    id="height_pixels"
                                                    disabled={readonly}
                                                    value={
                                                        this.props.asset.height_pixels
                                                            ? this.props.asset.height_pixels.toString()
                                                            : ''
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <h5 className={responsiveField_25}>Width:</h5>
                                                <Input
                                                    className={responsiveField_25}
                                                    type="number"
                                                    id="width_pixels"
                                                    disabled={readonly}
                                                    value={
                                                        this.props.asset.width_pixels
                                                            ? this.props.asset.width_pixels.toString()
                                                            : ''
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </ExpandableSection>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}
export default Data;
