import { InterludeSchedule } from '../../data-types';
import {
    DATE_FORMAT,
    TIME_FORMAT,
    getDateTimeFromString,
    convertToDateTimeString,
    convertToTimeString,
} from '../../utils/dateTimeUtil';
import _ from 'lodash';

export const ALL_DAYS_FIELD_MAPPING: Record<string, string> = {
    SUN: 'Sunday',
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THU: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
};
export const ALL_DAYS = _.keys(ALL_DAYS_FIELD_MAPPING);
export const NONE_DAYS = 'NONE';

/** Flattened representation of the schedule form fields intended for use as React state reference */
export interface ScheduleForm {
    startEnabled: boolean;
    startDate: string;
    startTime: string;
    endEnabled: boolean;
    endDate: string;
    endTime: string;
    daysOfWeek: string[];
    hoursEnabled: boolean;
    hoursStartTime: string;
    hoursEndTime: string;
    useLocalTime: boolean;
}

export const modelToForm = (schedule?: InterludeSchedule): ScheduleForm => {
    const startEnabled = schedule?.start?.enabled ?? false;
    const startDateTime = getDateTimeFromString(schedule?.start?.dateTime);
    const startDate = startDateTime?.toFormat(DATE_FORMAT) ?? '';
    const startTime = startDateTime?.toFormat(TIME_FORMAT) ?? '';
    const endEnabled = schedule?.end?.enabled ?? false;
    const endDateTime = getDateTimeFromString(schedule?.end?.dateTime);
    const endDate = endDateTime?.toFormat(DATE_FORMAT) ?? '';
    const endTime = endDateTime?.toFormat(TIME_FORMAT) ?? '';

    const hoursEnabled = schedule?.hoursOfDay?.enabled ?? false;
    const hoursStartTime = getDateTimeFromString(schedule?.hoursOfDay?.start)?.toFormat(TIME_FORMAT) ?? '';
    const hoursEndTime = getDateTimeFromString(schedule?.hoursOfDay?.end)?.toFormat(TIME_FORMAT) ?? '';

    const daysOfWeek = schedule?.daysOfWeek === NONE_DAYS ? [] : schedule?.daysOfWeek?.split(',') ?? ALL_DAYS;
    const useLocalTime = schedule?.useLocal ?? false;
    return {
        startEnabled,
        startDate,
        startTime,
        endEnabled,
        endDate,
        endTime,
        hoursEnabled,
        hoursStartTime,
        hoursEndTime,
        daysOfWeek,
        useLocalTime,
    };
};

export const formToModel = (schedule: ScheduleForm): InterludeSchedule => {
    const startDateTime = convertToDateTimeString(schedule.startDate, schedule.startTime);
    const endDateTime = convertToDateTimeString(schedule.endDate, schedule.endTime);
    const hoursStart = convertToTimeString(schedule.hoursStartTime);
    const hoursEnd = convertToTimeString(schedule.hoursEndTime);
    const daysOfWeek = schedule.daysOfWeek.length > 0 ? schedule.daysOfWeek.join(',') : NONE_DAYS;

    //API schema enforces that the dateTime must be valid if enabled populated, cannot send {enabled: false, dateTime: (''|undefined)}
    const start = startDateTime ? { enabled: schedule.startEnabled, dateTime: startDateTime } : undefined;
    const end = endDateTime ? { enabled: schedule.endEnabled, dateTime: endDateTime } : undefined;
    const hoursOfDay =
        hoursStart && hoursEnd
            ? {
                  enabled: schedule.hoursEnabled,
                  start: hoursStart,
                  end: hoursEnd,
              }
            : undefined;
    const useLocal = schedule.useLocalTime;
    return { start, end, daysOfWeek, hoursOfDay, useLocal };
};
