import { Select } from '@amzn/awsui-components-react';
import { OperationDescription, OperationName, SupportedOperationType } from '../../../utils/imageUtil';
import { OperationType } from '@amzn/forge-image-processing-types';

export interface OperationSelectorProps {
    onSelected: (type: SupportedOperationType) => void;
}

function OperationSelector(props: OperationSelectorProps): JSX.Element {
    const options: Select.Option[] = [];
    for (const type in OperationType) {
        const key = OperationType[type as keyof typeof OperationType] as SupportedOperationType;
        options.push({
            id: key,
            label: OperationName[key],
            description: OperationDescription[key],
        });
    }
    const change = (e: CustomEvent<Select.ChangeDetail>) => {
        e.stopPropagation();
        if (e.detail.selectedId !== 'new') {
            props.onSelected(e.detail.selectedId as SupportedOperationType);
        }
    };
    return <Select options={options} placeholder="Select operation type" onChange={change}></Select>;
}

export default OperationSelector;
