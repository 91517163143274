import { HelpPanel, HelpPanelFooterLinks } from './HelpPanel';

import { Icon } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';

// Help content for different sections of the website

export function bulkUploadHelp() {
    return (
        <HelpPanel header="Upload guidelines">
            <p>Use only .csv metadata files for bulk uploading of Interlude, Asset and Fan Poll definitions.</p>
            <p>
                You can generate an example CSV file using the &quot;Export&quot; option from either of the{' '}
                <Link to="/interludes">Interludes</Link> or <Link to="/assets">Assets</Link> or{' '}
                <Link to="/fan-polls">Fan Polls</Link> tables, open in your favorite editor and replace the information
                as needed.
            </p>
            <p>You may select one or more files to upload at once.</p>
        </HelpPanel>
    );
}

export function interludeAssociationsHelp() {
    return (
        <HelpPanel header="Association guidelines">
            <h3>Overview</h3>
            <p>Associations define where in a customer's queue and on what station(s) an Interlude can be played.</p>
            <p>
                For example, if an Interlude is created with a list of Artist ASIN associations, then the Interlude
                <strong> can only be</strong> played adjacent to a Track that has at least one of the specified Artists.
            </p>
            <p>
                If an Interlude is created with a list of Station Key associations, then the Interlude
                <strong> can only be</strong> played on the specified stations.
            </p>
            <h3>Input</h3>
            <p>
                A list can be provided for each association <strong>type</strong>. Commas, spaces, or both can be used
                to separate list items.
            </p>
            <h3>Please note!</h3>
            <p>
                When no value is provided for an association <strong>type</strong>, then the Interlude has{' '}
                <strong>NO</strong> restrictions for that <strong>type</strong> of association.
            </p>
            <p>
                For example, if no value is provided for <strong>Station Keys</strong>, then the Interlude can be played
                on <strong>any</strong> station - with respect to any other <strong>type </strong>
                of association(s) that may have been provided.
            </p>
            <h3>Editing Associations</h3>
            <p>
                On the <strong>Edit Interlude</strong> page, update the association fields as necessary and click{' '}
                <strong>Save</strong>.
            </p>
            <p>
                When you need to remove an association <strong>type</strong>, remove everything from the respective
                field and click <strong>Save</strong>.
            </p>
        </HelpPanel>
    );
}

export function replacementTokensHelp() {
    const links = [
        {
            url: 'https://w.amazon.com/bin/view/Amazon_Music/Storm/Tech/FieldTokens/',
            label: 'Field tokens',
            external: true,
        },
        {
            url: 'https://w.amazon.com/bin/view/Amazon_Music/Storm/Tech/InterludesV1/#HTTSVariables',
            label: 'TTS variables',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Replacement tokens">
            <p>
                Replacement tokens are substituted at runtime based on the tracks surrounding the interlude. Tokens
                contain a reference to a track and the metadata for that track used as replacement. Position and
                metadata references are separated by an underscode character. To include a token in a template or
                display string, surround its reference with curly braces, for example:
                <code>{'{prev_track_short_title}'}</code>
            </p>
            <h3>Track position references</h3>
            <p>
                Use the syntax <code>prev</code> or <code>next</code> to reference tracks immediately before or after an
                interlude. Use the syntax <code>prev(#)</code> or <code>next(#)</code>) to reference tracks beyond
                those. Note that <code>prev(1)</code> is equivalent to <code>prev</code> and <code>next(1)</code> is
                equivalent to <code>next</code>
            </p>
            <h3>Metadata references</h3>
            <h4>Aggregates</h4>
            <ul>
                <li>
                    <code>track_short_title</code> The short version of the track title, example: "Blinding Lights"
                </li>
                <li>
                    <code>album_short_title</code> The short version of the album title, example: "After Hours"
                </li>
                <li>
                    <code>concatenated_artist_name</code> The name of the artist(s) associated with the track, example:
                    "The Weeknd"
                </li>
                <li>
                    <code>album_local_original_release_date</code> The date the track was released
                </li>
                <li>
                    <code>album_local_original_release_date-[dateformat]</code> *See "Date formats" section below
                </li>
            </ul>
            <h4>Charts</h4>
            <ul>
                <li>
                    <code>charts_rank</code> Rank of the track within the charts, example: "4"
                </li>
                <li>
                    <code>charts_type</code> Type of the charts, example: "Billboards"
                </li>
                <li>
                    <code>charts_category</code> Subtype of the charts, example: "Top 100"
                </li>
                <li>
                    <code>charts_period</code> Tracking period, example: "week"
                </li>
                <li>
                    <code>charts_period_count</code> Longest duration at this rank within the charts
                </li>
                <li>
                    <code>charts_start_of_period</code> When it first appeared at this rank in the charts
                </li>
                <li>
                    <code>charts_start_of_period-[dateformat]</code> *See "Date formats" section below
                </li>
            </ul>
            <h3>Date formats</h3>
            <p>
                Certain metadata tokens that reference a date can be custom-formatted by specifying a suffix of the form{' '}
                <code>-[format]</code> that combines one or more of the following: <code>y</code> (year),
                <code>m</code> (month) or <code>d</code> (day).
            </p>
            <p>
                For example: <code>next_charts_start_of_period-d</code> will read the day when the next track first
                appeared in its current rank within the charts.
            </p>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}

export function stagedChangesHelp() {
    const links = [
        {
            url: 'https://quip-amazon.com/St0vASAcbTtx/Forge-Staging-Flow-Changes',
            label: 'Staging Changes in Forge',
            external: true,
        },
        {
            url: 'https://quip-amazon.com/wGicAjQ0hoSh/Catalog-Staging-Design',
            label: 'Catalog Staging Design',
            external: true,
        },
        {
            url: 'https://tiny.amazon.com/yurq7awn/acceamazawsconsrolearnForg',
            label: 'Sandbox Customer Override Configuration',
            external: true,
        },
        {
            url: 'https://amzn-media.slack.com/archives/G01D270TQQ4',
            label: 'Forge Slack Channel',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Staged Changes">
            <p>
                Forge allows users to specify the target stage for changes performed via the website. There are
                currently 2 stages: *Live* and *Sandbox*. Any changes made to the catalog data in the Live stage are
                visible to all Music customers in Production immediately. Changes done in the Sandbox stage are visible
                only to customers in the allow-list (more details below). Forge users can use the toggle at the top of
                the page between Live and Sandbox.
            </p>
            <h3>Why use the Sandbox?</h3>
            <p>
                Staged changes in the Storm catalog are entries that are only visible to a small number of Music
                customers in Production. This allows Forge users to iterate, creating or editing entries in the catalog
                and testing those changes without impacting Production customers. The content in the Sandbox can also be
                used to run simulations to determine the quality of the changes. Once all changes have been validated
                they can be made available to all customers by ‘promoting’ the changes to the Live stage.
            </p>
            <h3>What data can be staged?</h3>
            <ul>
                <li>Interludes</li>
                <li>Assets</li>
            </ul>
            <h3>How to use the Sandbox?</h3>
            <p>
                Use the following sequence of steps to safely make changes to the catalog using Forge, leveraging the
                Sandbox:
            </p>
            <ol>
                <li>
                    Make changes as needed to <Link to="/interludes">Interludes</Link> or{' '}
                    <Link to="/assets">Assets</Link>
                    in the Sandbox stage.
                </li>
                <li>
                    Test the changes in Production using a CID that is allow-listed for the Sandbox. Tests may be manual
                    or via simulation.
                </li>
                <li>
                    Once the changes have been successfully validated, use the table in this section to select all
                    related changes and promote them to Live at once.
                </li>
            </ol>
            <h3>Adding a CID to the Sandbox override</h3>
            <p>
                Changes made to the catalog in the Sandbox are only visible to CIDs that have been allow-listed via
                configuration. To do that, follow the steps below. If you run into any issues with these steps, shoot
                out a message to the{' '}
                <a href="https://amzn-media.slack.com/archives/G01D270TQQ4" target="_blank" rel="noreferrer">
                    Forge Slack Channel <Icon name="external"></Icon>
                </a>
                .
            </p>
            <ol>
                <li>
                    Open the{' '}
                    <a
                        href="https://tiny.amazon.com/yurq7awn/acceamazawsconsrolearnForg"
                        target="_blank"
                        rel="noreferrer"
                    >
                        catalog-staging-customer-overrides <Icon name="external"></Icon>
                    </a>{' '}
                    AppSync configuration profile
                </li>
                <li>Inspect the content of the configuration and check if the CID(s) are already allow-listed</li>
                <li>
                    If not, hit the <span style={{ fontWeight: 'bold' }}>Create</span> button to add a new configuration
                    version
                </li>
                <li>Add the desired CID(s) to the sandbox array.</li>
                <li>
                    Hit the button <span style={{ fontWeight: 'bold' }}>Create hosted configuration version</span>
                </li>
                <li>
                    Back in the configuration profile, make sure the latest version is selected and that it contains the
                    new CID(s)
                </li>
                <li>
                    Hit the <span style={{ fontWeight: 'bold' }}>Start deployment</span> button at the top of the page
                </li>
                <li>
                    In the page that follows, from the <span style={{ fontWeight: 'bold' }}>Deployment strategy</span>{' '}
                    drop-down select the <span style={{ fontWeight: 'bold' }}>AllAtOnceDeploymentStrategy</span>
                </li>
                <li>
                    Hit the <span style={{ fontWeight: 'bold' }}>Start deployment</span> button at the bottom of the
                    page
                </li>
                <li>The new configuration is now deployed to Prod</li>
            </ol>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}

export function assetCategoryHelp() {
    const links = [
        {
            url: 'https://quip-amazon.com/2tKVA37BGZv6/Default-Artist-Images',
            label: 'Default Artist Images',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Asset Categories">
            <p>
                This optional field allows users to further specify how the asset is intended to be used. By default
                this field will be empty. If the asset does not fit any of the categories specified below, the field
                should be left empty. If there are no valid categories for a given asset type (e.g. Text or Audio) this
                field will not show up in the Forge UI.
            </p>
            <h3>Categories for Images</h3>
            <h4>DEFAULT_ARTIST_IMAGE</h4>
            <p>
                Image assets with this category represent the default artist profile image that should be associated
                with interludes that feature the voice of the artist (by ASIN). There can only be 1 asset with this
                category associated with the same ASIN / Marketplace combination.
            </p>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}

export function voiceAssetCategoryHelp() {
    const links = [
        {
            url: 'https://quip-amazon.com/LssEA5VD4q9o/Artist-Voice-Upsell-Audio-Upload',
            label: 'Category of Audio use cases',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Asset Categories">
            <p>
                This field allows users to further specify how the Audio asset is intended to be used. This is an
                optional field. For DJ Mode please leave it blank. For voice upsell use cases we have two different
                cases of now:
                <ul>
                    <li>
                        VOICE_UPSELL_AUDIO_NEW_RELEASE : The asset will be used only for new releases of that artist
                    </li>
                    <li>
                        VOICE_UPSELL_AUDIO_ENTIRE_CATALOG: The asset will be used for the entire catalog of that artist
                    </li>
                </ul>
            </p>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}

export function voiceAssetBatchNumberHelp() {
    const links = [
        {
            url: 'https://quip-amazon.com/fyLaAo966AQc/SOP-for-using-Batch-Number-in-Forge',
            label: 'SOP for using Batch Number',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Asset Batch Number">
            <p>This is an optional field and cannot be edited once set.</p>
            <p>It should only be entered if this audio is planned to be used in voice upsell experiments.</p>
            <p>It represents the experiment batch of which this audio asset is a part of.</p>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}

export function voiceAssetMetadataHelp() {
    const jsonObject = {
        duration: 20,
        accent: "British",
        source: "AI/Voice Actor/Artist"
    };
    const jsonString = JSON.stringify(jsonObject, null, 2);
    return (
        <HelpPanel header="Asset Metadata">
            <p>This is an optional field to store additional metadata in json format.</p>
            <p>Example Metadata:</p>
            <pre><code className="json">{ jsonString }</code></pre>
        </HelpPanel>
    );
}

export function interludeDefaultImageHelp() {
    const links = [
        {
            url: 'https://quip-amazon.com/2tKVA37BGZv6/Default-Artist-Images',
            label: 'Default Artist Images',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Default Image">
            <p>
                Clicking on the <span style={{ fontWeight: 'bold' }}>Default</span> button next to{' '}
                <span style={{ fontWeight: 'bold' }}>Image Asset ID</span> will attempt to retrieve the Asset ID for the
                default image associated with the first ASIN that shows in the{' '}
                <span style={{ fontWeight: 'bold' }}>Artist ASIN</span> field.
            </p>
            <p>
                If a default artist image Asset does not exist, a new one will be created and its Asset ID will be
                associated with this Interlude.
            </p>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}
