import { useContext, useRef } from 'react';
import { StageContext } from '../components/StageContext';

/**
 * Returns the current Forge data stage value as a string (sandbox/live). Accepts a callback function for handling data stage changes.
 * @param onDatastageChange
 * A function to be called when the data stage changes. Passes the new data stage value as a parameter.
 */
export default function useDatastage(onDatastageChange: (newDatastage: string) => void) {
    const { dataStage } = useContext(StageContext);
    const previousDataStage = useRef(dataStage);

    if (previousDataStage.current !== dataStage) {
        previousDataStage.current = dataStage;
        onDatastageChange(dataStage);
    }

    return dataStage;
}
