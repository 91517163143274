import { Icon } from '@amzn/awsui-components-react';

export interface HelpPanelProps {
    header: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
}

/**
 * Helper component that renders content inside the toolbox panel following the AWSUI design guidelines
 * See: https://v2-1.polaris.a2z.com/components/help_panel/
 */
export function HelpPanel(props: HelpPanelProps) {
    return (
        <div className="awsui-util-help-panel">
            <div className="awsui-util-help-panel-header">
                <h2>{props.header}</h2>
            </div>
            {props.children}
            {props.footer}
        </div>
    );
}

export interface HelpPanelFooterLinksProps {
    title?: string;
    links: {
        url: string;
        label: string;
        external?: boolean;
    }[];
}

/**
 * Helper component that renders a list of links in the footer section of the toolbox panel
 * following the AWSUI design guidelines. Meant to be nested inside a <HelpPanel>
 */
export function HelpPanelFooterLinks(props: HelpPanelFooterLinksProps) {
    return (
        <div className="awsui-util-help-panel-footer">
            <h3>{props.title || 'Other resources'}</h3>
            <ul>
                {props.links.map((link, i) => (
                    <li key={`${i}`}>
                        <a href={link.url} target={link.external ? '_blank' : '_self'} rel="noreferrer">
                            {link.label}
                            {link.external && <Icon name="external" />}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
