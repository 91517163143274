import { Table, TableSorting } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { ServerInterlude } from '../data-types';
import { ColumnDefinitionWithId } from '../utils/tableUtil';

export interface InterludeTableItem extends ServerInterlude, Table.Item {
    // Used to join fields from Interlude and Table.Item - no new fields required
}

export const COLUMN_DEFINITIONS: ColumnDefinitionWithId<InterludeTableItem>[] = [
    {
        id: 'id',
        header: () => 'Interlude ID',
        cell: (item) => (
            <div>
                <Link to={`/interludes/${item.id}`}>{item.id}</Link>
            </div>
        ),
        minWidth: '180px',
        allowLineWrap: true,
    },
    {
        id: 'a_type',
        cell: (item) => item.a_type,
        header: () => 'Assembly Type',
        minWidth: '160px',
        allowLineWrap: true,
    },
    {
        id: 'asset_ids',
        cell: (item) =>
            item.asset_ids
                ? item.asset_ids.media
                    ? item.asset_ids.media.join(',')
                    : item.asset_ids.text
                    ? item.asset_ids.text.join(',')
                    : ''
                : '',
        header: () => 'Asset IDs',
        minWidth: '160px',
        allowLineWrap: true,
    },
    {
        id: 'imgs',
        cell: (item) => (item.imgs ? item.imgs.map((img: any) => img.id).join(',') : ''),
        header: () => 'Image ID',
        minWidth: '160px',
        allowLineWrap: true,
    },
    {
        id: 'lang',
        header: () => 'Available Languages',
        cell: (item) => (item.lang ? item.lang.toString() : ''),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'mtr_list',
        header: () => 'Available Territories',
        cell: (item) => (item.mtr_list ? item.mtr_list.toString() : ''),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'src',
        header: () => 'Content Source',
        cell: (item) => item.src,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'c_type',
        header: () => 'Content Type',
        cell: (item) => item.c_type,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'crt_time',
        header: () => 'Creation Date Time',
        cell: (item) => item.crt_time,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'descr',
        header: () => 'Description',
        cell: (item) => item.descr,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'associations',
        header: () => 'Associations',
        cell: (item) => item.associations,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'is_edtrl',
        header: () => 'Is Editorial',
        cell: (item) => (item.is_edtrl ? item.is_edtrl.toString() : 'false'),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'pos',
        header: () => 'Position',
        cell: (item) => item.pos,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'rank',
        header: () => 'Rank',
        cell: (item) => item.rank,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'tiers',
        header: () => 'Service Tiers',
        cell: (item) => (item.tiers ? item.tiers.toString() : ''),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'is_explicit',
        header: () => 'Is Explicit',
        cell: (item) => (item.is_explicit ? item.is_explicit.toString() : 'false'),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'is_mixable',
        header: () => 'Is Mixable',
        cell: (item) => (item.is_mixable ? item.is_mixable.toString() : 'false'),
        minWidth: '100px',
    },
    {
        id: 'is_skippable',
        header: () => 'Is Skippable',
        cell: (item) => (item.is_skippable ? item.is_skippable.toString() : 'false'),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'data_stage',
        header: () => 'Stage',
        cell: (item) => item.data_stage || 'live',
    },
    {
        id: 'pri_txt',
        header: () => 'Primary Text',
        cell: (item) => item.pri_txt,
    },
    {
        id: 'sec_txt',
        header: () => 'Secondary Text',
        cell: (item) => item.sec_txt,
    },
];

const idRegex = /([a-z]+)-([0-9]+)/i;

function sortId(item1?: InterludeTableItem, item2?: InterludeTableItem): number {
    if (!item1?.id || !item2?.id) return 0;
    if (!item1?.id) return -1;
    if (!item2?.id) return -1;

    const m1 = item1.id.match(idRegex);
    const m2 = item2.id.match(idRegex);

    // "IL-1234" => ["IL-1234", "IL", "1234"]

    if (!m1 || !m2 || m1[1] !== m2[1]) return item1.id === item2.id ? 0 : item1.id < item2.id ? -1 : 1;

    return parseInt(m1[2]) - parseInt(m2[2]);
}

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<InterludeTableItem>[] = [
    { id: 'id', comparator: sortId },
    { id: 'a_type', field: 'a_type' },
    { id: 'asset_ids', field: 'asset_ids' },
    { id: 'imgs', field: 'imgs' },
    { id: 'lang', field: 'lang' },
    { id: 'mtr_list', field: 'mtr_list' },
    { id: 'src', field: 'src' },
    { id: 'c_type', field: 'c_type' },
    { id: 'crt_time', field: 'crt_time' },
    { id: 'descr', field: 'descr' },
    { id: 'is_edtrl', field: 'is_edtrl' },
    { id: 'pos', field: 'pos' },
    { id: 'rank', field: 'rank' },
    { id: 'tiers', field: 'tiers' },
    { id: 'is_explicit', field: 'is_explicit' },
    { id: 'is_mixable', field: 'is_mixable' },
    { id: 'is_skippable', field: 'is_skippable' },
    { id: 'data_stage', field: 'data_stage' },
    { id: 'pri_txt', field: 'pri_txt' },
    { id: 'sec_txt', field: 'sec_txt' },
];

export const FILTERING_FIELDS = [
    'a_type',
    'lang',
    'mtr_list',
    'src',
    'c_type',
    'is_edtrl',
    'pos',
    'rank',
    'service_tiers',
    'is_explicit',
    'is_mixable',
    'is_skippable',
];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main Properties',
        options: [
            { id: 'id', label: 'Interlude ID', editable: false, visible: true },
            { id: 'data_stage', label: 'Data Stage', editable: true, visible: true },
            { id: 'crt_time', label: 'Creation Date Time', editable: true, visible: false },
            { id: 'descr', label: 'Description', editable: true, visible: true },
        ],
    },
    {
        label: 'Sequencing',
        options: [
            { id: 'a_type', label: 'Assembly Type', editable: true, visible: true },
            { id: 'src', label: 'Content Source', editable: true, visible: true },
            { id: 'c_type', label: 'Content Type', editable: true, visible: true },
            { id: 'pos', label: 'Position', editable: true, visible: false },
            { id: 'rank', label: 'Rank', editable: true, visible: false },
            { id: 'is_edtrl', label: 'Is Editorial', editable: true, visible: false },
            { id: 'is_explicit', label: 'Is Explicit', editable: true, visible: false },
            { id: 'is_mixable', label: 'Is Mixable', editable: true, visible: false },
            { id: 'is_skippable', label: 'Is Skippable', editable: true, visible: false },
        ],
    },
    {
        label: 'Assets',
        options: [
            { id: 'asset_ids', label: 'Asset IDs', editable: true, visible: true },
            { id: 'imgs', label: 'Image Assets', editable: true, visible: true },
        ],
    },
    {
        label: 'Playback Eligibility',
        options: [
            { id: 'service_tiers', label: 'Service Tiers', editable: true, visible: false },
            { id: 'lang', label: 'Available Languages', editable: true, visible: true },
            { id: 'mtr_list', label: 'Available Markets', editable: true, visible: false },
            { id: 'associations', label: 'Associations', editable: true, visible: true },
        ],
    },
    {
        label: 'Display Text',
        options: [
            { id: 'pri_txt', label: 'Primary Text', editable: true, visible: false },
            { id: 'sec_txt', label: 'Secondary Text', editable: true, visible: false },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Interludes' },
    { value: 15, label: '15 Interludes' },
    { value: 30, label: '30 Interludes' },
    { value: 100, label: '100 Interludes' },
    { value: 500, label: '500 Interludes' },
    { value: 1000, label: '1000 Interludes' },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter interludes by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear filters',
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter distributions',
    filteringStatusText: 'Loading',
};
