import React from 'react';
import { Button, Flashbar, Icon, Modal, Spinner, Tabs } from '@amzn/awsui-components-react';
import {
    fetchGetInterlude,
    fetchUpdateInterlude,
    fetchDeleteInterlude,
    fetchRevertInterlude,
    fetchCreateInterlude,
} from '../utils/fetchUtil';
import {
    service_tier_default,
    available_language_default,
    available_market_default,
} from '../configs/multi-select-config';
import { deepCopy } from '../utils/jsonUtil';
import EditDataTab from './interludes/EditDataTab';
import EditAssetsTab from './interludes/EditAssetsTab';
import { itemError, itemInfo, itemSuccess, itemWarning } from './commons/flash-messages';
import { applyChange } from '../utils/eventUtil';
import PageHeader, { PageHeaderButton } from './PageHeader';
import { RouteComponentProps } from 'react-router-dom';
import { DataStage, PropsWithDataStage, withDataStage } from './StageContext';
import { Interlude, ServerInterlude } from '../data-types';
import ScheduleTab from './interludes/ScheduleTab';
import { interludeValidation } from '../validation/interludeValidation';
import _ from 'lodash';

interface State {
    initial?: ServerInterlude; // Values since last time interlude was fetched
    interlude?: Interlude; // Current values based on UX changes
    activeTabId?: string;
    isEdit: boolean;
    loading: boolean;
    saving: boolean;
    deleting: boolean; // Reused by the revert popup
    flashbar: Flashbar.MessageDefinition[];
    showDeletePopup: boolean;
    showRevertPopup: boolean;
    showSavePopup: boolean;
}

interface RouteParams {
    interludeId: string;
}

const rank_default = 1;

type Props = PropsWithDataStage & RouteComponentProps<RouteParams>;

class EditInterlude extends React.Component<Props, State> {
    interlude: Interlude = {};

    constructor(props: any) {
        super(props);
        this.state = {
            initial: undefined, // Values since last time interlude was fetched
            interlude: undefined, // Current values based on UX changes
            isEdit: true,
            loading: false,
            saving: false,
            deleting: false,
            flashbar: [],
            showDeletePopup: false,
            showRevertPopup: false,
            showSavePopup: false,
        };
    }

    componentDidMount(): void {
        this.loadInterludeDetails().catch((error) =>
            this.setState({ flashbar: [itemError('Failed to load interlude', error)] }),
        );
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.match?.params?.interludeId !== this.props.match?.params?.interludeId) {
            // User navigated to a different URL that maps to the same component
            // React does not (by design) unload the component, so we need to cleanup the state
            this.setState({ flashbar: [], activeTabId: 'data' });
            this.loadInterludeDetails();
        }
        if (this.state.isEdit && this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadInterludeDetails();
        }
    }

    async loadInterludeDetails(): Promise<void> {
        const interludeId = this.props.match.params.interludeId;
        if (!interludeId) {
            // Initialize default values
            this.interlude = {
                rank: rank_default,
                tiers: service_tier_default.map((tier) => tier.id),
                lang: available_language_default.map((lang) => lang.id),
                mtr_list: available_market_default.map((mtr) => mtr.id),
            };
            this.setState({
                initial: undefined,
                interlude: deepCopy(this.interlude),
                isEdit: false,
            });
            return;
        }
        this.setState({ isEdit: true, loading: true });
        await fetchGetInterlude(interludeId, this.props.dataStage)
            .then((interlude) => {
                this.interlude = interlude;
                this.setState({ interlude: deepCopy(interlude), initial: deepCopy(interlude) });
            })
            .catch((e) => this.setState({ interlude: undefined, flashbar: [itemError('Failed to load interlude', e)] }))
            .finally(() => this.setState({ loading: false }));
    }

    createInterludeTemplate = (): Promise<void> => {
        this.setState({ saving: true });
        return fetchCreateInterlude(this.interlude, this.props.dataStage)
            .then((id) => {
                this.setState({
                    flashbar: [
                        itemSuccess(`Interlude ${id} created successfully`).withButton('View interlude', () => {
                            this.props.history.push(`/interludes/${id}`);
                        }),
                    ],
                });
            })
            .catch((error) => {
                this.setState({
                    flashbar: [itemError('Failed to create interlude', error)],
                });
            })
            .finally(() => this.setState({ saving: false }));
    };

    saveInterludeTemplate = (): Promise<void> => {
        this.setState({ saving: true, showSavePopup: false });
        return fetchUpdateInterlude(this.interlude, this.props.dataStage)
            .then(() => {
                // Temporary attempt at a multi-item flashbar
                // Will later introduce a proper class to hide this complexity
                const flashbar = [itemSuccess('Interlude saved')];
                this.setState({ flashbar: flashbar });
                this.loadInterludeDetails().catch((e) => {
                    flashbar.push(itemError('Failed to reload interlude', e));
                    this.setState({ flashbar: flashbar });
                });
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to save interlude', e)] }))
            .finally(() => this.setState({ saving: false }));
    };

    deleteInterludeTemplate = (): Promise<void> => {
        this.setState({ deleting: true, showDeletePopup: false });
        const interludeId = this.props.match.params.interludeId;
        return fetchDeleteInterlude(interludeId, this.props.dataStage)
            .then(() => {
                this.setState({ interlude: undefined, initial: undefined });
                this.props.history.push('/interludes', { reload: true });
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to delete interlude', e)] }))
            .finally(() => this.setState({ deleting: false }));
    };

    revertInterludeTemplate = (): Promise<void> => {
        this.setState({ deleting: true, showRevertPopup: false });
        const interludeId = this.props.match.params.interludeId;
        return fetchRevertInterlude(interludeId, this.props.dataStage)
            .then(() => {
                this.setState({ flashbar: [itemSuccess('Interlude changes reverted')] });
                fetchGetInterlude(interludeId, this.props.dataStage)
                    .then(() => this.loadInterludeDetails())
                    .catch(() => this.props.history.push('/interludes', { reload: true }));
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to revert interlude', e)] }))
            .finally(() => this.setState({ deleting: false }));
    };

    /** deprecated - use updateInterlude to propagate changes upwards from newer components */
    handleChange = (e: CustomEvent): void => {
        // If a user specifies the wildcard value while editing the association,
        // then empty the value and propagate that to the interlude via applyChange()
        if (this.checkForWildcardInAssociation(e)) {
            e.detail.value = '';
            this.setState({ flashbar: [itemError('Invalid Association:', 'Wildcards "*" are not supported')] });
        }
        applyChange(e, this.interlude);
        this.setState({ interlude: deepCopy(this.interlude) });
    };

    checkForWildcardInAssociation = (e: CustomEvent): boolean => {
        // Check if an association field is being edited and if '*' is the value
        const target: any = e.target;
        const id = target.id;
        const associations = ['track_asin', 'album_asin', 'artist_asin', 'stn_key'];
        return associations.includes(id) && e.detail.value.includes('*');
    };

    updateInterlude = (interlude: Interlude): void => {
        this.interlude = interlude as ServerInterlude;
        this.setState({ interlude: deepCopy(this.interlude) });
    };

    updateInterludeSchedule = (schedule: Interlude['schedule']): void => {
        this.updateInterlude(_.chain(this.state.interlude).cloneDeep().assign({ schedule }).value());
    };

    fillAssetDetails = (asset: any): boolean => {
        let filled = false;
        if (asset && asset.author && !this.interlude['pri_txt']) {
            filled = true;
            this.interlude['pri_txt'] = asset.author;
        }
        if (asset && asset.descr && !this.interlude['sec_txt']) {
            filled = true;
            this.interlude['sec_txt'] = asset.descr;
        }
        if (filled) {
            this.setState({ interlude: deepCopy(this.interlude) }); // Propagate the state change down to components
        }
        return filled;
    };

    render(): JSX.Element {
        const headerText = this.state.isEdit ? `Edit interlude: ${this.state.interlude?.id}` : 'Create interlude';
        const headerButtons: PageHeaderButton[] = [];
        if (this.state.isEdit) {
            headerButtons.push({
                text: 'Save',
                disabled: interludeValidation.get().hasErrors() || this.state.deleting,
                loading: this.state.saving,
                variant: 'primary',
                onClick: () => this.setState({ showSavePopup: true }),
            });
        } else {
            headerButtons.push({
                text: 'Create',
                disabled: interludeValidation.get().hasErrors(),
                loading: this.state.saving,
                variant: 'primary',
                onClick: this.createInterludeTemplate,
            });
        }
        if (this.props.dataStage === DataStage.Live) {
            headerButtons.unshift({
                text: 'Delete',
                disabled: this.state.saving,
                loading: this.state.deleting,
                onClick: () => this.setState({ showDeletePopup: true }),
            });
        }
        const tabs: Tabs.Tab[] = this.state.interlude
            ? [
                  {
                      label: 'Interlude data',
                      id: 'data',
                      content: (
                          <EditDataTab
                              dataStage={this.props.dataStage}
                              initial={this.state.initial}
                              current={this.state.interlude}
                              isEdit={this.state.isEdit}
                              onChange={this.handleChange}
                              onUpdate={this.updateInterlude}
                              fillAssetDetails={this.fillAssetDetails}
                          />
                      ),
                  },
                  {
                      // Simplistic indication of tab error (only this one is validated currently)
                      label: (
                          <>
                              Schedule{' '}
                              {interludeValidation.get().hasErrorsByGroup('schedule') ? (
                                  <Icon variant="error" name="status-negative" />
                              ) : (
                                  ''
                              )}
                          </>
                      ),
                      id: 'schedule',
                      content: (
                          <ScheduleTab
                              dataStage={this.props.dataStage}
                              initial={this.state.initial}
                              current={this.state.interlude}
                              onChange={this.updateInterludeSchedule}
                              validator={interludeValidation}
                          />
                      ),
                  },
              ]
            : [];
        if (this.state.isEdit && this.state.interlude) {
            tabs.push({
                label: 'Assets',
                id: 'assets',
                content: <EditAssetsTab current={this.state.interlude} dataStage={this.props.dataStage} />,
            });
        }
        let flashbar = this.state.flashbar;
        if (this.props.dataStage === DataStage.Live) {
            const item = itemWarning(
                'You are editing Live content',
                'The changes to this Interlude are being made against the Live stage and will be visible to all customers. Please make sure this is intended before saving your changes.',
            ).isDismissible(false);
            flashbar = flashbar.concat(item);
        }
        if (this.state.interlude?.data_stage === DataStage.Sandbox) {
            const item = itemInfo('', 'This interlude has staged changes. Showing contents from the sandbox below.')
                .isDismissible(false)
                .withButton('Unstage', () => this.setState({ showRevertPopup: true }));
            flashbar = flashbar.concat(item);
        }
        return (
            <div className="awsui-grid">
                <Flashbar items={flashbar}></Flashbar>
                <Modal
                    visible={this.state.showDeletePopup}
                    header="Delete Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeletePopup: false })}>
                                {this.props.dataStage === DataStage.Live ? 'Cancel' : 'Ok'}
                            </Button>
                            {this.props.dataStage === DataStage.Live && (
                                <Button variant="primary" onClick={this.deleteInterludeTemplate}>
                                    Confirm
                                </Button>
                            )}
                        </span>
                    }
                >
                    This will delete the Asset record from LIVE along with any staged changes in the SANDBOX.
                </Modal>
                <Modal
                    visible={this.state.showRevertPopup}
                    header="Revert Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showRevertPopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.revertInterludeTemplate}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will remove the staged changes for this Interlude Template and linked associations.
                </Modal>
                <Modal
                    visible={this.state.showSavePopup}
                    header="Save Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showSavePopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.saveInterludeTemplate}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will update the Interlude Template with the current specified information.
                </Modal>
                {this.state.loading ? (
                    <span className="awsui-util-status-inactive">
                        <Spinner /> Loading
                    </span>
                ) : (
                    this.state.interlude && (
                        <div>
                            <PageHeader text={headerText} buttons={headerButtons} />
                            <Tabs
                                tabs={tabs}
                                activeTabId={this.state?.activeTabId ?? 'data'}
                                onChange={(ev) => this.setState({ activeTabId: ev.detail.activeTabId })}
                            ></Tabs>
                        </div>
                    )
                )}
            </div>
        );
    }
}
export default withDataStage(EditInterlude);
