export const jsonStorage = {
    save: (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value)),
    load: (key: string) => {
        const value = localStorage.getItem(key);
        try {
            return value && JSON.parse(value);
        } catch (e) {
            console.warn(
                `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`,
            );
            return undefined;
        }
    }
}

export interface PropertyStorage<T> {
    save: (property: T) => Promise<void>;
    load: () => Promise<T>;
}

export function createPropertyStorage<T>(name: string): PropertyStorage<T> {
  return {
    save: async (property: T) => jsonStorage.save(name, property),
    load: async () => jsonStorage.load(name)
  }
}