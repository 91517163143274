import React from 'react';
import { TableHeader } from './commons/common-components';
import { Button, ButtonDropdown } from '@amzn/awsui-components-react';
import { ExperienceState, ExperienceType } from '@amzn/mousai-service-client';

export interface Props {
    totalItems: number;
    selectedItems: any[];
    stateFilter: ExperienceState;
    typeFilter: ExperienceType;
    loading: boolean;
    onReload: () => void;
    onStateTransition: (state: ExperienceState) => void;
}

export const VoiceCommentsTableButtons: React.FC<Props> = (props: Props) => {
    const responsiveButtons: Button.Props[] = [];
    const disabled = props.selectedItems.length == 0;
    switch (props.typeFilter) {
        case 'VOICE_COMMENT':
            switch (props.stateFilter) {
                case 'DRAFT':
                    responsiveButtons.push(
                        {
                            text: 'Approve',
                            disabled,
                            onClick: () => props.onStateTransition(ExperienceState.APPROVED),
                        },
                        {
                            text: 'Reject',
                            disabled,
                            onClick: () => props.onStateTransition(ExperienceState.REJECTED),
                        },
                        {
                            text: 'Review',
                            disabled,
                            onClick: () => props.onStateTransition(ExperienceState.REVIEW),
                        },
                    );
                    break;
                case 'REVIEW':
                    responsiveButtons.push(
                        {
                            text: 'Approve',
                            disabled,
                            onClick: () => props.onStateTransition(ExperienceState.APPROVED),
                        },
                        {
                            text: 'Reject',
                            disabled,
                            onClick: () => props.onStateTransition(ExperienceState.REJECTED),
                        },
                    );
                    break;
                case 'APPROVED':
                case 'REJECTED':
                    responsiveButtons.push({
                        text: 'Delete',
                        disabled,
                        onClick: () => props.onStateTransition(ExperienceState.DELETED),
                    });
                    break;
            }
    }

    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    return (
        <TableHeader
            {...props}
            title="Voice Comments"
            actionButtons={
                <div className="awsui-util-action-stripe-group">
                    <Button
                        icon="refresh"
                        disabled={props.loading}
                        loading={props.loading}
                        onClick={() => props.onReload()}
                    ></Button>
                    {isMobile && (
                        <ButtonDropdown
                            items={responsiveButtons.map((b, i) => ({
                                id: `${i}`,
                                text: b.text as string,
                                disabled: b.disabled,
                            }))}
                            disabled={responsiveButtons.findIndex((b) => !b.disabled) < 0}
                            loading={responsiveButtons.findIndex((b) => b.loading) >= 0}
                        >
                            Actions
                        </ButtonDropdown>
                    )}
                    {!isMobile && responsiveButtons.map((b, i) => <Button key={i} {...b}></Button>)}
                </div>
            }
        />
    );
};
