import {
    AssetOutput,
    AssetType,
    InterludeOutput,
    MediaAssetOutput,
    TextAssetOutput,
} from '@amzn/amazon-music-interlude-catalog-manager-client';
import { DateTime } from 'luxon';
import { ServerAsset, ServerInterlude } from '../data-types';

export function formatInterludes(interlude: InterludeOutput): ServerInterlude {
    /**
     * Transforms an interlude object as returned by the Catalog Manager service into the format
     * expected by the Forge Website
     */
    let crtTime: string;
    if (interlude.creationTime) {
        crtTime = DateTime.fromMillis(interlude.creationTime).toLocaleString(DateTime.DATETIME_SHORT);
    } else {
        //no creation time => empty string
        crtTime = '';
    }
    const image_assets = interlude.assets.images?.map((asset) => ({ id: asset })) ?? [];
    const serverInterlude: ServerInterlude = {
        id: interlude.id,
        crt_time: crtTime,
        data_stage: interlude.stage,
        a_type: interlude.assemblyType,
        c_type: interlude.contentType,
        pos: interlude.position,
        rank: interlude.rank,
        lang: interlude.languages,
        mtr_list: interlude.territories,
        tiers: interlude.tiers,
        assets: {
            text: interlude.assets.text ?? [],
            media: interlude.assets.audio ?? [],
            images: image_assets,
        },
        is_explicit: interlude.isExplicit,
        is_edtrl: interlude.isEditorial,
        pri_txt: interlude.primaryText,
        sec_txt: interlude.secondaryText,
        src: interlude.source,
        descr: interlude.description,
    };
    return serverInterlude;
}

export function convertToForgeServerAsset(asset: AssetOutput): ServerAsset {
    /**
     * Transforms the asset output returned by Catalog Manager into the format expected
     * by Forge Website. Filters out Spotlight, NRI, and Artist Commentary Assets
     */
    return asset.type == AssetType.TTS
        ? constructServerAssetFromTextAsset(asset)
        : constructServerAssetFromMediaAsset(asset);
}

const IGNORED_ASSET_CATEGORIES = ['SPOTLIGHT', 'NEW_RELEASE_ANNOUNCEMENT', 'ARTIST_COMMENTARY'];

export function isExperienceAsset(asset: AssetOutput): boolean {
    /**
     * Used to filter out Music Experience related Assets. We prefer showing only DJ Mode
     * related content in the Assets table.
     */
    if (asset.type === AssetType.TTS) {
        return false;
    }
    return IGNORED_ASSET_CATEGORIES.includes((asset as MediaAssetOutput).category ?? '');
}

function constructServerAssetFromMediaAsset(asset: AssetOutput): ServerAsset {
    const mediaAsset = asset as MediaAssetOutput;

    const serverAsset: ServerAsset = {
        id: mediaAsset.id,
        type: mediaAsset.type,
        lang: mediaAsset.languages.toString(),
        mtr: mediaAsset.territories.toString(),
        asin: mediaAsset.artistAsin,
        author: mediaAsset.author,
        auto_values: mediaAsset.autoValues,
        descr: mediaAsset.description,
        origfile: mediaAsset.originalFile,
        data_stage: mediaAsset.stage,

        // Specific to MediaAssetOutput
        category: mediaAsset.category,
    };

    return serverAsset;
}

function constructServerAssetFromTextAsset(asset: AssetOutput): ServerAsset {
    const textAsset = asset as TextAssetOutput;

    const serverAsset: ServerAsset = {
        id: textAsset.id,
        type: textAsset.type,
        lang: textAsset.languages.toString(),
        mtr: textAsset.territories.toString(),
        asin: textAsset.artistAsin,
        author: textAsset.author,
        auto_values: textAsset.autoValues,
        descr: textAsset.description,
        origfile: textAsset.originalFile,
        data_stage: textAsset.stage,

        // Specific to TextAssetOutput
        template: textAsset.template,
    };
    return serverAsset;
}
