import { create, enforce, group, skip, test } from 'vest';

import { PollScheduleForm } from '../components/form/PollScheduleForm';
import { getDateTimeFromStrings } from '../utils/dateTimeUtil';

export interface PollForm {
    schedule?: PollScheduleForm;
}

export const pollValidation = create('pollForm', (poll: PollForm) => {
    if (!poll.schedule) skip.group('schedule');
    group('schedule', () => {
        const schedule = poll.schedule;
        if (!schedule) {
            return;
        }
        test('startDate', 'Must provide start date', () => {
            enforce(schedule.startDate).isNotEmpty();
        });

        test('startTime', 'Must provide start time', () => {
            if (!schedule.startTime) {
                schedule.startTime = '00:00';
            }
        });

        test('endDate', 'Must provide end date', () => {
            enforce(schedule.endDate).isNotEmpty();
        });

        test('endTime', 'Must provide end time', () => {
            if (!schedule.endTime) {
                schedule.endTime = '23:59';
            }
        });

        const startDateTime = getDateTimeFromStrings(schedule.startDate, schedule.startTime);
        const endDateTime = getDateTimeFromStrings(schedule.endDate, schedule.endTime);
        test('startAndEnd', 'Start must come before end', () => {
            if (startDateTime && endDateTime) {
                enforce(startDateTime.toMillis()).lessThan(endDateTime.toMillis());
            }
        });
    });
});
