import { BulkUploadErrorContext } from '../components/UploadResults'

export class BulkUploadError extends Error {
    error_contexts_by_file_name: Map<string, BulkUploadErrorContext[]>

    constructor(message: string, error_contexts_by_file_name: Map<string, BulkUploadErrorContext[]>) {
        super(message);
        this.name = "BulkUploadError";
        this.error_contexts_by_file_name = error_contexts_by_file_name;
    }
}