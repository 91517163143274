import { Poll, PollSchedule } from '../../data-types';

import PollContent from './PollContent';
import PollMetadata from './PollMetadata';
import { PollScheduleForm } from '../form/PollScheduleForm';
import PollScheduling from './PollScheduling';
import React from 'react';
import { Suite } from 'vest';

export interface FanPollDataTabProps {
    poll: Poll;
    isEdit: boolean;
    onChange: (e: CustomEvent) => void;
    onPollChanged: (poll: Poll) => void;
    onPollScheduleChanged: (schedule: PollSchedule) => void;
    scheduleValidator: Suite<(input: { schedule: PollScheduleForm }) => void>;
}

class FanPollDataTab extends React.Component<FanPollDataTabProps> {
    render(): JSX.Element {
        return (
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-xxxs-12 col-xs-6" style={{ width: '50%' }}>
                        <PollMetadata
                            poll={this.props.poll}
                            isEdit={this.props.isEdit}
                            onChange={this.props.onChange}
                        ></PollMetadata>
                    </div>
                    <div className="col-xxxs-12 col-xs-6" style={{ width: '50%' }}>
                        <PollContent
                            poll={this.props.poll}
                            isEdit={this.props.isEdit}
                            onPollChanged={this.props.onPollChanged}
                        ></PollContent>
                    </div>
                    <div className="row-xxxs-12 row-xs-6" style={{ width: '100%' }}>
                        <PollScheduling
                            poll={this.props.poll}
                            onPollScheduleChanged={this.props.onPollScheduleChanged}
                            validator={this.props.scheduleValidator}
                        ></PollScheduling>
                    </div>
                </div>
            </div>
        );
    }
}

export default FanPollDataTab;
