import {
    Checkbox,
    Table,
    TableContentSelector,
    TablePageSizeSelector,
    TableSorting,
} from '@amzn/awsui-components-react';
import { QueueItem } from '../data-types';

export interface QueueTableItem extends QueueItem, Table.Item {
    // Used to join fields from Interlude and Table.Item - no new fields required
}

export const COLUMN_DEFINITIONS: Table.ColumnDefinition<QueueTableItem>[] = [
    {
        id: 'vended',
        header: 'Vended?',
        cell: (item) => <Checkbox checked={item.are_vended ? true : false} disabled={true} />,
        minWidth: '10px',
        allowLineWrap: false,
    },
    {
        id: 'timestamp',
        header: 'Timestamp',
        cell: (item) => (item.timestamp ? new Date(item.timestamp).toUTCString() : ''),
        minWidth: '10px',
        allowLineWrap: false,
    },
    {
        id: 'id',
        header: 'ID',
        cell: (item) => (item.id ? item.id : item.rendered_interlude_id),
        minWidth: '30px',
        allowLineWrap: true,
    },
    {
        id: 'content_type',
        header: 'Content Type',
        cell: (item) => item.content_type,
        minWidth: '30px',
        allowLineWrap: true,
    },
    {
        id: 'position',
        header: 'Position',
        cell: (item) => item.position,
        minWidth: '30px',
        allowLineWrap: true,
    },
    {
        id: 'template_id',
        header: 'Interlude ID',
        cell: (item) => item.template_id,
        minWidth: '30px',
        allowLineWrap: true,
    },
    {
        id: 'asset_id',
        header: 'Asset ID',
        cell: (item) => (item.assets ? item.assets[0].asset_id : ''),
        minWidth: '30px',
        allowLineWrap: true,
    },
];

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<QueueTableItem>[] = [
    { id: 'timestamp', field: 'timestamp' },
    { id: 'id', field: 'id' },
];

export const FILTERING_FIELDS = ['vended', 'content_type', 'position'];

export const CONTENT_SELECTOR_OPTIONS: TableContentSelector.ContentDescriptionGroup[] = [
    {
        label: 'Main Queue properties',
        options: [
            { id: 'timestamp', label: 'Timestamp', editable: true, visible: true },
            { id: 'vended', label: 'Vended?', editable: true, visible: true },
            { id: 'id', label: 'ASIN', editable: true, visible: true },
            { id: 'content_type', label: 'Content Type', editable: true, visible: true },
            { id: 'position', label: 'Position', editable: true, visible: true },
            { id: 'template_id', label: 'Interlude', editable: true, visible: true },
            { id: 'asset_id', label: 'Asset', editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS: TablePageSizeSelector.Option[] = [
    { value: 10, label: '10 items' },
    { value: 15, label: '15 items' },
    { value: 30, label: '30 items' },
    { value: 100, label: '100 items' },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter distributions by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear filters',
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter distributions',
    filteringStatusText: 'Loading',
};
