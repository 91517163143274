import React from 'react';
import {
    MusicExperienceTableItem,
    PAGE_SELECTOR_OPTIONS,
    VOICE_COMMENTS_COLUMN_DEFINITIONS,
    VOICE_COMMENTS_CONTENT_SELECTOR_OPTIONS,
    VOICE_COMMENTS_SORTABLE_COLUMNS,
} from '../configs/experiences-table-config';
import {
    Button,
    ColumnLayout,
    Flashbar,
    Modal,
    Select,
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    TableWrapLines,
} from '@amzn/awsui-components-react';
import { TableNoMatchState } from './commons/common-components';
import { createPropertyStorage } from '../utils/jsonStorage';
import { itemError, itemInfo } from './commons/flash-messages';
import { Props, RouteStateComponent } from './RouteState';
import {
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
    TableOptions,
    VoiceCommentsTableOptions,
} from '../utils/tableUtil';
import { PropsWithDataStage } from './StageContext';
import { Eligibility, ExperienceState, ExperienceType, MusicExperienceSearchItem } from '@amzn/mousai-service-client';
import { VoiceCommentsTableButtons } from './VoiceCommentsTableButtons';
import { experience_state_filter, experience_type_filter, getSelection } from '../configs/multi-select-config';
import _ from 'lodash';
import MousaiClient from '../utils/mousaiUtil';
import { Container } from 'reactstrap';
import { SectionHeader } from './form/Fields';
import SelectDateTime from './form/SelectDateTime';
import {
    convertToDateTimeString,
    DATE_FORMAT,
    getDateTimeFromString,
    getDateTimeFromStrings,
} from '../utils/dateTimeUtil';
import { DateTime } from 'luxon';

const columnStorage = createPropertyStorage<ColumnSetting[]>('Experiences-Table-Settings');
const optionsStorage = createPropertyStorage<TableOptions>('Experiences-Table-Options');

const START_TIME = '00:00'; // Midnight local time
const END_TIME = '00:00'; // Midnight local time
const START_DATE_DELTA = { days: 5 }; // From approval time
const END_DATE_DELTA = { days: 30 }; // From creation time

let cachedItems: MusicExperienceSearchItem[] | undefined;

type State = VoiceCommentsTableOptions;

export default class VoiceCommentsTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: VOICE_COMMENTS_COLUMN_DEFINITIONS,
            contentSelector: VOICE_COMMENTS_CONTENT_SELECTOR_OPTIONS,
            selectedExperiences: [],
            experiences: [],
            loading: true,
            hasData: false,
            working: false,
            flashbar: [],
            sortingDetail: { sortingColumn: VOICE_COMMENTS_COLUMN_DEFINITIONS[0].id, sortingDescending: true },
            typeFilter: ExperienceType.VOICE_COMMENT,
            stateFilter: ExperienceState.DRAFT,
            showScheduleModal: false,
            ...DEFAULT_TABLE_OPTIONS,
        };
    }

    historyState = new RouteStateComponent(this.props, this.state);
    unmounted = false;

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithColumnDefinitionIds(VOICE_COMMENTS_COLUMN_DEFINITIONS, 'width', e.detail.widths, existing),
        );
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithContentSelectionValues(VOICE_COMMENTS_COLUMN_DEFINITIONS, e.detail.contentSelection, existing),
        );
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(VOICE_COMMENTS_COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(VOICE_COMMENTS_CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    typeFilterChanged = (evt: CustomEvent<Select.ChangeDetail>) => {
        const typeFilter = evt.detail.selectedId as ExperienceType;
        this.setState({ typeFilter }, () => this.loadItems(false));
    };

    stateFilterChanged = (evt: CustomEvent<Select.ChangeDetail>) => {
        const stateFilter = evt.detail.selectedId as ExperienceState;
        this.setState({ stateFilter }, () => this.applyFilters());
    };

    applyFilters() {
        if (!this.unmounted) {
            this.setState({
                hasData: true,
                experiences:
                    cachedItems?.filter(
                        (exp) => exp.state === this.state.stateFilter && exp.type === this.state.typeFilter,
                    ) ?? [],
            });
        }
    }

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    componentWillUnmount(): void {
        this.unmounted = true;
        if (this.state.loading) {
            cachedItems = undefined;
        }
    }

    componentDidUpdate(prevProps: PropsWithDataStage): void {
        if (this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadItems();
        }
    }

    loadItems = async (useCache?: boolean) => {
        if (useCache && cachedItems) {
            this.setState({
                loading: false,
            });
            this.applyFilters();
            return;
        }
        this.setState({
            hasData: false,
            loading: true,
        });
        this.props.history.replace({ ...this.props.location });
        try {
            const input = {
                query: {
                    experienceTypes: [this.state.typeFilter],
                },
                includeAssets: true,
                includeStormResources: true,
            };
            await MousaiClient.search(input, (experiences) => {
                cachedItems = experiences;
                this.applyFilters();
            });
        } catch (error) {
            this.setState({
                flashbar: [itemError('Failed to load items', error)],
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    onSelectionChange(evt: CustomEvent<TableSelection.SelectionChangeDetail<MusicExperienceTableItem>>) {
        this.setState({
            selectedExperiences: evt.detail.selectedItems,
        });
    }

    collectFailures = (failures: Record<string, any>): boolean => {
        const errors = _.values(failures);
        if (errors.length == 0) {
            this.setState({
                flashbar: [itemInfo(`The selected experiences were updated`)],
            });
            return true;
        } else {
            this.setState({
                flashbar: [itemError(`Failed to update ${errors.length} experiences`, errors.join('<br/>'))],
            });
            return false;
        }
    };

    updateExperiencesState(ids: string[], state: ExperienceState): Promise<boolean> {
        this.setState({ working: true });
        return MousaiClient.updateState(ids, { state })
            .then(this.collectFailures)
            .finally(() => {
                this.setState({ working: false });
            });
    }

    updateExperiencesEligibility(ids: string[], eligibility: Eligibility): Promise<boolean> {
        this.setState({ working: true });
        return MousaiClient.updateMetadata(ids, { eligibility })
            .then(this.collectFailures)
            .finally(() => {
                this.setState({ working: false });
            });
    }

    onStateTransition = (state: ExperienceState) => {
        if (state === ExperienceState.APPROVED) {
            this.showApprovalModal();
        } else {
            const ids = this.state.selectedExperiences.map((exp) => exp.experienceId);
            this.updateExperiencesState(ids, state).then(() => this.loadItems());
        }
    };

    showApprovalModal() {
        const maxCreationDate =
            _(this.state.selectedExperiences)
                .map((exp) => getDateTimeFromString(exp.createdAt)?.toMillis())
                .filter() // Remove falsy values
                .max() ?? Date.now(); // Default of Date.now() added just for type safety
        const startDate = DateTime.now().plus(START_DATE_DELTA).toFormat(DATE_FORMAT);
        const endDate = DateTime.fromMillis(maxCreationDate).plus(END_DATE_DELTA).toISODate();
        debugger;
        this.setState({ showScheduleModal: true, startDate, startTime: START_TIME, endDate, endTime: END_TIME });
    }

    validateApproval(): boolean {
        const startDateTime = getDateTimeFromStrings(this.state.startDate, this.state.startTime);
        const endDateTime = getDateTimeFromStrings(this.state.endDate, this.state.endTime);
        // TBD: Perform stricter validation of start/end time using vest similar to interlude schedules
        return !!startDateTime && !!endDateTime;
    }

    completeApproval = () => {
        this.setState({ showScheduleModal: false });
        const ids = this.state.selectedExperiences.map((exp) => exp.experienceId);
        const eligibility = {
            isExplicit: false,
            schedule: {
                startDate: convertToDateTimeString(this.state.startDate, this.state.startTime),
                expirationDate: convertToDateTimeString(this.state.endDate, this.state.endTime),
            },
        };
        this.updateExperiencesEligibility(ids, eligibility).then((success) => {
            if (success) {
                this.updateExperiencesState(ids, ExperienceState.APPROVED).then(() => this.loadItems());
            }
        });
    };

    render() {
        const disabled = (this.state.loading && !this.state.hasData) || this.state.working;
        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                {/* 
                The <SelectDateTime> displays the values of start/end that it was originally created with, which are not known
                until user chooses to approve an experience. Use conditional rendering of the modal as a workaround to create 
                new controls with the desired start/end values each time.
                TBD: Update <SelectDateTime> control so it reacts to prop updates.
                TBD: Consider refactoring this Modal into a 'form' component (though likely won't be portable across multiple sections)
                */}
                {this.state.showScheduleModal && (
                    <Modal
                        visible={true}
                        header="Voice Comment approval options"
                        footer={
                            <span className="awsui-util-f-r">
                                <Button variant="link" onClick={() => this.setState({ showScheduleModal: false })}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={!this.validateApproval()}
                                    onClick={this.completeApproval}
                                >
                                    Approve
                                </Button>
                            </span>
                        }
                    >
                        <Container header={<SectionHeader header="Eligible Dates" />}>
                            <h2>Start Date and Time</h2>
                            <ColumnLayout columns={2}>
                                <SelectDateTime
                                    enabled={true}
                                    date={this.state.startDate ?? ''}
                                    time={this.state.startTime ?? ''}
                                    dateError=""
                                    timeError=""
                                    onChange={(startDate, startTime) => this.setState({ startDate, startTime })}
                                />
                            </ColumnLayout>
                            <h2 className="awsui-util-mt-m">End Date and Time</h2>
                            <ColumnLayout columns={2}>
                                <SelectDateTime
                                    enabled={true}
                                    date={this.state.endDate ?? ''}
                                    time={this.state.endTime ?? ''}
                                    dateError=""
                                    timeError=""
                                    onChange={(endDate, endTime) => this.setState({ endDate, endTime })}
                                />
                            </ColumnLayout>
                        </Container>
                    </Modal>
                )}
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.experiences}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    header={
                        <VoiceCommentsTableButtons
                            totalItems={this.state.experiences.length}
                            selectedItems={this.state.selectedExperiences}
                            stateFilter={this.state.stateFilter}
                            typeFilter={this.state.typeFilter}
                            loading={this.state.loading || this.state.working}
                            onReload={this.loadItems}
                            onStateTransition={this.onStateTransition}
                        />
                    }
                    loading={disabled}
                    loadingText="Loading..."
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Find..."
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                    <div className="table-filters">
                        <Select
                            options={experience_type_filter}
                            selectedLabel="Selected"
                            selectedOption={getSelection(experience_type_filter, this.state.typeFilter)}
                            onChange={this.typeFilterChanged}
                            className="experience-type-filter"
                        />
                        <Select
                            options={experience_state_filter}
                            selectedLabel="Selected"
                            selectedOption={getSelection(experience_state_filter, this.state.stateFilter)}
                            onChange={this.stateFilterChanged}
                            className="experience-state-filter"
                        />
                    </div>
                    <TablePagination
                        disabled={disabled}
                        pageSize={this.state.pageSize}
                        onPaginationChange={this.paginationChanged}
                    />
                    <TableSorting
                        sortableColumns={VOICE_COMMENTS_SORTABLE_COLUMNS}
                        disabled={disabled}
                        sortingColumn={this.state.sortingDetail.sortingColumn}
                        sortingDescending={this.state.sortingDetail.sortingDescending}
                        onSortingChange={this.sortingChanged}
                    />
                    <TableSelection
                        selectedItems={this.state.selectedExperiences}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={this.state.contentSelector} />
                    </TablePreferences>
                </Table>
            </div>
        );
    }
}
