import React from 'react';
import { Multiselect } from '@amzn/awsui-components-react';
import {
    available_language_options,
    available_market_options,
    service_tier_options,
    getMultipleSelection,
} from '../../configs/multi-select-config';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

class Eligibility extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveValue = 'col-xxxs-12 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Playback Eligibility</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Service Tiers:</h4>
                        <Multiselect
                            className={responsiveValue}
                            options={service_tier_options}
                            placeholder="Choose options"
                            checkboxes={true}
                            selectedOptions={getMultipleSelection(service_tier_options, this.props.interlude.tiers)}
                            selectedLabel="Selected"
                            id="tiers"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Available Languages:</h4>
                        <Multiselect
                            className={responsiveValue}
                            options={available_language_options}
                            placeholder="Choose options"
                            checkboxes={true}
                            selectedOptions={getMultipleSelection(
                                available_language_options,
                                this.props.interlude.lang,
                            )}
                            selectedLabel="Selected"
                            id="lang"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Available Markets:</h4>
                        <Multiselect
                            className={responsiveValue}
                            options={available_market_options}
                            placeholder="Choose options"
                            checkboxes={true}
                            selectedOptions={getMultipleSelection(
                                available_market_options,
                                this.props.interlude.mtr_list,
                            )}
                            selectedLabel="Selected"
                            id="mtr_list"
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Eligibility;
