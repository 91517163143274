import React, { ReactElement } from 'react';

export interface TableNoMatchProps {
    title?: string;
    subtitle?: string;
}

export const TableNoMatchState: React.FC<TableNoMatchProps> = (props: TableNoMatchProps) => (
    <div className="awsui-util-t-c awsui-util-mv-s">
        <b>{props.title || 'No matches'}</b>
        <p className="awsui-util-mb-xs">{props.subtitle || 'No matches found.'}</p>
    </div>
);

export interface TableHeaderProps {
    totalItems: number;
    selectedItems?: any[];
    actionButtons: ReactElement;
    title: string;
    description?: string;
}

export const TableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
    let counter = `(${props.totalItems})`;
    if (props.selectedItems && props.selectedItems.length > 0) {
        counter = `(${props.selectedItems.length}/${props.totalItems})`;
    }
    return (
        <div>
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                    <h2>
                        {`${props.title} `}
                        {counter ? (
                            <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                                {`${counter}`}
                            </span>
                        ) : null}
                    </h2>
                </div>
                {props.actionButtons && <div className="awsui-util-action-stripe-group">{props.actionButtons}</div>}
            </div>
            {props.description && <div className="awsui-util-container-header-description">{props.description}</div>}
        </div>
    );
};
