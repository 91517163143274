import React from 'react';
import { TableHeader } from './commons/common-components';
import { Button, ButtonDropdown } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router';

export interface Props {
    totalItems: number;
    selectedItems: any[];
    loading: boolean;
    exporting: boolean;
    deleting: boolean;
    onReload: () => void;
    showDeleteModal: () => void;
    exportAssets: () => void;
}

export const AssetTableButtons: React.FC<Props> = (props: Props) => {
    const emptySelection = props.selectedItems.length === 0;
    const isOnlyOneSelected = props.selectedItems.length === 1;
    const disabled = props.loading || props.exporting || props.deleting;
    const history = useHistory();

    // Handler for Item Edit
    const onItemsEdit = () => {
        history.push(`/assets/${props.selectedItems[0].id}`);
    };

    const responsiveButtons: Button.Props[] = [
        {
            text: 'Edit',
            disabled: disabled || !isOnlyOneSelected,
            onClick: onItemsEdit,
        },
        {
            text: 'Delete',
            disabled: disabled || emptySelection,
            loading: props.deleting,
            onClick: props.showDeleteModal,
        },
        {
            text: 'Export as CSV',
            disabled: disabled || emptySelection,
            loading: props.exporting,
            onClick: props.exportAssets,
        },
    ];

    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    return (
        <TableHeader
            {...props}
            title="Assets"
            actionButtons={
                <div className="awsui-util-action-stripe-group">
                    <Button
                        icon="refresh"
                        disabled={disabled}
                        loading={props.loading}
                        onClick={() => props.onReload()}
                    ></Button>
                    {isMobile && (
                        <ButtonDropdown
                            items={responsiveButtons.map((b, i) => ({
                                id: `${i}`,
                                text: b.text as string,
                                disabled: b.disabled,
                            }))}
                            disabled={responsiveButtons.findIndex((b) => !b.disabled) < 0}
                            loading={responsiveButtons.findIndex((b) => b.loading) >= 0}
                        >
                            Actions
                        </ButtonDropdown>
                    )}
                    {!isMobile && responsiveButtons.map((b, i) => <Button key={i} {...b}></Button>)}
                </div>
            }
        />
    );
};
