import { Table, TableSorting } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { StagedResource } from '../data-types';

export interface StagedResourceTableItem extends StagedResource, Table.Item {
    // Used to join fields from Interlude and Table.Item - no new fields required
}

function getResourceLink(resource: StagedResource) {
    switch (resource.type) {
        case 'INTERLUDE':
            return <Link to={`/interludes/${resource.id}`}>{resource.id}</Link>;
        case 'ASSET':
            return <Link to={`/assets/${resource.id}`}>{resource.id}</Link>;
        default:
            return resource.id;
    }
}

export const COLUMN_DEFINITIONS: Table.ColumnDefinition<StagedResourceTableItem>[] = [
    {
        id: 'id',
        header: () => 'Resource ID',
        cell: (item) => <div>{getResourceLink(item)}</div>,
        minWidth: '80px',
        allowLineWrap: true,
    },
    {
        id: 'type',
        cell: (item) => item.type,
        header: () => 'Resource Type',
        minWidth: '80px',
        allowLineWrap: true,
    },
    {
        id: 'c_type',
        cell: (item) => item.c_type,
        header: () => 'Content Type',
        minWidth: '80px',
        allowLineWrap: true,
    },
    {
        id: 'descr',
        cell: (item) => item.descr,
        header: () => 'Description',
        minWidth: '80px',
        allowLineWrap: true,
    },
    {
        id: 'src',
        cell: (item) => item.src,
        header: () => 'Content Source',
        minWidth: '80px',
        allowLineWrap: true,
    },
    {
        id: 'a_type',
        cell: (item) => item.a_type,
        header: () => 'Asset Type',
        minWidth: '80px',
        allowLineWrap: true,
    },
    {
        id: 'author',
        cell: (item) => item.author,
        header: () => 'Speaker/Subject',
        minWidth: '80px',
        allowLineWrap: true,
    },
];

const idRegex = /([a-z]+)-([0-9]+)/i;

function sortId(item1?: StagedResourceTableItem, item2?: StagedResourceTableItem): number {
    if (!item1?.id || !item2?.id) return 0;
    if (!item1?.id) return -1;
    if (!item2?.id) return -1;

    const m1 = item1.id.match(idRegex);
    const m2 = item2.id.match(idRegex);

    // "IL-1234" => ["IL-1234", "IL", "1234"]

    if (!m1 || !m2 || m1[1] !== m2[1]) return item1.id === item2.id ? 0 : item1.id < item2.id ? -1 : 1;

    return parseInt(m1[2]) - parseInt(m2[2]);
}

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<StagedResourceTableItem>[] = [
    { id: 'id', comparator: sortId },
    { id: 'type', field: 'type' },
    { id: 'c_type', field: 'c_type' },
    { id: 'descr', field: 'descr' },
    { id: 'src', field: 'src' },
    { id: 'a_type', field: 'a_type' },
    { id: 'author', field: 'author' },
];

export const FILTERING_FIELDS = ['type', 'c_type', 'src', 'a_type', 'author'];

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Staged Resource Properties',
        options: [
            { id: 'id', label: 'Resource ID', editable: false, visible: true },
            { id: 'type', label: 'Resource Type', editable: true, visible: true },
        ],
    },
    {
        label: 'Sequencing',
        options: [
            { id: 'c_type', label: 'Content Type', editable: true, visible: true },
            { id: 'src', label: 'Content Source', editable: true, visible: true },
        ],
    },
    {
        label: 'Main Properties',
        options: [
            { id: 'descr', label: 'Description', editable: true, visible: true },
            { id: 'a_type', label: 'Assembly Type', editable: true, visible: true },
            { id: 'author', label: 'Speaker', editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 items' },
    { value: 15, label: '15 items' },
    { value: 30, label: '30 items' },
    { value: 100, label: '100 items' },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter interludes by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear filters',
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter distributions',
    filteringStatusText: 'Loading',
};
