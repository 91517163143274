import { FormField, Input, Toggle } from '@amzn/awsui-components-react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import { Rotate as RotateOptions } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';

export interface RotateProps {
    options: RotateOptions;
    errors?: StringMap;
    onChange: (changed: RotateOptions) => void;
}

function Rotate(props: RotateProps): JSX.Element {
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail | Toggle.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <div className="awsui-grid awsui-util-ml-xl">
            <div className="awsui-row">
                <FormField className="col-3" label="Degrees:" errorText={errors['degrees']} stretch={true}>
                    <Input id="degrees" value={props.options.degrees.toString()} onChange={handleChange}></Input>
                </FormField>
                <FormField className="col-3" label="Resize:" errorText={errors['resize']} stretch={true}>
                    <Toggle id="resize" checked={props.options.resize} onChange={handleChange}>
                        {props.options.resize ? 'Enabled' : 'Disabled'}
                    </Toggle>
                </FormField>
            </div>
        </div>
    );
}

export default Rotate;
