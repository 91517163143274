import React, { useEffect, useState } from 'react';
import { PinnedInterludes, PinnedSequence, PreSelectedInterlude } from '../../utils/qcar/qcarTransformer';
import PageHeader, { PageHeaderButton } from '../PageHeader';
import { Button, Input, Table, Select, TableProps, FormField } from '@amzn/awsui-components-react-v3';
import {
    addNoSelectionOption,
    content_type,
    position_list,
    toPolarisV3SelectOption,
} from '../../configs/multi-select-config';

type PinnedInterludesProps = {
    pinnedInterludes: PinnedInterludes;
    setIsValid: (isValid: boolean) => void;
    onEdit: (pinnedInterludes: PinnedInterludes) => void;
};

const EditPinnedInterludes: React.FC<PinnedInterludesProps> = ({
    pinnedInterludes,
    setIsValid,
    onEdit,
}: PinnedInterludesProps) => {
    const [selectedPinnedSeqences, setSelectedPinnedSeqences] = useState([] as PinnedSequence[]);
    const [selectedPreSelectedInterludes, setSelectedPreSelectedInterludes] = useState([] as PreSelectedInterlude[]);

    const locationErrorMessage = 'Must have one or more location';
    const interludesErrorMessage = 'Must have one or more interludes';
    const contentTypeOrPositionErrorMessage = 'Must have a Content Type or Position';

    useEffect(() => {
        setIsValid(allPinnedSequencesAreValid() && allPreSelectedInterludesAreValid());
    });

    const addPinnedSequencedRow = () => {
        const existingPinnedSequences = pinnedInterludes.pinnedSequences ?? [];
        pinnedInterludes.pinnedSequences = [
            ...existingPinnedSequences,
            {
                contentType: undefined,
                position: undefined,
                location: [],
            } as PinnedSequence,
        ];
        onEdit(pinnedInterludes);
    };

    const addPreSelectedInterludesRow = () => {
        const existingPreSelectedInterludes = pinnedInterludes.preSelectedInterludes ?? [];
        pinnedInterludes.preSelectedInterludes = [
            ...existingPreSelectedInterludes,
            {
                interludes: [],
                location: [],
            } as PreSelectedInterlude,
        ];
        onEdit(pinnedInterludes);
    };

    const deleteSelectedPinnedSequences = () => {
        if (pinnedInterludes?.pinnedSequences) {
            for (const sequencedInterludeToDelete of selectedPinnedSeqences) {
                const indexToDelete = pinnedInterludes.pinnedSequences.indexOf(sequencedInterludeToDelete);
                pinnedInterludes.pinnedSequences.splice(indexToDelete, 1);
            }
            onEdit(pinnedInterludes);
            setSelectedPinnedSeqences([]);
        }
    };

    const deleteSelectedPreSelectedInterludes = () => {
        if (pinnedInterludes?.preSelectedInterludes) {
            for (const preSelectedInterludeToDelete of selectedPreSelectedInterludes) {
                const indexToDelete = pinnedInterludes.preSelectedInterludes.indexOf(preSelectedInterludeToDelete);
                pinnedInterludes.preSelectedInterludes.splice(indexToDelete, 1);
            }
            onEdit(pinnedInterludes);
            setSelectedPreSelectedInterludes([]);
        }
    };

    const allPinnedSequencesAreValid = () => {
        return pinnedInterludes.pinnedSequences ? pinnedInterludes.pinnedSequences.every(pinnedSequenceIsValid) : true;
    };

    const pinnedSequenceIsValid = (item: PinnedSequence) => {
        return contentTypeOrPositionIsSelected(item) && locationHasOneOrMoreItems(item);
    };

    const contentTypeOrPositionIsSelected = (item: PinnedSequence) => {
        return item.contentType !== undefined || item.position !== undefined;
    };

    const allPreSelectedInterludesAreValid = () => {
        return pinnedInterludes.preSelectedInterludes
            ? pinnedInterludes.preSelectedInterludes.every(preSelectedInterludeIsValid)
            : true;
    };

    const preSelectedInterludeIsValid = (item: PreSelectedInterlude) => {
        return locationHasOneOrMoreItems(item) && interludesHasOneOrMoreItems(item);
    };

    const interludesHasOneOrMoreItems = (item: PreSelectedInterlude) => {
        return item.interludes && item.interludes.length > 0;
    };

    const locationHasOneOrMoreItems = (item: { location: number[] }) => {
        return item.location && item.location.length > 0;
    };

    const removeEmptyStringsFromCommaDelimetedList = (
        currentValue: string,
        commaDelimetedList: string,
        array: string[] | number[],
    ) => {
        const modifyingExistingValue = array.length >= commaDelimetedList.split(',').length;
        if (modifyingExistingValue) {
            return currentValue.length > 1 || currentValue.trim() !== '';
        }
        return true;
    };

    const pinnedSequencesHeaderButtons: PageHeaderButton[] = [];
    pinnedSequencesHeaderButtons.push({
        text: 'Add',
        variant: 'primary',
        loading: false,
        onClick: () => addPinnedSequencedRow(),
    });
    pinnedSequencesHeaderButtons.push({
        text: 'Delete',
        variant: 'primary',
        loading: false,
        disabled: selectedPinnedSeqences.length === 0,
        onClick: deleteSelectedPinnedSequences,
    });

    const preSelectedInterludesHeaderButtons: PageHeaderButton[] = [];
    preSelectedInterludesHeaderButtons.push({
        text: 'Add',
        variant: 'primary',
        loading: false,
        onClick: () => addPreSelectedInterludesRow(),
    });
    preSelectedInterludesHeaderButtons.push({
        text: 'Delete',
        variant: 'primary',
        loading: false,
        disabled: selectedPreSelectedInterludes.length === 0,
        onClick: deleteSelectedPreSelectedInterludes,
    });

    const pinnedSequencesColumnDefinitions: TableProps.ColumnDefinition<PinnedSequence>[] = [
        {
            id: 'content_type',
            header: 'Content Type',
            cell: (item) => (
                <FormField errorText={!contentTypeOrPositionIsSelected(item) && contentTypeOrPositionErrorMessage}>
                    <Select
                        options={addNoSelectionOption(content_type.map(toPolarisV3SelectOption))}
                        selectedOption={
                            content_type
                                .map(toPolarisV3SelectOption)
                                .find((contentType) => item.contentType === contentType.value) ?? null
                        }
                        onChange={(e) => {
                            const index = pinnedInterludes.pinnedSequences.indexOf(item);
                            pinnedInterludes.pinnedSequences[index].contentType = e.detail.selectedOption.value;
                            onEdit(pinnedInterludes);
                        }}
                        expandToViewport
                        filteringType="manual"
                    />
                </FormField>
            ),
            minWidth: '180px',
        },
        {
            id: 'position',
            header: 'Position',
            cell: (item) => (
                <FormField errorText={!contentTypeOrPositionIsSelected(item) && contentTypeOrPositionErrorMessage}>
                    <Select
                        options={addNoSelectionOption(position_list.map(toPolarisV3SelectOption))}
                        selectedOption={
                            position_list
                                .map(toPolarisV3SelectOption)
                                .find((position) => item.position === position.value) ?? null
                        }
                        onChange={(e) => {
                            const index = pinnedInterludes.pinnedSequences.indexOf(item);
                            pinnedInterludes.pinnedSequences[index].position = e.detail.selectedOption.value;
                            onEdit(pinnedInterludes);
                        }}
                        expandToViewport
                        filteringType="manual"
                    />
                </FormField>
            ),
            minWidth: '180px',
        },
        {
            id: 'location',
            header: 'Location*',
            cell: (item) => (
                <FormField errorText={!locationHasOneOrMoreItems(item) && locationErrorMessage}>
                    <Input
                        value={item.location?.join(', ')}
                        onChange={(e) => {
                            const index = pinnedInterludes.pinnedSequences.indexOf(item);
                            pinnedInterludes.pinnedSequences[index].location = e.detail.value
                                .split(',')
                                .filter((stringToCheck) =>
                                    removeEmptyStringsFromCommaDelimetedList(
                                        stringToCheck,
                                        e.detail.value,
                                        item.location,
                                    ),
                                )
                                .map((interludeValueString) => Number(interludeValueString.replace(/\D/g, '')));
                            onEdit(pinnedInterludes);
                        }}
                        placeholder="2, 4, 7"
                    />
                </FormField>
            ),
            minWidth: '180px',
        },
    ];

    const preSelectedInterludesColumnDefinitions: TableProps.ColumnDefinition<PreSelectedInterlude>[] = [
        {
            id: 'interludes',
            header: 'Interludes*',
            cell: (item) => (
                <FormField errorText={!interludesHasOneOrMoreItems(item) && interludesErrorMessage}>
                    <Input
                        value={item.interludes?.join(', ')}
                        onChange={(e) => {
                            const index = pinnedInterludes.preSelectedInterludes.indexOf(item);
                            pinnedInterludes.preSelectedInterludes[index].interludes = e.detail.value
                                .split(',')
                                .filter((stringToCheck) =>
                                    removeEmptyStringsFromCommaDelimetedList(
                                        stringToCheck,
                                        e.detail.value,
                                        item.interludes,
                                    ),
                                )
                                .map((interludeValueString) => interludeValueString.trim());
                            onEdit(pinnedInterludes);
                        }}
                        placeholder="IL-5, IL-30"
                    />
                </FormField>
            ),
            minWidth: '180px',
        },
        {
            id: 'location',
            header: 'Location*',
            cell: (item) => (
                <FormField errorText={!locationHasOneOrMoreItems(item) && locationErrorMessage}>
                    <Input
                        value={item.location?.join(', ')}
                        onChange={(e) => {
                            const index = pinnedInterludes.preSelectedInterludes.indexOf(item);
                            pinnedInterludes.preSelectedInterludes[index].location = e.detail.value
                                .split(',')
                                .filter((stringToCheck) =>
                                    removeEmptyStringsFromCommaDelimetedList(
                                        stringToCheck,
                                        e.detail.value,
                                        item.location,
                                    ),
                                )
                                .map((interludeValueString) => Number(interludeValueString.replace(/\D/g, '')));
                            onEdit(pinnedInterludes);
                        }}
                        placeholder="2, 4, 7"
                    />
                </FormField>
            ),
            minWidth: '180px',
        },
    ];

    return (
        <div>
            <div className="awsui-grid">
                <PageHeader text="Pinned Sequences" buttons={pinnedSequencesHeaderButtons} />
                <Table
                    items={pinnedInterludes.pinnedSequences ?? []}
                    columnDefinitions={pinnedSequencesColumnDefinitions}
                    empty={
                        <div>
                            <p>This variation has no Pinned Sequences</p>
                            <Button onClick={addPinnedSequencedRow}>Add a Pinned Sequence</Button>
                        </div>
                    }
                    stickyHeader
                    resizableColumns
                    loadingText="Loading PinnedSequences"
                    selectionType="multi"
                    selectedItems={selectedPinnedSeqences}
                    onSelectionChange={(e) => setSelectedPinnedSeqences(e.detail.selectedItems)}
                />
            </div>
            <div className="awsui-grid">
                <PageHeader text="Pre-Selected Interludes" buttons={preSelectedInterludesHeaderButtons} />
                <Table
                    items={pinnedInterludes.preSelectedInterludes ?? []}
                    empty={
                        <div>
                            <p>This variation has no pre-selected interludes</p>
                            <Button onClick={addPreSelectedInterludesRow}>Add a Pre-Selected Interlude</Button>
                        </div>
                    }
                    columnDefinitions={preSelectedInterludesColumnDefinitions}
                    stickyHeader
                    resizableColumns
                    loadingText="Loading Pre-Selected Interludes"
                    selectionType="multi"
                    selectedItems={selectedPreSelectedInterludes}
                    onSelectionChange={(e) => setSelectedPreSelectedInterludes(e.detail.selectedItems)}
                />
            </div>
        </div>
    );
};

export default EditPinnedInterludes;
