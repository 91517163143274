import { AppLayout } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';
import Navigation from './components/Navigation';
import AppContent from './AppContent';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { HelpContext } from './components/help/HelpContext';
import { DataStage, StageContext } from './components/StageContext';

interface State extends HelpContext, StageContext {
    tools: React.ReactNode;
    toolsHide: boolean;
    toolsOpen: boolean;
}

class App extends React.Component<RouteComponentProps, State> {
    // Last app location that modified the state of the tools panel
    lastHelpLocation?: string;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            setHelpContent: this.setHelpContent,
            dataStage: DataStage.Sandbox,
            setDataStage: this.setDataStage,
            tools: '',
            toolsHide: true,
            toolsOpen: false,
        };
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.onRouteChange();
        }
    }

    onRouteChange() {
        // This check is in case the help panel has been displayed before we were notified of the route change
        if (this.props.location.pathname !== this.lastHelpLocation) {
            delete this.lastHelpLocation;
            this.setState({ tools: <div />, toolsOpen: false, toolsHide: true });
        }
    }

    setDataStage = (dataStage: DataStage): void => {
        this.setState({
            dataStage: dataStage,
        });
    };

    setHelpContent = (content: React.ReactNode, isPageDefault: boolean, openPanel: boolean): void => {
        if (isPageDefault) {
            // It's only possible to set the default page help content once
            if (this.lastHelpLocation === this.props.location.pathname) {
                // The error stack trace will help figure out which component tried adding a second <PageHelp> component
                throw new Error(
                    'Attempting to set the default page help content more than once.\
                     You should only include a <PageHelp> component once within a given page route.',
                );
            }
            this.lastHelpLocation = this.props.location.pathname;
            // Default content will not show automatically in mobile screens (which AWSUI defines as < 768px wide)
            const isMobile = window.matchMedia('(max-width: 768px)').matches;
            openPanel = openPanel && !isMobile;
        }
        this.setState({ tools: content, toolsHide: false, toolsOpen: openPanel });
    };

    render(): ReactNode {
        const isTopSection = this.props.location.pathname.split('/').length <= 2;
        const layoutProps = isTopSection ? { className: 'awsui-util-no-gutters' } : { breadcrumbs: <Breadcrumbs /> };
        return (
            <HelpContext.Provider value={this.state}>
                <StageContext.Provider value={this.state}>
                    <div id="b">
                        <Header />
                        <AppLayout
                            navigation={<Navigation />}
                            navigationWidth={200}
                            content={<AppContent />}
                            toolsHide={this.state.toolsHide}
                            toolsOpen={this.state.toolsOpen}
                            tools={this.state.tools}
                            {...layoutProps}
                        />
                    </div>
                </StageContext.Provider>
            </HelpContext.Provider>
        );
    }
}

const AppWithRouter = withRouter(App);

export default AppWithRouter;
