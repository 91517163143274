import { FormField, Input, Toggle } from '@amzn/awsui-components-react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import { Brightness as BrightnessOptions } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';

export interface BrightnessProps {
    options: BrightnessOptions;
    errors?: StringMap;
    onChange: (changed: BrightnessOptions) => void;
}

function Brightness(props: BrightnessProps): JSX.Element {
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail | Toggle.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <div className="awsui-grid awsui-util-ml-xl">
            <div className="awsui-row">
                <FormField
                    className="col-3"
                    label="Brightness level:"
                    description="-100 to 100%"
                    errorText={errors['brightness']}
                    stretch={true}
                >
                    <Input id="brightness" value={props.options.brightness.toString()} onChange={handleChange}></Input>
                </FormField>
            </div>
        </div>
    );
}

export default Brightness;
