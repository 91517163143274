import React from 'react';
import { TableFiltering } from '@amzn/awsui-components-react';
import { PropsWithDataStage } from './StageContext';
import { State } from './InterludesTable';
import { RouteComponentProps, StaticContext } from 'react-router';

interface RouteState {
    filteringText: string;
}

export type RouteProps = RouteComponentProps<Record<string, string>, StaticContext, RouteState>;
export type Props = RouteProps & PropsWithDataStage;

export class RouteStateComponent extends React.Component<Props, State> {
    setRouteState(routeState: Partial<RouteState>) {
        this.props.history.replace({
            ...this.props.location,
            state: {
                ...this.props.location.state,
                ...routeState,
            },
        });
    }

    filteringChanged = (e: CustomEvent<TableFiltering.FilteringChangeDetail>) => {
        this.setState({
            filteringText: e.detail.filteringText,
        });
        this.setRouteState({
            filteringText: e.detail.filteringText,
        });
    };
}
