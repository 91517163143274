import React, { useEffect, useState } from 'react';
import { MiniSequence } from '../../utils/qcar/qcarTransformer';
import PageHeader, { PageHeaderButton } from '../PageHeader';
import { Button, FormField, Input, Select, Table, TableProps } from '@amzn/awsui-components-react-v3';
import {
    assembly_type,
    content_type,
    position_list,
    addNoSelectionOption,
    toPolarisV3SelectOption,
} from '../../configs/multi-select-config';

type SequencedInterludesProps = {
    sequencedInterludes: MiniSequence[];
    setIsValid: (isValid: boolean) => void;
    onEdit: (sequencedInterludes: MiniSequence[]) => void;
};

const EditSequencedInterludes: React.FC<SequencedInterludesProps> = ({
    sequencedInterludes,
    setIsValid,
    onEdit,
}: SequencedInterludesProps) => {
    const [selectedSequencedInterludes, setSelectedSequencedInterludes] = useState([] as MiniSequence[]);

    const rankErrorMessage = 'Rank is required and must be betweeen 0 and 1';
    const contentTypeOrPositionOrAssemblyErrorMessage = 'Must have a Content Type, Position, or Assembly Type';

    useEffect(() => {
        setIsValid(allSequencedInterludesAreValid());
    });

    const addRow = () => {
        if (sequencedInterludes) {
            onEdit([...sequencedInterludes, {}]);
        }
    };

    const deleteSelectedSequencedInterludes = () => {
        if (sequencedInterludes) {
            for (const sequencedInterludeToDelete of selectedSequencedInterludes) {
                const indexToDelete = sequencedInterludes.indexOf(sequencedInterludeToDelete);
                if (indexToDelete > -1) {
                    sequencedInterludes.splice(indexToDelete, 1);
                }
            }
            onEdit(sequencedInterludes);
            setSelectedSequencedInterludes([]);
        }
    };

    const allSequencedInterludesAreValid = () => {
        return sequencedInterludes ? sequencedInterludes.every(sequencedInterludeIsValid) : true;
    };

    const sequencedInterludeIsValid = (item: MiniSequence) => {
        return rankIsValid(item) && contentTypeOrPositionOrAssemblyIsSelected(item);
    };

    const rankIsValid = (item: MiniSequence) => {
        return item.rank !== undefined && !isNaN(Number(item.rank)) && item.rank >= 0 && item.rank < 1;
    };

    const contentTypeOrPositionOrAssemblyIsSelected = (item: MiniSequence) => {
        return item.contentType !== undefined || item.position !== undefined || item.assemblyType !== undefined;
    };

    const headerButtons: PageHeaderButton[] = [];
    headerButtons.push({
        text: 'Add',
        variant: 'primary',
        loading: false,
        onClick: addRow,
    });
    headerButtons.push({
        text: 'Delete',
        variant: 'primary',
        loading: false,
        disabled: selectedSequencedInterludes.length === 0,
        onClick: deleteSelectedSequencedInterludes,
    });

    const sequencedInterludesColumnDefinitions: TableProps.ColumnDefinition<MiniSequence>[] = [
        {
            id: 'rank',
            header: 'Rank*',
            cell: (item) => (
                <div>
                    <FormField errorText={!rankIsValid(item) && rankErrorMessage}>
                        <Input
                            value={item.rank?.toString() ?? ''}
                            type="number"
                            step={0.1}
                            onChange={(e) => {
                                const index = sequencedInterludes.indexOf(item);
                                if (e.detail.value.trim() === '') {
                                    sequencedInterludes[index].rank = undefined;
                                } else {
                                    sequencedInterludes[index].rank = Number(e.detail.value);
                                }
                                onEdit(sequencedInterludes);
                            }}
                        />
                    </FormField>
                </div>
            ),
            minWidth: '180px',
        },
        {
            id: 'contenttype',
            cell: (item) => (
                <div>
                    <FormField
                        errorText={
                            !contentTypeOrPositionOrAssemblyIsSelected(item) &&
                            contentTypeOrPositionOrAssemblyErrorMessage
                        }
                    >
                        <Select
                            options={addNoSelectionOption(content_type.map(toPolarisV3SelectOption))}
                            selectedOption={
                                content_type
                                    .map(toPolarisV3SelectOption)
                                    .find((type) => type.value === item.contentType) ?? null
                            }
                            onChange={(e) => {
                                const index = sequencedInterludes.indexOf(item);
                                sequencedInterludes[index].contentType = e.detail.selectedOption.value;
                                onEdit(sequencedInterludes);
                            }}
                            expandToViewport
                            filteringType="manual"
                            invalid={!contentTypeOrPositionOrAssemblyIsSelected(item)}
                        />
                    </FormField>
                </div>
            ),
            header: 'Content Type',
            minWidth: '160px',
            width: 250,
        },
        {
            id: 'position',
            cell: (item) => (
                <div>
                    <FormField
                        errorText={
                            !contentTypeOrPositionOrAssemblyIsSelected(item) &&
                            contentTypeOrPositionOrAssemblyErrorMessage
                        }
                    >
                        <Select
                            options={addNoSelectionOption(position_list.map(toPolarisV3SelectOption))}
                            selectedOption={
                                position_list
                                    .map(toPolarisV3SelectOption)
                                    .find((position) => position.value === item.position) ?? null
                            }
                            onChange={(e) => {
                                const index = sequencedInterludes.indexOf(item);
                                sequencedInterludes[index].position = e.detail.selectedOption.value;
                                onEdit(sequencedInterludes);
                            }}
                            expandToViewport
                            filteringType="manual"
                            invalid={!contentTypeOrPositionOrAssemblyIsSelected(item)}
                        />
                    </FormField>
                </div>
            ),
            header: 'Position',
            minWidth: '160px',
        },
        {
            id: 'assembly_type',
            cell: (item) => (
                <div>
                    <FormField
                        errorText={
                            !contentTypeOrPositionOrAssemblyIsSelected(item) &&
                            contentTypeOrPositionOrAssemblyErrorMessage
                        }
                    >
                        <Select
                            options={addNoSelectionOption(assembly_type.map(toPolarisV3SelectOption))}
                            selectedOption={
                                assembly_type
                                    .map(toPolarisV3SelectOption)
                                    .find((assemblyType) => assemblyType.value === item.assemblyType) ?? null
                            }
                            onChange={(e) => {
                                const index = sequencedInterludes.indexOf(item);
                                sequencedInterludes[index].assemblyType = e.detail.selectedOption.value;
                                onEdit(sequencedInterludes);
                                setIsValid(allSequencedInterludesAreValid());
                            }}
                            expandToViewport
                            filteringType="manual"
                            invalid={!contentTypeOrPositionOrAssemblyIsSelected(item)}
                        />
                    </FormField>
                </div>
            ),
            header: 'Assembly Type',
            minWidth: '160px',
        },
        {
            id: 'relativehistory',
            cell: (item) => (
                <div>
                    <Input
                        type="number"
                        value={item.relativeHistory ? item.relativeHistory.toString() : ''}
                        onChange={(e) => {
                            const index = sequencedInterludes.indexOf(item);
                            sequencedInterludes[index].relativeHistory = Number(e.detail.value);
                            onEdit(sequencedInterludes);
                        }}
                    />
                </div>
            ),
            header: 'Relative History',
            minWidth: '80px',
        },
        {
            id: 'customerhistory',
            cell: (item) => (
                <div>
                    <Input
                        type="number"
                        value={item.customerHistory !== undefined ? item.customerHistory.toString() : ''}
                        onChange={(e) => {
                            const index = sequencedInterludes.indexOf(item);
                            sequencedInterludes[index].customerHistory = Number(e.detail.value);
                            onEdit(sequencedInterludes);
                        }}
                    />
                </div>
            ),
            header: 'Customer History',
            minWidth: '80px',
        },
    ];

    return (
        <div className="awsui-grid">
            <PageHeader buttons={headerButtons} />
            <Table
                items={sequencedInterludes}
                empty={
                    <div>
                        <p>There is nothing to show</p>
                        <Button onClick={addRow}>Add a Sequenced Interlude</Button>
                    </div>
                }
                columnDefinitions={sequencedInterludesColumnDefinitions}
                loadingText="Loading Sequenced Interludes"
                selectionType="multi"
                selectedItems={selectedSequencedInterludes}
                onSelectionChange={(e) => {
                    setSelectedSequencedInterludes(e.detail.selectedItems);
                }}
            />
        </div>
    );
};

export default EditSequencedInterludes;
