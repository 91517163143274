export interface BulkUploadErrorContext {
    bulk_upload_row_number: number;
    exception_type: string;
    exception_msg: string;
}

export interface BaseUploadResponse {
    load_counts?: Record<string, any>;
    error_contexts_by_file_name?: Map<string, BulkUploadErrorContext[]>;
}

export interface InterludesUploadResponse extends BaseUploadResponse {
    load_counts?: {
        interlude_definition_create_count: number;
        interlude_definition_create_range_start: string;
        interlude_definition_create_range_end: string;
        interlude_definition_update_count: number;
        entity_associations_create_count: number;
        entity_associations_update_count: number;
    };
    error_contexts_by_file_name?: Map<string, BulkUploadErrorContext[]>;
}

export function renderInterludeResults(response?: InterludesUploadResponse): JSX.Element {
    const load_counts = response?.load_counts;
    return load_counts ? (
        <div className="awsui-util-spacing-v-s">
            <div>
                <div className="awsui-util-label">Interludes created</div>
                <div>{load_counts.interlude_definition_create_count}</div>
            </div>
            {load_counts.interlude_definition_create_count > 0 && (
                <div>
                    <div className="awsui-util-label">New interludes</div>
                    <div>
                        {load_counts.interlude_definition_create_count > 1
                            ? `${load_counts.interlude_definition_create_range_start} to ${load_counts.interlude_definition_create_range_end}`
                            : load_counts.interlude_definition_create_range_start}
                    </div>
                </div>
            )}
            <div>
                <div className="awsui-util-label">Interludes updated</div>
                <div>{load_counts.interlude_definition_update_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Entity associations created</div>
                <div>{load_counts.entity_associations_create_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Entity associations updated</div>
                <div>{load_counts.entity_associations_update_count}</div>
            </div>
        </div>
    ) : (
        <div />
    );
}

export interface AssetUploadResponse extends BaseUploadResponse {
    load_counts?: {
        text_asset_create_count: number;
        text_asset_create_range_start: string;
        text_asset_create_range_end: string;
        text_asset_update_count: number;
        media_asset_create_count: number;
        media_asset_create_range_start: string;
        media_asset_create_range_end: string;
        media_asset_update_count: number;
    };
}

export function renderAssetResults(response?: AssetUploadResponse): JSX.Element {
    const load_counts = response?.load_counts;
    return load_counts ? (
        <div className="awsui-util-spacing-v-s">
            <div>
                <div className="awsui-util-label">Text assets created</div>
                <div>{load_counts.text_asset_create_count}</div>
            </div>
            {load_counts.text_asset_create_count > 0 && (
                <div>
                    <div className="awsui-util-label">New text assets</div>
                    <div>
                        {load_counts.text_asset_create_count > 1
                            ? `${load_counts.text_asset_create_range_start} to ${load_counts.text_asset_create_range_end}`
                            : load_counts.text_asset_create_range_start}
                    </div>
                </div>
            )}
            <div>
                <div className="awsui-util-label">Text assets updated</div>
                <div>{load_counts.text_asset_update_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Media assets created</div>
                <div>{load_counts.media_asset_create_count}</div>
            </div>
            {load_counts.media_asset_create_count > 0 && (
                <div>
                    <div className="awsui-util-label">New media assets</div>
                    <div>
                        {load_counts.media_asset_create_count > 1
                            ? `${load_counts.media_asset_create_range_start} to ${load_counts.media_asset_create_range_end}`
                            : load_counts.media_asset_create_range_start}
                    </div>
                </div>
            )}
            <div>
                <div className="awsui-util-label">Media assets updated</div>
                <div>{load_counts.media_asset_update_count}</div>
            </div>
        </div>
    ) : (
        <div></div>
    );
}

export interface PollUploadResponse extends BaseUploadResponse {
    load_counts?: {
        poll_definition_create_count: number;
        poll_definition_update_count: number;
    };
}

export function renderPollResults(response?: PollUploadResponse): JSX.Element {
    const load_counts = response?.load_counts;
    return load_counts ? (
        <div className="awsui-util-spacing-v-s">
            <div>
                <div className="awsui-util-label">Polls created</div>
                <div>{load_counts.poll_definition_create_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Polls updated</div>
                <div>{load_counts.poll_definition_update_count}</div>
            </div>
        </div>
    ) : (
        <div></div>
    );
}

function renderFileErrors(file_name: string, error_context_list: BulkUploadErrorContext[]): JSX.Element {
    return (
        <div>
            <div className="awsui-util-label">{file_name}</div>
            {error_context_list.map((error_context: BulkUploadErrorContext) => (
                <div key={error_context.bulk_upload_row_number}>
                    Row {error_context.bulk_upload_row_number}: {error_context.exception_msg}
                </div>
            ))}
        </div>
    );
}

function renderUploadErrors(file_name_to_errors_map: Map<string, BulkUploadErrorContext[]>) {
    const renderedErrorBox: any = [];
    Object.entries(file_name_to_errors_map).forEach((entry) => {
        const [file_name, error_context_list] = entry;
        const rederedFileErrors = renderFileErrors(file_name, error_context_list);
        renderedErrorBox.push(rederedFileErrors);
    });
    return <div className="awsui-util-spacing-v-s">{renderedErrorBox}</div>;
}

function renderDefaultErrorMessage() {
    return (
        <div>
            <div className="awsui-util-label">One or more errors found while processing the CSV file</div>
        </div>
    );
}

export function renderUploadErrorsFromResponse(response?: BaseUploadResponse): JSX.Element {
    if (response && response.error_contexts_by_file_name) {
        return renderUploadErrors(response.error_contexts_by_file_name);
    } else {
        return renderDefaultErrorMessage();
    }
}
