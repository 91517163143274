import { DATE_FORMAT, TIME_FORMAT, convertToDateTimeString, getDateTimeFromString } from '../../utils/dateTimeUtil';

import { PollSchedule } from '../../data-types';
import _ from 'lodash';

export const NONE_DAYS = 'NONE';

export interface PollScheduleForm {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
}

export const modelToForm = (schedule?: PollSchedule): PollScheduleForm => {
    const startDateTime = getDateTimeFromString(schedule?.startTime);
    const startDate = startDateTime?.toFormat(DATE_FORMAT) ?? '';
    const startTime = startDateTime?.toFormat(TIME_FORMAT) ?? '';
    const endDateTime = getDateTimeFromString(schedule?.endTime);
    const endDate = endDateTime?.toFormat(DATE_FORMAT) ?? '';
    const endTime = endDateTime?.toFormat(TIME_FORMAT) ?? '';
    return {
        startDate,
        startTime,
        endDate,
        endTime,
    };
};

export const formToModel = (schedule: PollScheduleForm): PollSchedule => {
    const startDateTime = convertToDateTimeString(schedule.startDate, schedule.startTime);
    const endDateTime = convertToDateTimeString(schedule.endDate, schedule.endTime);

    //API schema enforces that the dateTime must be valid if enabled populated, cannot send {enabled: false, dateTime: (''|undefined)}
    const startTime = startDateTime ? startDateTime : undefined;
    const endTime = endDateTime ? endDateTime : undefined;
    return { startTime, endTime };
};
