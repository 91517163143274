import { useState } from 'react';
import { Button, Form, FormSection } from '@amzn/awsui-components-react';
import { deepCopy } from '../../utils/jsonUtil';
import AudioMediaFooter from '../media/AudioMediaFooter';
import Media from '../media/Media';
import Data from './Data';
import { Asset } from '../../data-types';
import { FlashbarItem, itemError, itemSuccess } from '../commons/flash-messages';
import { AssetDestination, uploadMedia } from '../../utils/assetUtil';
import { fetchCreateAsset } from '../../utils/fetchUtil';

export interface Props {
    dataStage: string;
    dataRef: any;
    assetMetadata: Asset;
    hasBatch?: boolean;
    handleDataChange: (e: CustomEvent) => void;
    handleAuthorUpdate: (author: string) => void;
    setAsset: (asset: Asset) => void;
    addToFlashbar: (msg: FlashbarItem) => void;
    resetFlashbar: () => void;
    addToHistory: (path: string) => void;
}

const CreateAudioAssetsForm = (props: Props): JSX.Element => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [saving, setSaving] = useState(false);
    const responsiveColumn = 'col-xxxs-12 col-xs-6';
    const responsiveLabel = 'col-xxxs-12 col-xxs-3';

    const handleFileChange = (files: File[]): void => {
        setLoadingFiles(true);
        if (!files || files.length == 0) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(files);
        }
        setLoadingFiles(false);
    };

    const createAssets = async (): Promise<void> => {
        setSaving(true);
        props.resetFlashbar();
        for (const file of selectedFiles) {
            const asset = deepCopy(props.assetMetadata);
            asset.origfile = file.name;
            try {
                const { filename, extension } = await uploadMedia(AssetDestination.FORGE, file);
                asset.fileName = filename;
                asset.extension = extension;
                props.addToFlashbar(itemSuccess(`"${file.name}" uploaded successfully`));
            } catch (e) {
                props.addToFlashbar(itemError(`Failed to upload media for "${file.name}"`, e));
                continue;
            }
            try {
                const id = await fetchCreateAsset(asset, props.dataStage);
                props.addToFlashbar(
                    itemSuccess(`Asset ${id} created successfully from "${file.name}"`).withButton('View asset', () => {
                        props.addToHistory(`/assets/${id}`);
                    }),
                );
                props.dataRef.current?.onAssetSaved();
            } catch (e) {
                props.addToFlashbar(itemError(`Failed to update asset metadata for "${file.name}"`, e));
            }
        }
        props.setAsset(deepCopy({ type: props.assetMetadata.type }));
        setSaving(false);
    };

    const mediaSection = (): JSX.Element => {
        const mediaFooter = (
            <AudioMediaFooter
                loadingFile={loadingFiles}
                onFileChange={handleFileChange}
                multiple={true}
                canUploadFolder={true}
            />
        );
        const mediaComponents = selectedFiles?.map((file, idx) => (
            <div key={idx} className="awsui-row">
                <h5 className={responsiveLabel}>{file.name}:</h5>
                <Media selectedFile={file} presignedUrl={props.assetMetadata.type} type={props.assetMetadata.type} />
            </div>
        ));
        return (
            <FormSection className={responsiveColumn} header="Media" footer={mediaFooter}>
                {!loadingFiles && mediaComponents}
            </FormSection>
        );
    };

    const form: JSX.Element = (
        <div className="awsui-grid">
            <div className="awsui-row">
                <FormSection className={responsiveColumn} header="Information">
                    <Data
                        ref={props.dataRef}
                        asset={props.assetMetadata}
                        isEdit={false}
                        readonly={false}
                        displayFileName={false}
                        hasBatch={props.hasBatch}
                        onChange={props.handleDataChange}
                        onAuthorUpdate={props.handleAuthorUpdate}
                    />
                </FormSection>
                {mediaSection()}
            </div>
        </div>
    );

    const createButton: JSX.Element = (
        <Button
            variant="primary"
            disabled={!selectedFiles || selectedFiles.length < 1}
            onClick={createAssets}
            loading={saving}
        >
            Create
        </Button>
    );

    return (
        <Form header="Create assets" actions={createButton}>
            {form}
        </Form>
    );
};

export default CreateAudioAssetsForm;
