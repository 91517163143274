import { PresignedUrl } from '../data-types';
import { fetchPresignedPostUrlForReports, fetchPresignedGetUrlForReports } from './fetchUtil';
import { prepareFormData } from './jsonUtil';

/**
 * Uploads a file to the reports input bucket and returns the presigned URL for sharing the file
 */
export function uploadFile(file: File): Promise<string> {
    let uploadedFileName: string;
    return fetchPresignedPostUrlForReports(file.name)
        .then((result: PresignedUrl) => {
            uploadedFileName = result.file_name;
            const formData = prepareFormData(result);
            formData.append('file', file as Blob);
            return fetch(result.url, { method: 'POST', body: formData });
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to upload asset file: ' + response.statusText);
            }
            return fetchPresignedGetUrlForReports(uploadedFileName).then((result: string) => result);
        });
}
