import { Button } from '@amzn/awsui-components-react';
import React from 'react';
import UnsupportedFileModal from '../media/UnsupportedFileModal';
import { UploadButton } from '../media/UploadButton';

export interface Props {
    albumImageFile?: File;
    loadingFile: boolean;
    onFileChange: (files: File[], shouldUseAlbumImage: boolean) => void;
}

export interface State {
    modalVisible: boolean;
    unsupportedFileNames: string[];
}

const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
const acceptType = validFileTypes.join(',');

export default class ArtistCommentaryImageMediaFooter extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            modalVisible: false,
            unsupportedFileNames: [],
        };
    }

    onFileChange = (files: File[]) => {
        if (files.length < 1) {
            return;
        }
        const file = files[0];
        if (!validFileTypes.includes(file.type)) {
            this.setState({
                modalVisible: true,
                unsupportedFileNames: [file.name],
            });
            return;
        }
        this.props.onFileChange([file], false);
    };

    useDownloadedAlbumImage = () => {
        if (!!this.props.albumImageFile) {
            this.props.onFileChange([this.props.albumImageFile], true);
        }
    };

    render = (): JSX.Element => {
        return (
            <React.Fragment>
                <UnsupportedFileModal
                    visible={this.state.modalVisible}
                    fileNames={this.state.unsupportedFileNames}
                    validFormats={validFileTypes}
                    onExit={() => this.setState({ modalVisible: false })}
                />
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <div className="col-12">
                            <div className="awsui-util-f-l">
                                <UploadButton
                                    variant="link"
                                    icon="file-open"
                                    accept={acceptType}
                                    multiple={false}
                                    onFileChange={this.onFileChange}
                                    loading={this.props.loadingFile}
                                    disabled={this.props.loadingFile}
                                >
                                    Choose file
                                </UploadButton>
                                <Button
                                    variant="link"
                                    icon="copy"
                                    loading={false}
                                    disabled={!this.props.albumImageFile}
                                    onClick={this.useDownloadedAlbumImage}
                                >
                                    Use Album Artwork
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}
