import React from 'react';
import {
    StagedResourceTableItem,
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    COLUMN_DEFINITIONS,
    SORTABLE_COLUMNS,
} from '../configs/changes-table-config';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    TableWrapLines,
    Modal,
    Button,
    Flashbar,
} from '@amzn/awsui-components-react';
import { TableNoMatchState } from './commons/common-components';
import { createPropertyStorage } from '../utils/jsonStorage';
import { fetchGetAllChanges, fetchPromoteChanges, fetchRevertChanges } from '../utils/fetchUtil';
import { FlashbarItem, itemError } from './commons/flash-messages';
import { RouteStateComponent, Props } from './RouteState';
import {
    addToColumnDefinitions,
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    TableOptions,
} from '../utils/tableUtil';
import { StagedResource } from '../data-types';
import { ChangesTableButtons } from './ChangesTableHeader';
import { DataStage } from './StageContext';
import { stagedChangesHelp } from './help/HelpContent';
import PageHelp from './help/PageHelp';

const widthsStorage = createPropertyStorage<ColumnSetting[]>('Changes-Table-Widths');
const optionsStorage = createPropertyStorage<TableOptions>('Changes-Table-Options');

let cachedItems: StagedResource[];

interface State extends TableOptions {
    columnDefinitions: Table.ColumnDefinition[];
    selectedResources: StagedResourceTableItem[];
    resources: StagedResourceTableItem[];
    showRevertModal: boolean;
    showPromoteModal: boolean;
    loading: boolean;
    changing: boolean;
    flashbar: FlashbarItem[];
    filteringText?: string;
}

export class ChangesTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: COLUMN_DEFINITIONS,
            selectedResources: [],
            resources: [],
            showRevertModal: false,
            showPromoteModal: false,
            loading: true,
            changing: false,
            flashbar: [],
            ...DEFAULT_TABLE_OPTIONS,
        };
    }
    historyState = new RouteStateComponent(this.props, this.state);

    saveWidths = (e: CustomEvent<Table.ColumnWidthsChangeDetail>): void => {
        widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
    };

    loadSettings = async () => {
        const columns = (await widthsStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns);
        this.setState({ columnDefinitions, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    loadItems = (useCache?: boolean) => {
        if (useCache && cachedItems) {
            this.setState({
                resources: cachedItems,
                loading: false,
            });
            return;
        }
        this.setState({
            loading: true,
        });
        this.props.history.replace({ ...this.props.location });
        fetchGetAllChanges(DataStage.Sandbox)
            .then((json) => {
                cachedItems = json.resources;
                this.setState({
                    loading: false,
                    resources: json.resources,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    flashbar: [itemError('Failed to load staged resources', error)],
                });
            });
    };

    onSelectionChange(evt: CustomEvent<TableSelection.SelectionChangeDetail<StagedResourceTableItem>>) {
        this.setState({
            selectedResources: evt.detail.selectedItems,
        });
    }

    openRevertModal = () => {
        this.setState({
            showRevertModal: true,
        });
    };

    openPromoteModal = () => {
        this.setState({
            showPromoteModal: true,
        });
    };

    onItemsRevert = () => {
        this.setState({ changing: true });
        fetchRevertChanges(DataStage.Sandbox, this.state.selectedResources)
            .then(() => this.loadItems())
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to delete one or more staged resources', error)] });
            })
            .finally(() => this.setState({ changing: false }));
    };

    onItemsPromote = () => {
        this.setState({ changing: true });
        fetchPromoteChanges(DataStage.Sandbox, this.state.selectedResources)
            .then(() => this.loadItems())
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to promote one or more staged resources', error)] });
            })
            .finally(() => this.setState({ changing: false }));
    };

    render() {
        const disabled = this.state.loading || this.state.changing;
        return (
            <div>
                <Modal
                    visible={this.state.showRevertModal}
                    header="Revert staged changes?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showRevertModal: false })}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.onItemsRevert();
                                    this.setState({ showRevertModal: false });
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will permantently delete the selected changes from the sandbox stage.
                </Modal>
                <Modal
                    visible={this.state.showPromoteModal}
                    header="Promote staged changes?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showPromoteModal: false })}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.onItemsPromote();
                                    this.setState({ showPromoteModal: false });
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will make the selected changes in the sandbox available in LIVE to all prod customers.
                </Modal>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <PageHelp content={stagedChangesHelp()} openPanel={true}></PageHelp>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.resources}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveWidths}
                    onWrapLinesChange={this.wrapLinesChanged}
                    header={
                        <ChangesTableButtons
                            showRevertModal={this.openRevertModal}
                            showPromoteModal={this.openPromoteModal}
                            totalItems={this.state.resources.length}
                            selectedItems={this.state.selectedResources}
                            loading={this.state.loading}
                            onReload={this.loadItems}
                            changing={this.state.changing}
                        />
                    }
                    loading={this.state.loading}
                    loadingText="Loading staged resources"
                    noMatch={<TableNoMatchState />}
                    empty={
                        <TableNoMatchState title="No changes" subtitle="There are no staged changes in the sandbox" />
                    }
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Find Changes"
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                    <TablePagination
                        disabled={disabled}
                        pageSize={this.state.pageSize}
                        onPaginationChange={this.paginationChanged}
                    />
                    <TableSorting sortableColumns={SORTABLE_COLUMNS} disabled={disabled} />
                    <TableSelection
                        selectedItems={this.state.selectedResources}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={CONTENT_SELECTOR_OPTIONS} />
                    </TablePreferences>
                </Table>
            </div>
        );
    }
}

export default ChangesTable;
