import stage from '../configs/stage';

const AUTH_CONFIGS = {
    beta: {
        AppWebDomain: 'beta-forge.auth.us-east-1.amazoncognito.com', // without https://
        ClientId: '24ia2egq7u1udj8snlvj98q4ih', // the client ID from Cognito "General settings > App clients" page
        RedirectUriSignIn: 'https://beta.forge.music.amazon.dev/', // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
        RedirectUriSignOut: 'https://beta.forge.music.amazon.dev/', // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
        TokenScopesArray: ['openid'],
        UserPoolId: 'us-east-1_gBrIMr2TL', // the user pool from Cognito "General settings" page
        Region: 'us-east-1',
    },

    gamma: {
        AppWebDomain: 'gamma-forge.auth.us-east-1.amazoncognito.com',
        ClientId: '664b0d5tj863948lnassukfl4e',
        RedirectUriSignIn: 'https://gamma.forge.music.amazon.dev/',
        RedirectUriSignOut: 'https://gamma.forge.music.amazon.dev/',
        TokenScopesArray: ['openid'],
        UserPoolId: 'us-east-1_UmY3ooGkc',
        Region: 'us-east-1',
    },

    prod: {
        AppWebDomain: 'prod-forge.auth.us-east-1.amazoncognito.com',
        ClientId: '1cema65qg6afpmfknjtm2dqafr',
        RedirectUriSignIn: 'https://forge.music.amazon.dev/',
        RedirectUriSignOut: 'https://forge.music.amazon.dev/',
        TokenScopesArray: ['openid'],
        UserPoolId: 'us-east-1_HcGGJWty0',
        Region: 'us-east-1',
    },

};
export function getAuthConfig() {
    if (process.env.REACT_APP_FORGE_COGNITO_DOMAIN) {
        return {
            AppWebDomain: process.env.REACT_APP_FORGE_COGNITO_DOMAIN,
            ClientId: process.env.REACT_APP_FORGE_COGNITO_CLIENT_ID,
            RedirectUriSignIn: process.env.REACT_APP_FORGE_COGNITO_REDIRECT_URL,
            RedirectUriSignOut: process.env.REACT_APP_FORGE_COGNITO_REDIRECT_URL,
            TokenScopesArray: ['openid'],
            UserPoolId: process.env.REACT_APP_FORGE_COGNITO_POOL_ID,
            Region: process.env.REACT_APP_FORGE_COGNITO_DOMAIN ?? 'us-east-1',
        }
    }
    return AUTH_CONFIGS[stage()];
}
