import React from 'react';
import { FlashbarItem, itemError, itemInfo, itemLoading } from '../commons/flash-messages';
import { Button, Flashbar, FormSection, Input } from '@amzn/awsui-components-react';
import Media from '../media/Media';
import { applyChange } from '../../utils/eventUtil';
import { isValidTextValue } from '../../utils/commentary/validation';
import { fetchGetAlbumMetadata } from '../../utils/fetchUtil';
import { findLargestImage } from '../../utils/imageUtil';
import Jimp from 'jimp';
import formatAlbumImage from '../../utils/commentary/commentaryImages';
import { ArtistCommentaryPreset, Preset } from '@amzn/forge-image-processing-types';
import { CommentaryAsins } from './EditArtistCommentary';
import _ from 'lodash';

export interface Props {
    asins: CommentaryAsins;
    isReadonly: boolean;
    onAsinChanged: (asins: CommentaryAsins, areValidAsins: boolean) => void;
    setAlbumImageFile: (file: File) => void;
}

export interface State {
    albumImageButtonDisabled: boolean;
    albumImageFile?: File;
    albumImageHeader: string;
    imageFormat: Preset;
    flashbar: FlashbarItem[];
    isAlbumImageLoading: boolean;
    isValidAlbumAsin: boolean;
}

export class ArtistCommentaryAsinForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            albumImageButtonDisabled: true,
            albumImageFile: undefined,
            albumImageHeader: '',
            flashbar: [itemInfo('Must provide both Artist and Album ASINs')],
            imageFormat: ArtistCommentaryPreset,
            isAlbumImageLoading: false,
            isValidAlbumAsin: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        // When experiences from Mousai are loaded, update this component with the ASIN data
        if (!_.isEqual(prevProps.asins, this.props.asins)) {
            this.configureAsinFields();
            this.setState({ albumImageFile: undefined });
        }
    }

    configureAsinFields = (e?: CustomEvent): void => {
        if (e !== undefined) {
            applyChange(e, this.props.asins);
        }
        const isValidAlbumAsin = isValidTextValue(this.props.asins?.album);
        const isValidArtistAsin = isValidTextValue(this.props.asins?.artist);
        const areValidAsins = isValidAlbumAsin && isValidArtistAsin;
        this.setState({
            albumImageButtonDisabled: !isValidAlbumAsin,
            flashbar: areValidAsins ? [] : [itemInfo('Must provide both Artist and Album ASINs').isDismissible(false)],
            isValidAlbumAsin: isValidAlbumAsin,
        });
        this.props.onAsinChanged(this.props.asins, areValidAsins);
    };

    // MARK: Add album image
    useAlbumImage = async (): Promise<void> => {
        this.setState({
            albumImageFile: undefined,
            flashbar: [itemLoading('Fetching the album image...')],
            isAlbumImageLoading: true,
            isValidAlbumAsin: true,
        });

        try {
            const metadata = await fetchGetAlbumMetadata(this.props.asins.album);
            const source = findLargestImage(metadata);
            if (!source) {
                this.setState({
                    flashbar: [itemError(`Failed to load image for album with ASIN: ${this.props.asins.album}`)],
                    isValidAlbumAsin: false,
                });
                return;
            }
            const image = await Jimp.read(source.url);
            const formattedFile = await formatAlbumImage(image, this.state.imageFormat, this.props.asins.album);
            this.setState({
                albumImageHeader: `Album image for "${metadata.title}"`,
                albumImageFile: formattedFile,
                flashbar: [],
            });
            this.props.setAlbumImageFile(formattedFile);
        } catch (error) {
            this.setState({
                flashbar: [itemError(`Failed to load image for album with ASIN: ${this.props.asins.album}`)],
                isValidAlbumAsin: false,
            });
        } finally {
            this.setState({ isAlbumImageLoading: false });
        }
    };

    render(): JSX.Element {
        const responsiveColumn = 'col-xxxs-12 col-xxs-6';
        return (
            <div className="awsui-row">
                <div className="col-xxxs-12 col-xxs-8">
                    <FormSection header="ASINs">
                        <Flashbar items={this.state.flashbar}></Flashbar>
                        <div style={{ paddingTop: '10px' }} />
                        <div className="awsui-grid">
                            <div className="awsui-row">
                                <div className="col-xxxs-12 col-xxs-6">
                                    <div className="awsui-row">
                                        <h4 className={responsiveColumn}>Artist ASIN:</h4>
                                        <Input
                                            className={responsiveColumn}
                                            type="text"
                                            id="artist"
                                            onChange={this.configureAsinFields}
                                            value={this.props.asins.artist}
                                            disabled={this.props.isReadonly}
                                        />
                                    </div>
                                    <div className="awsui-row">
                                        <h4 className={responsiveColumn}>Album ASIN:</h4>
                                        <Input
                                            className={responsiveColumn}
                                            type="text"
                                            id="album"
                                            onChange={this.configureAsinFields}
                                            value={this.props.asins.album}
                                            disabled={this.props.isReadonly}
                                        />
                                    </div>
                                    <div className="awsui-row">
                                        <div className="awsui-util-action-stripe-group awsui-util-pv-n">
                                            <Button
                                                disabled={this.state.albumImageButtonDisabled}
                                                loading={this.state.isAlbumImageLoading}
                                                onClick={this.useAlbumImage}
                                                variant="primary"
                                            >
                                                Fetch Album Artwork
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxxs-12 col-xxs-6">
                                    {!!this.state.albumImageFile && (
                                        <FormSection header={this.state.albumImageHeader}>
                                            <Media selectedFile={this.state.albumImageFile} type={'IMAGE'} />
                                        </FormSection>
                                    )}
                                </div>
                            </div>
                        </div>
                    </FormSection>
                </div>
            </div>
        );
    }
}
