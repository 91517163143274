import React from 'react';
import { Interlude } from '../../data-types';
import { PropsWithDataStage } from '../StageContext';
import ViewAsset from './ViewAsset';

export interface EditAssetsTabProps extends PropsWithDataStage {
    current: Interlude;
}

class EditAssetsTab extends React.Component<EditAssetsTabProps> {
    render(): JSX.Element {
        return (
            <div>
                {this.props.current.assets?.text.map((assetId: string) => (
                    <ViewAsset key={assetId} assetId={assetId} dataStage={this.props.dataStage} />
                ))}
                {this.props.current.assets?.media.map((assetId: string) => (
                    <ViewAsset key={assetId} assetId={assetId} dataStage={this.props.dataStage} />
                ))}
                {this.props.current.assets?.images.map((img: any) => (
                    <ViewAsset key={img.id} assetId={img.id} dataStage={this.props.dataStage} />
                ))}
            </div>
        );
    }
}

export default EditAssetsTab;
