import { Grid } from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';
import { DateField, TimeField } from './Fields';

export interface SelectDateTimeProps {
    enabled: boolean;
    date: string;
    time: string;
    dateError?: string;
    timeError?: string;
    onChange: (date: string, time: string) => void;
}

const SelectDateTime: React.FunctionComponent<SelectDateTimeProps> = (props) => {
    const [date, setDate] = useState(props.date);
    const [time, setTime] = useState(props.time);

    return (
        <Grid gridDefinition={[{ colspan: 7 }, { colspan: 4 }]}>
            <DateField
                label="Date"
                value={date}
                onChange={(dt) => {
                    setDate(dt);
                    props.onChange(dt, time);
                }}
                disabled={!props.enabled}
                error={props.dateError}
            />
            <TimeField
                label="Time"
                value={time}
                disabled={!props.enabled}
                onChange={(tm) => {
                    setTime(tm);
                    props.onChange(date, tm);
                }}
                error={props.timeError}
            />
        </Grid>
    );
};

export default SelectDateTime;
