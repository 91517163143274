import React from 'react';

export enum DataStage {
    Sandbox = 'sandbox',
    Live = 'live',
}

export interface StageContext {
    dataStage: string;
    setDataStage: (stage: DataStage) => void;
}

const defaultContext = {
    dataStage: DataStage.Sandbox,
    setDataStage: () => {},
};

export const StageContext = React.createContext<StageContext>(defaultContext);

export interface PropsWithDataStage {
    dataStage: string;
}

/**
 * Wraps a react component (class or function) with a consumer of the StageContext and
 * passes the current dataStage in the props to the component
 * @example
 * // Assuming you have a react component called MyComponent
 * export default withDataStage(MyComponent)
 * @param component Component to wrap
 * @returns Wrapped component
 */
export function withDataStage<T extends PropsWithDataStage>(
    component: React.FunctionComponent<T> | React.ComponentClass<T>,
) {
    return (props: T): React.ReactElement<T> => {
        const { dataStage } = React.useContext(StageContext);
        return React.createElement(component, { ...props, dataStage: dataStage });
    };
}
