import { BulkUploadAssets, BulkUploadInterludes, BulkUploadPolls } from './components/BulkUpload';
import { Redirect, Route, Switch } from 'react-router-dom';

import ArtistCommentariesTable from './components/artist-commentaries/ArtistCommentariesTable';
import AssetsTable from './components/AssetsTable';
import ChangesTable from './components/ChangesTable';
import CreateReport from './components/CreateReport';
import EditArtistCommentary from './components/artist-commentaries/EditArtistCommentary';
import EditCreateAsset from './components/EditCreateAsset';
import EditFanPoll from './components/fan-polls/EditFanPoll';
import EditInterlude from './components/EditInterlude';
import EditQcar from './components/qcar/EditQcar';
import FanPollsTable from './components/fan-polls/FanPollsTable';
import InterludesTable from './components/InterludesTable';
import QcarsTable from './components/qcar/QcarTable';
import QueueTable from './components/QueueTable';
import React from 'react';
import VoiceCommentsTable from './components/VoiceCommentsTable';

class AppContent extends React.Component {
    render(): React.ReactNode {
        return (
            <div>
                <Route exact={true} path="/">
                    <Redirect to="/interludes" />
                </Route>
                <Switch>
                    <Route path="/interludes/create-interlude" component={EditInterlude} />
                    <Route path="/interludes/upload-interludes" component={BulkUploadInterludes} />
                    <Route path="/interludes/:interludeId" component={EditInterlude} />
                    <Route path="/interludes" component={InterludesTable} />
                    <Route path="/qcars/:qcarId" component={EditQcar} />
                    <Route path="/qcars" component={QcarsTable} />
                    <Route path="/assets/create-asset" component={EditCreateAsset} />
                    <Route path="/assets/upload-assets" component={BulkUploadAssets} />
                    <Route path="/assets/:assetId" component={EditCreateAsset} />
                    <Route path="/assets" component={AssetsTable} />
                    <Route exact path="/fan-polls" component={FanPollsTable} />
                    <Route exact path="/fan-polls/create-poll" component={EditFanPoll} />
                    <Route path="/fan-polls/upload-polls" component={BulkUploadPolls} />
                    <Route exact path="/fan-polls/:pollId" component={EditFanPoll} />
                    <Route path="/artist-commentaries/create" component={EditArtistCommentary} />
                    <Route path="/artist-commentaries/:containerAsin" component={EditArtistCommentary} />
                    <Route path="/artist-commentaries" component={ArtistCommentariesTable} />
                    <Route path="/voice-comments" component={VoiceCommentsTable} />
                    <Route path="/queue" component={QueueTable} />
                    <Route path="/changes" component={ChangesTable} />
                    <Route path="/reports/create-report" component={CreateReport} />
                </Switch>
            </div>
        );
    }
}

export default AppContent;
