export function applyChange<T>(change: CustomEvent, data: T): T {
    const target: any = change.target;
    const id: keyof T = target.id;
    let value: any;
    if ('value' in change.detail) {
        value = change.detail.value;
    } else if ('checked' in change.detail) {
        value = change.detail.checked;
    } else if ('selectedIds' in change.detail) {
        value = change.detail.selectedIds;
    } else if ('selectedId' in change.detail) {
        value = change.detail.selectedId;
    }
    data[id] = value;
    return data;
}