import { FormField, Input, Toggle } from '@amzn/awsui-components-react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import { Opacity as OpacityOptions } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';

export interface OpacityProps {
    options: OpacityOptions;
    errors?: StringMap;
    onChange: (changed: OpacityOptions) => void;
}

function Opacity(props: OpacityProps): JSX.Element {
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail | Toggle.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <div className="awsui-grid awsui-util-ml-xl">
            <div className="awsui-row">
                <FormField
                    className="col-3"
                    label="Opacity level:"
                    description="0 to 100%"
                    errorText={errors['opacity']}
                    stretch={true}
                >
                    <Input id="opacity" value={props.options.opacity.toString()} onChange={handleChange}></Input>
                </FormField>
            </div>
        </div>
    );
}

export default Opacity;
