import { Button, Modal } from '@amzn/awsui-components-react';

import React from 'react';

export interface FanPollDataTabProps {
    showDeletePopup: boolean;
    showSavePopup: boolean;
    updateDeletePopup: (shouldDisplayDeletePopup: boolean) => void;
    updateSavePopup: (shouldDisplaySavePopup: boolean) => void;
    deletePoll: () => Promise<void>;
    savePoll: () => Promise<void>;
}

class FanPollDataTab extends React.Component<FanPollDataTabProps> {
    render(): JSX.Element {
        return (
            <div>
                <Modal
                    visible={this.props.showDeletePopup}
                    header="Delete Poll?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.props.updateDeletePopup(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.props.deletePoll();
                                    this.props.updateDeletePopup(false);
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will delete the Fan Poll from live content.
                </Modal>
                <Modal
                    visible={this.props.showSavePopup}
                    header="Save Poll?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.props.updateSavePopup(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.props.savePoll();
                                    this.props.updateSavePopup(false);
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will update the Fan Poll with the current specified information.
                </Modal>
            </div>
        );
    }
}

export default FanPollDataTab;
