import { Flashbar, Select, Spinner } from '@amzn/awsui-components-react';
import PageHeader, { PageHeaderButton } from '../PageHeader';
import { Poll, PollSchedule, ServerPoll } from '../../data-types';
import { available_locale_options, getSelection } from '../../configs/multi-select-config';
import { fetchCreatePoll, fetchDeletePoll, fetchGetPoll, fetchQueryPoll, fetchUpdatePoll } from '../../utils/fetchUtil';
import { itemError, itemSuccess } from '../commons/flash-messages';

import FanPollDataTab from './FanPollData';
import FanPollModal from './FanPollModal';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { applyChange } from '../../utils/eventUtil';
import { deepCopy } from '../../utils/jsonUtil';
import { pollValidation } from '../../validation/pollValidation';

interface State {
    selectedLocale: string;
    polls?: ServerPoll[];
    poll: Poll;
    activeTabId?: string;
    isEdit: boolean;
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    flashbar: Flashbar.MessageDefinition[];
    showDeletePopup: boolean;
    showSavePopup: boolean;
    pollLocales: string[];
}

interface RouteParams {
    pollId: string;
}

type Props = RouteComponentProps<RouteParams>;

function getLocaleOptions(options: Select.Option[], locales?: string[]): Select.Option[] {
    if (!locales) {
        return [];
    }
    const localeOptions: Select.Option[] = [];
    for (const option of options) {
        if (locales?.includes(option.id)) {
            localeOptions.push(option);
        }
    }
    return localeOptions;
}
class EditPoll extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedLocale: '',
            polls: undefined,
            poll: {},
            isEdit: true,
            loading: false,
            saving: false,
            deleting: false,
            flashbar: [],
            showDeletePopup: false,
            showSavePopup: false,
            pollLocales: [],
        };
    }

    componentDidMount(): void {
        const queryParams = new URLSearchParams(window.location.search);
        const locale = queryParams.get('locale') ?? '';
        this.setState({ selectedLocale: locale });
        this.loadPollDetails(locale).catch((error) =>
            this.setState({ flashbar: [itemError('Failed to load poll', error)] }),
        );
        if (this.state.isEdit) {
            this.setState({ flashbar: [] });
            this.loadPollDetails(locale);
        }
    }

    componentDidUpdate(prevProps: Props): void {
        // Update the browser's history with the new URL (without triggering a page reload)
        const currentURL = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentURL.search);
        searchParams.set('locale', this.state.selectedLocale);
        const newURL = `${currentURL.origin}${currentURL.pathname}?${searchParams.toString()}`;
        window.history.replaceState({}, '', newURL);
        if (prevProps.match?.params?.pollId !== this.props.match?.params?.pollId) {
            // User navigated to a different URL that maps to the same component
            // React does not (by design) unload the component, so we need to cleanup the state
            this.setState({ flashbar: [], activeTabId: 'data' });
            this.loadPollDetails(this.state.selectedLocale);
            this.setState({ pollLocales: this.buildPollLocales(this.state.polls) });
        }
    }

    buildPollLocales = (polls?: ServerPoll[]): string[] => {
        return polls ? polls.map((poll) => poll.locale) : [];
    };

    handleLocaleChange = (event: CustomEvent) => {
        this.setState({ selectedLocale: event.detail.selectedOption.id });
        this.loadPollDetails(event.detail.selectedOption.id);
    };

    async loadPollDetails(locale: string): Promise<void> {
        const pollId = this.props.match.params.pollId;
        if (pollId == 'create') {
            this.setState({
                poll: {},
                selectedLocale: '',
                isEdit: false,
                pollLocales: [],
            });
            return;
        }
        this.setState({ isEdit: true, loading: true });
        if (!this.state.polls) {
            this.fetchLocales(pollId);
        }
        await fetchGetPoll(pollId, locale)
            .then((poll) => {
                this.setState({ poll: poll });
            })
            .catch((e) => this.setState({ poll: {}, flashbar: [itemError('Failed to load poll', e)] }))
            .finally(() => this.setState({ loading: false }));
    }

    async fetchLocales(pollId: string): Promise<void> {
        await fetchQueryPoll(pollId)
            .then((polls) => {
                const locales: string[] = polls.map((poll) => poll.locale);
                this.setState({
                    polls: deepCopy(polls),
                    pollLocales: locales,
                });
            })
            .catch((e) =>
                this.setState({
                    poll: {},
                    flashbar: [itemError('Failed to load poll', e)],
                }),
            )
            .finally(() => this.setState({}));
    }

    createPoll = (): Promise<void> => {
        this.setState({ saving: true });
        return fetchCreatePoll(this.state.poll)
            .then((poll) => {
                this.setState({
                    selectedLocale: poll.locale,
                    flashbar: [
                        itemSuccess(`Poll ${poll.id} with locale ${poll.locale} created successfully`).withButton(
                            'View Poll',
                            () => {
                                this.props.history.push(`/fan-polls/${poll.id}?locale=${poll.locale}`);
                                this.setState({
                                    selectedLocale: poll.locale,
                                    isEdit: true,
                                    pollLocales: [...this.state.pollLocales, poll.locale],
                                    flashbar: [],
                                });
                            },
                        ),
                    ],
                });
            })
            .catch((error) => {
                this.setState({
                    flashbar: [itemError('Failed to create poll', error)],
                });
            })
            .finally(() => this.setState({ saving: false }));
    };

    savePoll = (): Promise<void> => {
        this.setState({ saving: true, showSavePopup: false });
        const pollId = this.props.match.params.pollId;
        return fetchUpdatePoll(pollId, this.state.selectedLocale, this.state.poll)
            .then(() => {
                const flashbar = [itemSuccess('Poll saved')];
                this.setState({ flashbar: flashbar });
                this.loadPollDetails(this.state.selectedLocale).catch((e) => {
                    flashbar.push(itemError('Failed to reload poll', e));
                    this.setState({ flashbar: flashbar });
                });
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to save poll', e)] }))
            .finally(() => this.setState({ saving: false }));
    };

    deletePoll = (): Promise<void> => {
        this.setState({ deleting: true, showDeletePopup: false });
        const pollId = this.props.match.params.pollId;
        return fetchDeletePoll(pollId, this.state.selectedLocale)
            .then(() => {
                this.setState({ poll: {} });
                this.props.history.push('/fan-polls/', { reload: true });
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to delete poll', e)] }))
            .finally(() => this.setState({ deleting: false }));
    };

    updatePoll = (poll: Poll): void => {
        this.setState({ poll: deepCopy(poll) });
    };

    handleChange = (e: CustomEvent): void => {
        const poll: Poll = deepCopy(this.state.poll);
        applyChange(e, poll);
        this.setState({ poll: poll });
    };

    updatePollSchedule = (schedule: PollSchedule): void => {
        const updatePollSchedule = {
            start_date: schedule.startTime,
            expiration_date: schedule.endTime,
        };
        const updatePollEligibility = {
            schedule: updatePollSchedule,
        };
        const updatePoll: Poll = {
            ...this.state.poll,
            eligibility: updatePollEligibility,
        };
        this.updatePoll(updatePoll);
    };

    updateDeletePopup = (shouldDisplayDeletePopup: boolean): void => {
        this.setState({
            showDeletePopup: shouldDisplayDeletePopup,
        });
    };

    updateSavePopup = (shouldDisplayDeletePopup: boolean): void => {
        this.setState({
            showSavePopup: shouldDisplayDeletePopup,
        });
    };

    render(): JSX.Element {
        const headerText = this.state.isEdit ? `Edit poll: ${this.state.poll?.id}` : 'Create poll';
        const headerButtons: PageHeaderButton[] = [];
        if (this.state.isEdit) {
            headerButtons.push({
                text: 'Locale',
                icon: 'add-plus',
                disabled: this.state.pollLocales.length === 15,
                loading: this.state.loading,
                onClick: () => this.setState({ isEdit: false, poll: { ...this.state.poll, locale: undefined } }),
            });
            headerButtons.push({
                text: 'Save',
                icon: 'upload',
                disabled: this.state.deleting,
                loading: this.state.saving,
                variant: 'primary',
                onClick: () => this.setState({ showSavePopup: true }),
            });
        } else {
            headerButtons.push({
                text: 'Create',
                loading: this.state.saving,
                variant: 'primary',
                onClick: this.createPoll,
            });
        }
        headerButtons.unshift({
            text: 'Delete',
            disabled: this.state.saving,
            loading: this.state.deleting,
            onClick: () => this.setState({ showDeletePopup: true }),
        });
        const flashbar = this.state.flashbar;
        const responsiveLabel = 'col-xxxs-1 col-xxs-1';
        const responsiveField = 'col-xxxs-1 col-xxs-1';
        const localeSelect = (
            <>
                <h4 className={responsiveLabel}>Locale</h4>
                <Select
                    className={responsiveField}
                    onChange={this.handleLocaleChange}
                    options={getLocaleOptions(available_locale_options, this.state.pollLocales)}
                    selectedLabel="Selected"
                    selectedOption={getSelection(available_locale_options, this.state.selectedLocale)}
                    id="locale"
                ></Select>
            </>
        );
        return (
            <div className="awsui-grid">
                <Flashbar items={flashbar}></Flashbar>
                <FanPollModal
                    showDeletePopup={this.state.showDeletePopup}
                    showSavePopup={this.state.showSavePopup}
                    updateDeletePopup={this.updateDeletePopup}
                    updateSavePopup={this.updateSavePopup}
                    deletePoll={this.deletePoll}
                    savePoll={this.savePoll}
                />
                {this.state.loading ? (
                    <span className="awsui-util-status-inactive">
                        <Spinner /> Loading
                    </span>
                ) : (
                    this.state.poll && (
                        <div>
                            <PageHeader text={headerText} buttons={headerButtons} />
                            {this.state.isEdit ? localeSelect : <></>}
                            <div className="empty-space" style={{ height: '40px' }}></div> {}
                            <FanPollDataTab
                                poll={this.state.poll}
                                isEdit={this.state.isEdit}
                                onChange={this.handleChange}
                                onPollChanged={this.updatePoll}
                                onPollScheduleChanged={this.updatePollSchedule}
                                scheduleValidator={pollValidation}
                            />
                        </div>
                    )
                )}
            </div>
        );
    }
}

export default EditPoll;
