import {
    Flashbar,
    Table,
    TableContentSelector,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TablePropertyFiltering,
    TableSelection,
    TableSorting,
    TableWrapLines,
} from '@amzn/awsui-components-react';
import {
    DEFAULT_FILTERING_TOKENS,
    FAN_POLL_COLUMN_DEFINITIONS,
    FAN_POLL_COLUMN_ID,
    FAN_POLL_CONTENT_SELECTOR_OPTIONS,
    FAN_POLL_SORTABLE_COLUMNS,
    FanPollTableItem,
    PAGE_SELECTOR_OPTIONS,
    PROPERTY_FILTERING_I18N_CONSTANTS,
    PROPERTY_FILTERING_OPTIONS,
} from '../../configs/fan-poll-table-config';
import { CsvExport, fetchDeletePoll, fetchExportPolls, fetchGetPollPage } from '../../utils/fetchUtil';
import {
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    TABLE_PAGE_SIZE,
    TableOptions,
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
} from '../../utils/tableUtil';
import { Props, RouteStateComponent } from '../RouteState';
import { FlashbarItem, itemError } from '../commons/flash-messages';

import React from 'react';
import { CSVLink } from 'react-csv';
import { ServerPoll } from '../../data-types';
import { createPropertyStorage } from '../../utils/jsonStorage';
import { transformPollDataForSearch } from '../../utils/pollsUtil';
import { PropsWithDataStage } from '../StageContext';
import { TableNoMatchState } from '../commons/common-components';
import FanPollModal from './FanPollModal';
import { FanPollsTableHeader } from './FanPollsTableHeader';

const columnStorage = createPropertyStorage<ColumnSetting[]>('Polls-Table-Settings');
const optionsStorage = createPropertyStorage<TableOptions>('Polls-Table-Options');

let cachedItems: FanPollTableItem[] | undefined;

export interface State extends TableOptions {
    columnDefinitions: Table.ColumnDefinition[];
    contentSelector: TableContentSelector.ContentDescriptionGroup[];
    showDeletePopup: boolean;
    selectedFanPolls: FanPollTableItem[];
    fanPolls: FanPollTableItem[];
    loading: boolean;
    firstPageDone: boolean;
    deleting: boolean;
    flashbar: FlashbarItem[];
    filteringText?: string;
    filteringTokens?: TablePropertyFiltering.FilteringToken[];
    filteringOptions?: TablePropertyFiltering.Option[];
    sortingDetail: TableSorting.SortingChangeDetail;
    dataToDownload: CsvExport;
    exporting: boolean;
}

const initialState: State = {
    columnDefinitions: FAN_POLL_COLUMN_DEFINITIONS,
    contentSelector: FAN_POLL_CONTENT_SELECTOR_OPTIONS,
    selectedFanPolls: [],
    fanPolls: [],
    loading: true,
    firstPageDone: false,
    showDeletePopup: false,
    flashbar: [],
    filteringTokens: DEFAULT_FILTERING_TOKENS,
    filteringOptions: PROPERTY_FILTERING_OPTIONS,
    deleting: false,
    sortingDetail: {
        sortingColumn: FAN_POLL_COLUMN_ID.last_updated,
        sortingDescending: true,
    },
    dataToDownload: [],
    exporting: false,
    ...DEFAULT_TABLE_OPTIONS,
};

export default class FanPollsTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    historyState = new RouteStateComponent(this.props, this.state);
    unloaded = false;
    csvLink = React.createRef<any>();

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(mapWithColumnDefinitionIds(FAN_POLL_COLUMN_DEFINITIONS, 'width', e.detail.widths, existing));
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithContentSelectionValues(FAN_POLL_COLUMN_DEFINITIONS, e.detail.contentSelection, existing),
        );
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(FAN_POLL_COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(FAN_POLL_CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    componentWillUnmount(): void {
        this.unloaded = true;
        if (this.state.loading) {
            cachedItems = undefined;
        }
    }

    componentDidUpdate(prevProps: PropsWithDataStage): void {
        if (this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadItems();
        }
    }

    loadItems = async (useCache?: boolean) => {
        if (useCache && cachedItems) {
            this.setState({
                fanPolls: cachedItems,
                loading: false,
                firstPageDone: true,
            });
            return;
        }
        this.setState({
            loading: true,
            firstPageDone: false,
        });
        this.props.history.replace({ ...this.props.location });

        try {
            const polls = await this.retrievePollsWithPagination();
            this.setState({
                firstPageDone: true,
                fanPolls: polls,
                loading: false,
            });
            // Only store the results in the global cache if the user did not navigate away while loading
            if (!this.unloaded) {
                cachedItems = polls;
            }
        } catch (error) {
            this.setState({
                loading: false,
                flashbar: [itemError('Failed to load Polls', error)],
            });
        }
    };

    // Enable paginated retrieval of poll content
    retrievePollsWithPagination = async (): Promise<ServerPoll[]> => {
        let polls: ServerPoll[] = [];
        let nextToken: string | undefined;
        do {
            const result = await fetchGetPollPage(nextToken);
            const pollsPage = result.polls;
            pollsPage.forEach((poll) => transformPollDataForSearch(poll));
            polls = polls.concat(...pollsPage);
            if (this.state.pageSize <= TABLE_PAGE_SIZE) {
                this.setState({
                    firstPageDone: true,
                    fanPolls: polls,
                });
            }
            nextToken = result.nextToken;
        } while (nextToken && !this.unloaded);
        return polls;
    };

    onSelectionChange(evt: CustomEvent<TableSelection.SelectionChangeDetail<FanPollTableItem>>) {
        this.setState({
            selectedFanPolls: evt.detail.selectedItems,
        });
    }

    onPropertyFilteringChange = (e: CustomEvent<TablePropertyFiltering.ChangeDetail>) => {
        this.setState({ filteringTokens: e.detail.tokens });
    };

    openDeleteModal = () => {
        this.setState({
            showDeletePopup: true,
        });
    };

    updateDeletePopup = (shouldDisplayDeletePopup: boolean): void => {
        this.setState({
            showDeletePopup: shouldDisplayDeletePopup,
        });
    };

    onItemsDelete = (): Promise<void> => {
        this.setState({ deleting: true });
        const deletePromises = this.state.selectedFanPolls.map((poll) => fetchDeletePoll(poll.id, poll.locale));
        return Promise.allSettled(deletePromises)
            .then((successfulDeletes) => {
                const fulfilledDeletes = successfulDeletes
                    .filter(
                        (successfulDelete): successfulDelete is PromiseFulfilledResult<any> =>
                            successfulDelete.status === 'fulfilled',
                    )
                    .map((successfulDelete) => successfulDelete.value);
                const fulfilledDeleteIds = fulfilledDeletes.map((successfulDelete) => successfulDelete.deleted_poll);
                const remainingFanPolls = this.state.fanPolls.filter(
                    (fanPoll) => fulfilledDeleteIds.indexOf(fanPoll.id) < 0,
                );
                this.setState({ fanPolls: remainingFanPolls });
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to delete one or more polls', error)] });
            })
            .finally(() => {
                this.setState({ deleting: false });
            });
    };

    onItemsExport = () => {
        this.setState({ dataToDownload: [], exporting: true });
        fetchExportPolls(this.state.selectedFanPolls)
            .then((dataToDownload) => {
                this.setState({ dataToDownload: dataToDownload }, () => {
                    // Use a timeout to allow the state to propagate to the CSVLink control before clicking the button
                    window.setTimeout(() => {
                        const link = this.csvLink.current?.link as HTMLLinkElement;
                        link.click();
                    }, 100);
                });
            })
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to export polls', error)] });
            })
            .finally(() => this.setState({ exporting: false }));
    };

    render() {
        const disabled = !this.state.firstPageDone || this.state.exporting || this.state.deleting;
        // Get the current date and time
        const currentDatetime = new Date();
        // Create the desired string by combining the formatted date and time with "Polls-" and ".csv"
        const csvFilename = `Polls-${currentDatetime.toISOString().replace(/[:T.-]/g, '_')}.csv`;
        return (
            <div>
                <FanPollModal
                    showDeletePopup={this.state.showDeletePopup}
                    showSavePopup={false}
                    updateDeletePopup={this.updateDeletePopup}
                    updateSavePopup={this.updateDeletePopup} // Isn't used since showSavePopup = false
                    deletePoll={this.onItemsDelete}
                    savePoll={this.onItemsDelete} // Isn't used since showSavePopup = false
                />
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Table
                    items={this.state.fanPolls}
                    columnDefinitions={this.state.columnDefinitions}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    stickyHeader
                    resizableColumns
                    header={
                        <FanPollsTableHeader
                            showDeleteModal={this.openDeleteModal}
                            exportPolls={this.onItemsExport}
                            totalItems={this.state.fanPolls.length}
                            selectedItems={this.state.selectedFanPolls}
                            loading={this.state.loading}
                            onReload={this.loadItems}
                            exporting={this.state.exporting}
                            deleting={this.state.deleting}
                        />
                    }
                    loading={this.state.loading && !this.state.firstPageDone}
                    loadingText="Loading Polls"
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TablePropertyFiltering
                        {...PROPERTY_FILTERING_I18N_CONSTANTS}
                        removeTokenButtonLabel={({ propertyLabel, value }) =>
                            `Remove filtering token for ${propertyLabel} with value ${value}`
                        }
                        disabled={disabled}
                        filteringText={this.state.filteringText}
                        filteringOptions={this.state.filteringOptions}
                        tokens={this.state.filteringTokens}
                        allowFreeTextFiltering={true}
                        onPropertyFilteringChange={this.onPropertyFilteringChange}
                        filteringCountTextFunction={(count) => `${count} ${count === 1 ? 'match' : 'matches'}`}
                    />
                    <TablePagination pageSize={this.state.pageSize} onPaginationChange={this.paginationChanged} />
                    <TableSorting
                        sortableColumns={FAN_POLL_SORTABLE_COLUMNS}
                        disabled={disabled}
                        sortingColumn={this.state.sortingDetail.sortingColumn}
                        sortingDescending={this.state.sortingDetail.sortingDescending}
                        onSortingChange={this.sortingChanged}
                    />
                    <TableSelection
                        selectedItems={this.state.selectedFanPolls}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={this.state.contentSelector} />
                    </TablePreferences>
                </Table>
                <CSVLink
                    data={this.state.dataToDownload}
                    filename={csvFilename}
                    target="_blank"
                    className="awsui-util-hide"
                    ref={this.csvLink}
                />
            </div>
        );
    }
}
