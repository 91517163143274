import { create, group, test, enforce, omitWhen, skip } from 'vest';
import { ScheduleForm } from '../components/form/ScheduleForm';
import { getDateTimeFromStrings } from '../utils/dateTimeUtil';

export interface InterludeForm {
    schedule?: ScheduleForm;
}

export const interludeValidation = create('interludeForm', (interlude: InterludeForm) => {
    if (!interlude.schedule) skip.group('schedule');
    group('schedule', () => {
        const schedule = interlude.schedule;
        if (!schedule) {
            return;
        }
        omitWhen(!schedule.startEnabled, () => {
            test('startDate', 'Must provide start date', () => {
                enforce(schedule.startDate).isNotEmpty();
            });

            test('startTime', 'Must provide start time', () => {
                enforce(schedule.startTime).isNotEmpty();
            });
        });

        omitWhen(!schedule.endEnabled, () => {
            test('endDate', 'Must provide end date', () => {
                enforce(schedule.endDate).isNotEmpty();
            });

            test('endTime', 'Must provide end time', () => {
                enforce(schedule.endTime).isNotEmpty();
            });
        });

        omitWhen(!(schedule.startEnabled && schedule.endEnabled), () => {
            const startDateTime = getDateTimeFromStrings(schedule.startDate, schedule.startTime);
            const endDateTime = getDateTimeFromStrings(schedule.endDate, schedule.endTime);
            test('startAndEnd', 'Start must come before end', () => {
                if (startDateTime && endDateTime) {
                    enforce(startDateTime.toMillis()).lessThan(endDateTime.toMillis());
                }
            });
        });

        test('daysOfWeek', 'Must select at least one day', () => {
            enforce(schedule.daysOfWeek).isNotEmpty();
        });

        omitWhen(!schedule.hoursEnabled, () => {
            test('hoursStartTime', 'Must set value for start time', () => {
                enforce(schedule.hoursStartTime).isNotBlank();
            });
            test('hoursEndTime', 'Must set value for end time', () => {
                enforce(schedule.hoursEndTime).isNotBlank();
            });
            test('hoursOfDay', 'Start time cannot equal end time', () => {
                enforce(schedule.hoursStartTime).notEquals(schedule.hoursEndTime);
            });
        });
    });
});
