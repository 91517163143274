import { Table, TableContentSelector, TablePageSizeSelector, TableSorting } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { ServerAsset } from '../data-types';
import { ColumnDefinitionWithId } from '../utils/tableUtil';

export interface AssetTableItem extends ServerAsset, Table.Item {
    // Used to join fields from Interlude and Table.Item - no new fields required
}

export const COLUMN_DEFINITIONS: ColumnDefinitionWithId<AssetTableItem>[] = [
    {
        id: 'id',
        header: () => 'Asset ID',
        cell: (item) => (
            <div>
                <Link to={`/assets/${item.id}`}>{item.id}</Link>
            </div>
        ),
        minWidth: '40px',
        allowLineWrap: true,
    },
    {
        id: 'type',
        header: () => 'Type',
        cell: (item) => item.type,
        minWidth: '30px',
        allowLineWrap: true,
    },
    {
        id: 'lang',
        header: () => 'Language',
        cell: (item) => item.lang,
        minWidth: '20px',
        allowLineWrap: true,
    },
    {
        id: 'mtr',
        header: () => 'Territory',
        cell: (item) => item.mtr,
        minWidth: '20px',
        allowLineWrap: true,
    },
    {
        id: 'descr',
        header: () => 'Description',
        cell: (item) => item.descr,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'origfile',
        header: () => 'Original Filename',
        cell: (item) => item.origfile,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'author',
        header: () => 'Author',
        cell: (item) => item.author,
        minWidth: '70px',
        allowLineWrap: true,
    },
    {
        id: 'srcs',
        header: () => 'Sources',
        cell: (item) => (item.srcs ? JSON.stringify(item.srcs[0]) : ''),
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'template',
        header: () => 'Template',
        cell: (item) => item.template,
        minWidth: '100px',
        allowLineWrap: true,
    },
    {
        id: 'asin',
        header: () => 'Artist ASIN',
        cell: (item) => item.asin,
        minWidth: '100px',
    },
    {
        id: 'data_stage',
        header: () => 'Stage',
        cell: (item) => item.data_stage || 'live',
        minWidth: '100px',
    },
    {
        id: 'height',
        header: () => 'Height',
        cell: (item) => item.height_pixels,
        minWidth: '100px',
    },
    {
        id: 'width',
        header: () => 'Width',
        cell: (item) => item.width_pixels,
        minWidth: '100px',
    },
    {
        id: 'category',
        header: () => 'Category',
        cell: (item) => item.category,
        minWidth: '100px',
    },
];

const idRegex = /([a-z]+)-([0-9]+)/i;

function sortId(item1?: AssetTableItem, item2?: AssetTableItem): number {
    if (!item1?.id || !item2?.id) return 0;
    if (!item1?.id) return -1;
    if (!item2?.id) return -1;

    const m1 = item1.id.match(idRegex);
    const m2 = item2.id.match(idRegex);

    // "IL-1234" => ["IL-1234", "IL", "1234"]

    if (!m1 || !m2 || m1[1] !== m2[1]) return item1.id === item2.id ? 0 : item1.id < item2.id ? -1 : 1;

    return parseInt(m1[2]) - parseInt(m2[2]);
}

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<AssetTableItem>[] = [
    { id: 'id', comparator: sortId },
    { id: 'author', field: 'author' },
    { id: 'asin', field: 'asin' },
    { id: 'lang', field: 'lang' },
    { id: 'mtr', field: 'mtr' },
    { id: 'type', field: 'type' },
    { id: 'descr', field: 'descr' },
    { id: 'origfile', field: 'origfile' },
    { id: 'template', field: 'template' },
    { id: 'width', field: 'width_pixels' },
    { id: 'height', field: 'height_pixels' },
    { id: 'data_stage', field: 'data_stage' },
    { id: 'category', field: 'category' },
];

export const FILTERING_FIELDS = ['type', 'author', 'lang', 'mtr', 'author'];

export const CONTENT_SELECTOR_OPTIONS: TableContentSelector.ContentDescriptionGroup[] = [
    {
        label: 'Main Properties',
        options: [
            { id: 'id', label: 'Asset ID', editable: false, visible: true },
            { id: 'type', label: 'Type', editable: true, visible: true },
            { id: 'data_stage', label: 'Data Stage', editable: true, visible: true },
            { id: 'asin', label: 'Artist ASIN', editable: true, visible: true },
            { id: 'author', label: 'Author', editable: true, visible: true },
            { id: 'descr', label: 'Description', editable: true, visible: true },
        ],
    },
    {
        label: 'Additional Details',
        options: [
            { id: 'lang', label: 'Language', editable: true, visible: false },
            { id: 'mtr', label: 'Marketplace', editable: true, visible: false },
            { id: 'origfile', label: 'Original Filename', editable: true, visible: false },
            { id: 'srcs', label: 'Sources', editable: true, visible: false },
        ],
    },
    {
        label: 'Text Content',
        options: [{ id: 'template', label: 'Template', editable: true, visible: true }],
    },
    {
        label: 'Image Content',
        options: [
            { id: 'category', label: 'Category', editable: true, visible: true },
            { id: 'height', label: 'Height', editable: true, visible: true },
            { id: 'width', label: 'Width', editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS: TablePageSizeSelector.Option[] = [
    { value: 10, label: '10 Assets' },
    { value: 15, label: '15 Assets' },
    { value: 30, label: '30 Assets' },
    { value: 100, label: '100 Assets' },
    { value: 500, label: '500 Assets' },
    { value: 1000, label: '1000 Assets' },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter distributions by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear filters',
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter distributions',
    filteringStatusText: 'Loading',
};
