import { FormSection, Input, Toggle } from '@amzn/awsui-components-react';
import { applyChange } from '../../utils/eventUtil';
import { ForgeCommentaryExperience } from '../../utils/commentary/commentaryDataTypes';
import React from 'react';

export interface Props {
    experience: ForgeCommentaryExperience;
    onExperienceChanged: (exp: ForgeCommentaryExperience) => void;
}

export default class InformationSection extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }

    handleChange = (e: CustomEvent): void => {
        applyChange(e, this.props.experience);
        this.props.onExperienceChanged(this.props.experience);
    };

    render = (): JSX.Element => {
        const responsiveColumn = 'col-xxxs-12';
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveField = 'col-xxxs-12 col-xxs-8';
        const fixedLabel = 'col-xxxs-5 col-xxs-4';
        const fixedField = 'col-xxxs-7 col-xxs-8';
        const isAssociationAsinReadOnly =
            this.props.experience.associationType === 'ALBUM' || this.props.experience.mousaiState !== 'LOCAL';

        return (
            <FormSection className={responsiveColumn} header="Commentary Information">
                <div className="awsui-row">
                    <h4 className={responsiveLabel}>Primary Text:</h4>
                    <Input
                        className={responsiveField}
                        type="text"
                        id="primaryText"
                        value={this.props.experience?.primaryText}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="awsui-row">
                    <h4 className={responsiveLabel}>Secondary Text:</h4>
                    <Input
                        className={responsiveField}
                        type="text"
                        id="secondaryText"
                        value={this.props.experience?.secondaryText}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="awsui-row">
                    <h4 className={responsiveLabel}>ASIN:</h4>
                    <Input
                        className={responsiveField}
                        type="text"
                        id="associationAsin"
                        value={this.props.experience?.associationAsin}
                        onChange={this.handleChange}
                        disabled={isAssociationAsinReadOnly}
                    />
                </div>
                {this.props.experience.mousaiState !== 'LOCAL' ? (
                    <div className="awsui-row">
                        <h4 className={fixedLabel}>Is Explicit:</h4>
                        <Toggle
                            className={fixedField}
                            id="isExplicit"
                            checked={this.props.experience?.isExplicit}
                            disabled={true}
                        />
                    </div>
                ) : null}
            </FormSection>
        );
    };
}
