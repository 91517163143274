import React from 'react';
import { HelpContext } from './HelpContext';

export interface HelpReferenceProps {
    /** (Optional) the text of the link, defaults to the AWSUI recommended value: 'Info' */
    label?: string;
    /** The content to load in the tools panel when the user clicks on the link */
    content: React.ReactNode;
}

/**
 * Displays a link that follows the AWSUI design guidelines which will open
 * the help pane with the given content when clicked.
 * See: https://code.amazon.com/packages/ForgeToolWebsiteContents/blobs/mainline/--/DEVELOPMENT.md#help-content
 */
function HelpInfoLink(props: HelpReferenceProps) {
    const context = React.useContext(HelpContext);
    const showHelp = (e: React.MouseEvent) => {
        e.preventDefault();
        context.setHelpContent(props.content, false, true);
    };
    return (
        <a className="awsui-util-help-info-link" href="#" onClick={showHelp}>
            {props.label || 'Info'}
        </a>
    );
}

export default HelpInfoLink;
