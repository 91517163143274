import { useState } from 'react';
import { UploadButton } from './UploadButton';
import UnsupportedFileModal from './UnsupportedFileModal';

export interface Props {
    loadingFile: boolean;
    multiple: boolean;
    canUploadFolder: boolean;
    onFileChange: (files: File[]) => void;
}

const validFileTypes = ['audio/mp3', 'audio/mpeg'];

const AudioMediaFooter = (props: Props): JSX.Element => {
    const [modalVisible, setModalVisible] = useState(false);
    const [unsupportedFileNames, setUnsupportedFileNames] = useState(['']);

    const onFileChange = (files: File[]) => {
        if (files.length < 1) {
            return;
        }
        // Filter out DS_Store files that might be accidentally included
        files = files.filter((file) => file.name !== '.DS_Store');
        const invalidFiles = files.filter((file) => !validFileTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            setUnsupportedFileNames(invalidFiles.map((file) => file.name));
            setModalVisible(true);
            return;
        }
        props.onFileChange(files);
    };

    return (
        <>
            <UnsupportedFileModal
                visible={modalVisible}
                fileNames={unsupportedFileNames}
                validFormats={validFileTypes}
                onExit={() => setModalVisible(false)}
            />
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-12">
                        <div className="awsui-util-f-l">
                            <UploadButton
                                variant="link"
                                icon="file-open"
                                accept={validFileTypes.join(',')}
                                multiple={props.multiple}
                                onFileChange={onFileChange}
                                loading={props.loadingFile}
                                disabled={props.loadingFile}
                            >
                                {props.multiple ? 'Choose files' : 'Choose file'}
                            </UploadButton>
                            {props.canUploadFolder && (
                                <UploadButton
                                    variant="link"
                                    icon="folder-open"
                                    accept={validFileTypes.join(',')}
                                    multiple={true}
                                    onFileChange={onFileChange}
                                    isFolderUploadButton={true}
                                    loading={props.loadingFile}
                                    disabled={props.loadingFile}
                                >
                                    Choose folder
                                </UploadButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AudioMediaFooter;
