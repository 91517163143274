import { CatalogManagerClient } from '@amzn/amazon-music-interlude-catalog-manager-client';
import { AxiosRequestConfig } from 'axios';
import { getAuthorizationHeader } from '../auth/AuthGenerator';
import stage from '../configs/stage';

const CATALOG_API_ENDPOINTS: Record<string, string> = {
    alpha: ' https://mncet4iydb.execute-api.us-east-1.amazonaws.com/beta/forge/v1',
    beta: ' https://mncet4iydb.execute-api.us-east-1.amazonaws.com/beta/forge/v1',
    gamma: ' https://evfyuclok2.execute-api.us-east-1.amazonaws.com/gamma/forge/v1',
    prod: 'https://hfbmt6pvl7.execute-api.us-east-1.amazonaws.com/prod/forge/v1',
};

export default class CatalogClient {
    private static client: CatalogManagerClient;

    private static authInterceptor(config: AxiosRequestConfig<any>) {
        config.headers = {
            ...config.headers,
            Authorization: getAuthorizationHeader(),
        };
        return config;
    }

    private static getEndpoint() {
        if (process.env.REACT_APP_CATALOG_API_URL) {
            return process.env.REACT_APP_CATALOG_API_URL;
        }
        const current = stage();
        return CATALOG_API_ENDPOINTS[current];
    }

    // Lazy initialization of the client
    static get instance(): CatalogManagerClient {
        if (!this.client) {
            this.client = new CatalogManagerClient({
                addAwsV4SigningToRequests: false,
                serviceBaseUrl: this.getEndpoint(),
            });
            this.client.axiosInstance.interceptors.request.use(this.authInterceptor);
        }
        return this.client;
    }
}
