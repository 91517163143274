import { useEffect, useRef, useState } from 'react';
import PageHeader from '../PageHeader';
import EditStationSettings from './EditStationSettings';

import { PageHeaderButton } from '../PageHeader';
import { useParams } from 'react-router-dom';
import { Icon, RadioGroup, Tabs, Flashbar } from '@amzn/awsui-components-react';
import {
    MiniSequence,
    StationSettings,
    WebQcar,
    serverToWeb,
    PinnedInterludes,
} from '../../utils/qcar/qcarTransformer';
import { fetchGetQcar, fetchUpdateQcar } from '../../utils/fetchUtil';
import EditSequencedInterludes from './EditSequencedInterludes';
import EditPinnedInterludes from './EditPinnedInterludes';
import { webToServer } from '../../utils/qcar/qcarTransformer';
import { Container, Grid, Header, StatusIndicator } from '@amzn/awsui-components-react-v3';
import { FlashbarItem, itemSuccess, itemError } from '../commons/flash-messages';
import _ from 'lodash';
import useDatastage from '../../hooks/useDatastage';

type EditQcarParams = {
    qcarId: string;
};

class TabId {
    public static readonly STATION_SETTINGS = 'station-settings';
    public static readonly SEQUENCED_INTERLUDES = 'sequenced-interludes';
    public static readonly PINNED_INTERLUDES = 'pinned-interludes';
}

const EditQcar: React.FC = () => {
    const params = useParams<EditQcarParams>();
    const shouldLoadQcarFromServer = useRef(true);

    const [isStationSettingsValid, setIsStationSettingsValid] = useState(true);
    const [isSequencedInterludesValid, setIsSequencedInterludesValid] = useState(true);
    const [isPinnedInterludesValid, setIsPinnedInterludesValid] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isQcarRequestFinished, setIsQcarRequestFinished] = useState(false);

    const [variationIndex, setVariationIndex] = useState(0);
    const [variationValidStates, setVariationValidStates] = useState({} as { [variation: number]: boolean });
    const [qcar, setQcar] = useState(undefined as WebQcar | undefined);
    const [originalQcar, setOriginalQcar] = useState(undefined as WebQcar | undefined);
    const [activeTab, setActiveTab] = useState(TabId.STATION_SETTINGS);
    const [flashbarItems, setFlashbarItems] = useState([] as FlashbarItem[]);

    const dataStage = useDatastage(() => {
        setIsQcarRequestFinished(false);
        setFlashbarItems([]);
        setOriginalQcar(undefined);
        setQcar(undefined);
        shouldLoadQcarFromServer.current = true;
    });

    useEffect(() => {
        const newVariationValidStates = { ...variationValidStates };
        newVariationValidStates[variationIndex] =
            isStationSettingsValid && isSequencedInterludesValid && isPinnedInterludesValid;
        setVariationValidStates(newVariationValidStates);
    }, [isStationSettingsValid, isSequencedInterludesValid, isPinnedInterludesValid]);

    const getActiveFlashbarItems = () => {
        return flashbarItems.filter((x) => !x.isDismissed);
    };

    const displaySuccess = () => {
        const successMessage = itemSuccess(`Successfully updated QCAR ${params.qcarId}`);
        setFlashbarItems([successMessage, ...getActiveFlashbarItems()]);
    };

    const displayRetrievalError = (error: string) => {
        const errorMessage = itemError(
            `Error when getting QCAR ${params.qcarId} from data stage ${dataStage.toUpperCase()}: ${error}`,
        );
        setFlashbarItems([errorMessage, ...getActiveFlashbarItems()]);
    };

    const displayUpdateError = (error: string) => {
        const errorMessage = itemError(
            `Error when updating QCAR ${params.qcarId} in data stage ${dataStage.toUpperCase()}: ${error}`,
        );
        setFlashbarItems([errorMessage, ...getActiveFlashbarItems()]);
    };

    const fetchQcarFromServer = () => {
        fetchGetQcar(dataStage, params.qcarId)
            .then((serverQcar) => {
                const webQcar = serverToWeb(serverQcar);
                setQcar(_.cloneDeep(webQcar));
                setOriginalQcar(_.cloneDeep(webQcar));
            })
            .catch(displayRetrievalError)
            .finally(() => setIsQcarRequestFinished(true));
    };

    if (shouldLoadQcarFromServer.current) {
        shouldLoadQcarFromServer.current = false;
        fetchQcarFromServer();
    }

    const saveQcar = () => {
        if (qcar) {
            setIsSaving(true);
            try {
                fetchUpdateQcar(dataStage, params.qcarId, webToServer(qcar))
                    .then((serverQcar) => {
                        const webQcar = serverToWeb(serverQcar);
                        setQcar(_.cloneDeep(webQcar));
                        setOriginalQcar(_.cloneDeep(webQcar));
                        displaySuccess();
                    })
                    .catch(displayUpdateError);
            } catch (error) {
                displayUpdateError('Transformer error: ' + error);
            } finally {
                setIsSaving(false);
            }
        }
    };

    const allVariationsAreValid = () => {
        return Object.values(variationValidStates).every((value) => value === true);
    };

    const qcarIsEdited = () => {
        return !_.isEqual(qcar, originalQcar);
    };

    const headerText = `Edit QCAR ${qcar?.id} (${
        qcar ? qcar.variations[variationIndex].stationSettings.general.stationName : '...'
    })`;
    const headerButtons: PageHeaderButton[] = [];
    headerButtons.push({
        text: 'Save',
        variant: 'primary',
        loading: isSaving,
        disabled: !isQcarRequestFinished || !qcar || !allVariationsAreValid() || !qcarIsEdited(),
        onClick: () => saveQcar(),
    });

    const updateStationSettings = (newStationSettings: StationSettings) => {
        if (qcar?.variations) {
            const newQcar = { ...qcar };
            newQcar.variations[variationIndex].stationSettings = newStationSettings;
            setQcar(newQcar);
        }
    };

    const updateSequencedInterludes = (newSequencedInterludes: MiniSequence[]) => {
        if (qcar?.variations) {
            const newQcar = { ...qcar };
            newQcar.variations[variationIndex].sequencedInterludes = newSequencedInterludes;
            setQcar(newQcar);
        }
    };

    const updatePinnedInterludes = (newPinnedInterludes: PinnedInterludes) => {
        if (qcar?.variations) {
            const newQcar = { ...qcar };
            newQcar.variations[variationIndex].pinnedInterludes = newPinnedInterludes;
            setQcar(newQcar);
        }
    };

    const getVariationsForList = () => {
        if (qcar?.variations) {
            return qcar?.variations.map((variation, index) => {
                return {
                    value: index.toString(),
                    label: variation.label,
                    description: variationValidStates[index] == false ? 'invalid' : '',
                };
            });
        }
        return [];
    };

    const warningIcon = (
        <div style={{ marginRight: 5, display: 'inline-block' }}>
            <Icon name="status-warning" variant="error" className="" />
        </div>
    );

    const centeredHorizontallyAndVertically = {
        width: '100%',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    } as React.CSSProperties;

    const loadingDisplay = (
        <div style={centeredHorizontallyAndVertically}>
            <StatusIndicator type="loading">Loading</StatusIndicator>
        </div>
    );

    const errorDisplay = (
        <div style={centeredHorizontallyAndVertically}>
            <StatusIndicator type="error">An Error Occured while Loading</StatusIndicator>
        </div>
    );

    const currentPageStateDisplay = isQcarRequestFinished ? errorDisplay : loadingDisplay;

    const tabs = [
        {
            id: TabId.STATION_SETTINGS,
            label: <div>{!isStationSettingsValid && warningIcon} Station Settings</div>,
            content: qcar?.variations ? (
                <EditStationSettings
                    stationSettings={qcar.variations[variationIndex].stationSettings}
                    setIsValid={(isValid) => setIsStationSettingsValid(isValid)}
                    onEdit={updateStationSettings}
                />
            ) : (
                currentPageStateDisplay
            ),
        },
        {
            id: TabId.SEQUENCED_INTERLUDES,
            label: <div>{!isSequencedInterludesValid && warningIcon} Sequenced Interludes</div>,
            content: qcar?.variations ? (
                <EditSequencedInterludes
                    sequencedInterludes={qcar.variations[variationIndex].sequencedInterludes}
                    setIsValid={(isValid) => setIsSequencedInterludesValid(isValid)}
                    onEdit={updateSequencedInterludes}
                />
            ) : (
                currentPageStateDisplay
            ),
        },
        {
            id: TabId.PINNED_INTERLUDES,
            label: <div>{!isPinnedInterludesValid && warningIcon} Pinned Interludes</div>,
            content: qcar?.variations ? (
                <EditPinnedInterludes
                    pinnedInterludes={qcar.variations[variationIndex].pinnedInterludes}
                    setIsValid={(isValid) => setIsPinnedInterludesValid(isValid)}
                    onEdit={updatePinnedInterludes}
                />
            ) : (
                currentPageStateDisplay
            ),
        },
    ];

    const variationsHeader = <Header variant="h2">{'Variations (' + (qcar?.variations?.length ?? '...') + ')'}</Header>;

    return (
        <div>
            <Flashbar items={flashbarItems}></Flashbar>
            <PageHeader text={headerText} buttons={headerButtons} />
            <Grid gridDefinition={[{ colspan: { default: 12, s: 3 } }, { colspan: { default: 12, s: 9 } }]}>
                <Container fitHeight header={variationsHeader}>
                    {qcar?.variations ? (
                        <RadioGroup
                            value={variationIndex.toString()}
                            onChange={(event) => setVariationIndex(Number(event.detail.value))}
                            items={getVariationsForList()}
                        />
                    ) : (
                        currentPageStateDisplay
                    )}
                </Container>
                <Tabs tabs={tabs} activeTabId={activeTab} onChange={(e) => setActiveTab(e.detail.activeTabId)} />
            </Grid>
        </div>
    );
};

export default EditQcar;
