import { ColumnLayout, ExpandableSection, FormField, Input } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import { Overlay as OverlayOptions } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';
import { Expandable } from './ImageFormat';

export interface OverlayProps {
    options: OverlayOptions;
    errors?: StringMap;
    onChange: (changed: OverlayOptions) => void;
}

function Overlay(props: OverlayProps): JSX.Element {
    const expandable = props.options as Expandable;
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        setExpanded(expandable.expanded || false);
    });
    const expandedChange = (e: CustomEvent<ExpandableSection.ChangeDetail>) => {
        expandable.expanded = e.detail.expanded;
        setExpanded(expandable.expanded);
    };
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <ExpandableSection expanded={expanded} onChange={expandedChange} header="Overlay">
            <ColumnLayout columns={1}>
                <div data-awsui-column-layout-root="true">
                    <div>Specify how this layer should be composed on top of the preious one</div>
                    <div className="awsui-grid awsui-util-ml-xl">
                        <div className="awsui-row">
                            <FormField className="col-3" label="Offset X" errorText={errors['offsetX']} stretch={true}>
                                <Input
                                    id="offsetX"
                                    value={props.options.offsetX.toString()}
                                    onChange={handleChange}
                                ></Input>
                            </FormField>
                            <FormField className="col-3" label="Offset Y" errorText={errors['offsetY']} stretch={true}>
                                <Input
                                    id="offsetY"
                                    value={props.options.offsetY.toString()}
                                    onChange={handleChange}
                                ></Input>
                            </FormField>
                        </div>
                    </div>
                </div>
            </ColumnLayout>
        </ExpandableSection>
    );
}

export default Overlay;
