import React from 'react';
import { ExperienceState, ExperienceType, MusicExperienceSearchItem } from '@amzn/mousai-service-client';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSorting,
    TableWrapLines,
} from '@amzn/awsui-components-react';
import {
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
    ArtistCommentaryTableOptions,
    TableOptions,
} from '../../utils/tableUtil';
import { itemError } from '../commons/flash-messages';
import { Props, RouteStateComponent } from '../RouteState';
import { createPropertyStorage } from '../../utils/jsonStorage';
import { PropsWithDataStage } from '../StageContext';
import MousaiClient from '../../utils/mousaiUtil';
import {
    ARTIST_COMMENTARIES_COLUMN_DEFINITIONS,
    ARTIST_COMMENTARY_CONTENT_SELECTOR_OPTIONS,
    ARTIST_COMMENTARY_SORTABLE_COLUMNS,
    PAGE_SELECTOR_OPTIONS,
} from '../../configs/experiences-table-config';
import { TableNoMatchState } from '../commons/common-components';

const columnStorage = createPropertyStorage<ColumnSetting[]>('Experiences-Table-Settings');
const optionsStorage = createPropertyStorage<TableOptions>('Experiences-Table-Options');

let cachedItems: MusicExperienceSearchItem[] | undefined;

type State = ArtistCommentaryTableOptions;

export default class ArtistCommentariesTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: ARTIST_COMMENTARIES_COLUMN_DEFINITIONS,
            contentSelector: ARTIST_COMMENTARY_CONTENT_SELECTOR_OPTIONS,
            selectedExperiences: [],
            experiences: [],
            loading: true,
            hasData: false,
            working: false,
            flashbar: [],
            sortingDetail: { sortingColumn: ARTIST_COMMENTARIES_COLUMN_DEFINITIONS[5].id, sortingDescending: false },
            typeFilter: ExperienceType.COMMENTARY,
            stateFilters: [ExperienceState.LIVE, ExperienceState.DRAFT],
            showScheduleModal: false,
            ...DEFAULT_TABLE_OPTIONS,
        };
    }

    historyState = new RouteStateComponent(this.props, this.state);
    unmounted = false;

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithColumnDefinitionIds(ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, 'width', e.detail.widths, existing),
        );
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithContentSelectionValues(ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, e.detail.contentSelection, existing),
        );
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(ARTIST_COMMENTARY_CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    applyFilters() {
        if (!this.unmounted) {
            this.setState({
                hasData: true,
                experiences:
                    cachedItems?.filter(
                        (exp) => this.state.stateFilters.includes(exp.state) && exp.type === this.state.typeFilter,
                    ) ?? [],
            });
        }
    }

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    componentWillUnmount(): void {
        this.unmounted = true;
        if (this.state.loading) {
            cachedItems = undefined;
        }
    }

    componentDidUpdate(prevProps: PropsWithDataStage): void {
        if (this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadItems();
        }
    }

    loadItems = async (useCache?: boolean) => {
        if (useCache && cachedItems) {
            this.setState({
                loading: false,
            });
            this.applyFilters();
            return;
        }
        this.setState({
            hasData: false,
            loading: true,
        });
        this.props.history.replace({ ...this.props.location });
        try {
            const input = {
                query: {
                    experienceTypes: [this.state.typeFilter],
                },
                includeAssets: true,
                includeStormResources: true,
            };
            await MousaiClient.search(input, (experiences) => {
                cachedItems = experiences;
                this.applyFilters();
            });
        } catch (error) {
            this.setState({
                flashbar: [itemError('Failed to load items', error)],
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const disabled = (this.state.loading && !this.state.hasData) || this.state.working;
        return (
            <div>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.experiences}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    loading={disabled}
                    loadingText="Loading..."
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Find..."
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                    <TablePagination
                        disabled={disabled}
                        pageSize={this.state.pageSize}
                        onPaginationChange={this.paginationChanged}
                    />
                    <TableSorting
                        sortableColumns={ARTIST_COMMENTARY_SORTABLE_COLUMNS}
                        disabled={disabled}
                        sortingColumn={this.state.sortingDetail.sortingColumn}
                        sortingDescending={this.state.sortingDetail.sortingDescending}
                        onSortingChange={this.sortingChanged}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" />
                        <TableContentSelector title="Select visible columns" options={this.state.contentSelector} />
                    </TablePreferences>
                </Table>
            </div>
        );
    }
}
