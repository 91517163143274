import { ForgeCommentaryExperience } from './commentaryDataTypes';
import {
    Asset,
    AssociatedEntity,
    AssociatedEntityEntityType,
    CreateExperienceInput,
    ExperienceState,
    ExperienceType,
} from '@amzn/mousai-service-client';
import { AssetDestination, uploadMedia } from '../assetUtil';
import { fetchGetPresignedS3DownloadUrl } from '../fetchUtil';
import MousaiClient from '../mousaiUtil';
import _ from 'lodash';

export interface MousaiAPIResult {
    experienceId: string;
    message: any;
}

// MARK: Save
export async function handleCommentaryFormSave(
    initialExperiences: ForgeCommentaryExperience[],
    updatedExperiences: ForgeCommentaryExperience[],
    albumAsin: string,
    artistAsin: string,
): Promise<[MousaiAPIResult[], MousaiAPIResult[]]> {
    const mousaiAPIHelper = new MousaiAPIHelper(albumAsin, artistAsin);
    const promises: Promise<MousaiAPIResult>[] = [];
    for (const exp of updatedExperiences) {
        if (exp.isForDeletion) {
            promises.push(mousaiAPIHelper.handleExperienceDeletion(exp));
        } else if (exp.mousaiState === 'LOCAL') {
            promises.push(mousaiAPIHelper.handleExperienceCreation(exp));
        } else if (exp.didExperienceChange) {
            promises.push(mousaiAPIHelper.handleExperienceUpdate(exp, initialExperiences));
        }
    }
    await Promise.allSettled(promises);
    return [mousaiAPIHelper.successes, mousaiAPIHelper.failures];
}

/**
 * Helper class to determine which API call to apply
 * **Case 1**: Experience exists locally only
 * This is easy - just one call to CREATE API
 *
 * **Case 2**: Experience exists in Mousai and is updated locally
 * Check these 4 fields, which determine which UPDATE APIs should be called:
 * Update Assets API
 * - audioFile
 * - imageFile
 * - displayText
 *
 * Update Metadata API
 * - title
 *
 * **Case 3**: Experience exists in Mousai and was deleted locally
 * Also easy - just delete the experience. We do this by transitioning the state to deleted
 */
class MousaiAPIHelper {
    private readonly albumAsin: string;
    private readonly artistAsin: string;
    public readonly successes: MousaiAPIResult[];
    public readonly failures: MousaiAPIResult[];

    constructor(albumAsin: string, artistAsin: string) {
        this.albumAsin = albumAsin;
        this.artistAsin = artistAsin;
        this.successes = [];
        this.failures = [];
    }

    handleExperienceCreation = async (exp: ForgeCommentaryExperience): Promise<any> => {
        const uploadAssetResult = await this.uploadExperienceAssets(exp);
        if (!uploadAssetResult) {
            return;
        }
        const [audioUrl, imageUrl] = uploadAssetResult;
        const createInput = convertToCreateInputModel(exp, audioUrl, imageUrl, this.albumAsin, this.artistAsin);
        if (!createInput) {
            return;
        }

        try {
            await MousaiClient.createExperience(createInput);
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully created ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error creating ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleExperienceDeletion = async (exp: ForgeCommentaryExperience): Promise<any> => {
        try {
            await MousaiClient.instance.updateExperienceState(exp.key, { state: ExperienceState.DELETED });
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully deleted ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error deleting ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleExperiencePromotion = async (exp: ForgeCommentaryExperience): Promise<any> => {
        try {
            await MousaiClient.instance.updateExperienceState(exp.key, { state: ExperienceState.LIVE });
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully promoted ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error promoting ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleExperienceUpdate = async (
        exp: ForgeCommentaryExperience,
        initialExperiences: ForgeCommentaryExperience[],
    ): Promise<any> => {
        const initialExperience = _(initialExperiences)
            .filter((e) => e.key == exp.key)
            .first();
        if (!initialExperience) {
            return;
        }

        try {
            await Promise.all([
                this.handleAssetUpdate(exp, initialExperience),
                this.handleMetadataUpdate(exp, initialExperience),
            ]);
            this.successes.push({
                experienceId: exp.key,
                message: `Successfully updated ${exp.associationType} commentary for ${exp.associationAsin}`,
            });
        } catch (err) {
            this.failures.push({
                experienceId: exp.key,
                message: `Error updating ${exp.associationType} commentary for ${exp.associationAsin}. ${err}`,
            });
        }
    };

    handleAssetUpdate = async (exp: ForgeCommentaryExperience, initialExperience: ForgeCommentaryExperience) => {
        const toUpdate: Asset[] = [];
        if (!!exp.audioFile) {
            const audioUrl = await this.uploadFileToMousai(exp.audioFile);
            toUpdate.push({
                key: 'MESSAGE',
                contentType: 'AUDIO',
                contentSource: 'HTTP',
                content: audioUrl,
            });
        }
        if (!!exp.imageFile) {
            const imageUrl = await this.uploadFileToMousai(exp.imageFile);
            toUpdate.push({
                key: 'BACKGROUND',
                contentType: 'IMAGE',
                contentSource: 'HTTP',
                content: imageUrl,
            });
        }
        if (exp.secondaryText !== initialExperience.secondaryText) {
            toUpdate.push({
                key: 'TITLE',
                contentType: 'TEXT',
                contentSource: 'RAW',
                content: exp.secondaryText,
            });
        }
        if (toUpdate.length > 0) {
            return MousaiClient.instance.updateExperienceAssets(exp.key, {
                setAssets: toUpdate,
            });
        }
    };

    handleMetadataUpdate = (exp: ForgeCommentaryExperience, initialExperience: ForgeCommentaryExperience) => {
        if (exp.primaryText !== initialExperience.primaryText) {
            return MousaiClient.instance.updateExperienceMetadata(exp.key, { title: exp.primaryText });
        }
    };

    uploadExperienceAssets = async (exp: ForgeCommentaryExperience): Promise<[string, string] | undefined> => {
        if (!exp.audioFile || !exp.imageFile) {
            return;
        }
        return await Promise.all([this.uploadFileToMousai(exp.audioFile), this.uploadFileToMousai(exp.imageFile)]);
    };

    uploadFileToMousai = async (file: File): Promise<string> => {
        const uploadResult = await uploadMedia(AssetDestination.MOUSAI, file);
        return await fetchGetPresignedS3DownloadUrl(uploadResult.filename);
    };
}

// MARK: Promote
export async function handleCommentaryFormPromote(
    experiences: ForgeCommentaryExperience[],
    albumAsin: string,
    artistAsin: string,
): Promise<[MousaiAPIResult[], MousaiAPIResult[]]> {
    const mousaiAPIHelper = new MousaiAPIHelper(albumAsin, artistAsin);
    const promises: Promise<MousaiAPIResult>[] = [];
    for (const exp of experiences) {
        promises.push(mousaiAPIHelper.handleExperiencePromotion(exp));
    }
    await Promise.allSettled(promises);
    return [mousaiAPIHelper.successes, mousaiAPIHelper.failures];
}

// MARK: API Helpers
export function convertToCreateInputModel(
    exp: ForgeCommentaryExperience,
    audioUrl: string,
    imageUrl: string,
    albumAsin?: string,
    artistAsin?: string,
): CreateExperienceInput | undefined {
    const assets = buildAssets(audioUrl, imageUrl, exp.secondaryText);
    const associatedEntities = buildAssociatedEntities(exp, artistAsin);
    if (
        !exp.primaryText ||
        exp.isExplicit === undefined ||
        !albumAsin ||
        assets.length === 0 ||
        associatedEntities.length === 0
    ) {
        return;
    }

    return {
        assets: assets,
        associatedEntities: associatedEntities,
        eligibility: {
            isExplicit: exp.isExplicit,
        },
        owner: {
            id: albumAsin,
            idType: 'ASIN',
        },
        state: ExperienceState.DRAFT,
        title: exp.primaryText,
        type: ExperienceType.COMMENTARY,
    };
}

function buildAssociatedEntities(exp: ForgeCommentaryExperience, artistAsin?: string): Array<AssociatedEntity> {
    const playableEntityType = getPlayableEntityType(exp.associationType);
    if (!artistAsin || !playableEntityType || !exp.associationAsin) {
        return [];
    }
    return [
        {
            entityType: playableEntityType,
            identifier: {
                id: exp.associationAsin,
                idType: 'ASIN',
            },
        },
        {
            entityType: 'ARTIST',
            identifier: {
                id: artistAsin,
                idType: 'ASIN',
            },
        },
    ];
}

function buildAssets(audioUrl: string, imageUrl: string, displayText?: string): Array<Asset> {
    if (!displayText) {
        return [];
    }
    return [
        {
            key: 'BACKGROUND',
            contentType: 'IMAGE',
            contentSource: 'HTTP',
            content: imageUrl,
        },
        {
            key: 'MESSAGE',
            contentType: 'AUDIO',
            contentSource: 'HTTP',
            content: audioUrl,
        },
        {
            key: 'TITLE',
            contentType: 'TEXT',
            contentSource: 'RAW',
            content: displayText,
        },
    ];
}

function getPlayableEntityType(type?: string): AssociatedEntityEntityType | undefined {
    if (type === 'ALBUM') {
        return 'ALBUM';
    } else if (type === 'TRACK') {
        return 'TRACK';
    }
}
