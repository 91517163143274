import { FormField, Input, SegmentedControl } from '@amzn/awsui-components-react';
import { StringMap } from '../../../data-types';
import { applyChange } from '../../../utils/eventUtil';
import { Circle as CircleOptions, UnitType } from '@amzn/forge-image-processing-types';
import { deepCopy } from '../../../utils/jsonUtil';

export interface CircleProps {
    options: CircleOptions;
    errors?: StringMap;
    onChange: (changed: CircleOptions) => void;
}

const unitTypes = [
    {
        id: UnitType.Percentage,
        text: 'Percentage',
    },
    {
        id: UnitType.Pixels,
        text: 'Pixels',
    },
];

function Circle(props: CircleProps): JSX.Element {
    const errors = props.errors || {};
    const handleChange = (e: CustomEvent<Input.ChangeDetail | SegmentedControl.ChangeDetail>) => {
        const changed = applyChange(e, deepCopy(props.options));
        props.onChange(changed);
    };
    return (
        <div className="awsui-grid awsui-util-ml-xl">
            <div className="awsui-row">
                <FormField className="col-6" label="Unit type:" errorText={errors['units']} stretch={true}>
                    <SegmentedControl
                        id="units"
                        options={unitTypes}
                        selectedId={props.options.units}
                        onChange={handleChange}
                    ></SegmentedControl>
                </FormField>
            </div>
            <div className="awsui-row">
                <FormField className="col-3" label="Radius:" errorText={errors['radius']} stretch={true}>
                    <Input id="radius" value={props.options.radius.toString()} onChange={handleChange}></Input>
                </FormField>
                <FormField className="col-3" label="Offset X:" errorText={errors['offsetX']} stretch={true}>
                    <Input id="offsetX" value={props.options.offsetX.toString()} onChange={handleChange}></Input>
                </FormField>
                <FormField className="col-3" label="Offset Y:" errorText={errors['offsetY']} stretch={true}>
                    <Input id="offsetY" value={props.options.offsetY.toString()} onChange={handleChange}></Input>
                </FormField>
            </div>
        </div>
    );
}

export default Circle;
