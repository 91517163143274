import { Button, ColumnLayout, ExpandableSection } from '@amzn/awsui-components-react';
import React from 'react';
import { useEffect, useState } from 'react';
import { StringMap } from '../../../data-types';
import {
    DefaultOperations,
    OperationDescription,
    OperationName,
    SupportedOperationType,
} from '../../../utils/imageUtil';
import Blur from './Blur';
import Brightness from './Brightness';
import Circle from './Circle';
import Opacity from './Opacity';
import OperationSelector from './OperationSelector';
import Resize from './Resize';
import Rotate from './Rotate';
import { Expandable } from './ImageFormat';
import { Operation as OperationOptions, OperationType, Print } from '@amzn/forge-image-processing-types';

type SupportedOptions = Exclude<OperationOptions, Print>;

export interface OperationProps {
    index: number;
    total: number;
    options: OperationOptions;
    errors?: StringMap;
    onChange: (changed: OperationOptions) => void;
    onMoveUp: () => void;
    onMoveDown: () => void;
    onRemove: () => void;
}

const OperationComponents: Record<SupportedOperationType, React.FunctionComponent<any> | React.ComponentClass<any>> = {
    [OperationType.Blur]: Blur,
    [OperationType.Brightness]: Brightness,
    [OperationType.Circle]: Circle,
    [OperationType.Opacity]: Opacity,
    [OperationType.Resize]: Resize,
    [OperationType.Rotate]: Rotate,
};

function Operation(props: OperationProps): JSX.Element {
    const [expanded, setExpanded] = useState(false);
    const expandable = props.options as Expandable;
    useEffect(() => {
        setExpanded(expandable.expanded || false);
    });
    const handleExpandableChange = (e: CustomEvent<ExpandableSection.ChangeDetail>) => {
        expandable.expanded = e.detail.expanded || (props.options.type as string) == '';
        setExpanded(expandable.expanded);
    };
    const typeSelected = (type: SupportedOperationType) => {
        if ((type as string) !== '') {
            const operation = DefaultOperations[type] as SupportedOptions & Expandable;
            operation.expanded = true;
            props.onChange(operation);
        }
    };
    let name: string;
    let description: JSX.Element | string;
    let element: JSX.Element | undefined;
    switch (props.options.type as string) {
        case '':
            name = 'New operation';
            description = <OperationSelector onSelected={typeSelected} />;
            break;
        case OperationType.Print:
            throw new Error('Operation type Print is not yet supported');
        default:
            const type = props.options.type as SupportedOperationType;
            name = OperationName[type];
            description = OperationDescription[type];
            element = React.createElement(OperationComponents[type], props);
            break;
    }
    const isFirst = props.index == 0;
    const isLast = props.index + 1 == props.total;
    const canRemove = props.total > 1;
    return (
        <ExpandableSection header={name} expanded={expanded} onChange={handleExpandableChange}>
            <ColumnLayout columns={1}>
                <div data-awsui-column-layout-root="true">
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-xxxs-6 col-xxs-8 col-xs-9">{description}</div>
                            <div className="col-xxxs-6 col-xxs-4 col-xs-3">
                                <Button
                                    className="awsui-util-f-r"
                                    variant="icon"
                                    icon="close"
                                    onClick={props.onRemove}
                                    disabled={!canRemove}
                                ></Button>
                                <Button
                                    className="awsui-util-f-r"
                                    variant="icon"
                                    icon="angle-down"
                                    onClick={props.onMoveDown}
                                    disabled={isLast}
                                ></Button>
                                <Button
                                    className="awsui-util-f-r"
                                    variant="icon"
                                    icon="angle-up"
                                    onClick={props.onMoveUp}
                                    disabled={isFirst}
                                ></Button>
                            </div>
                        </div>
                    </div>
                    {element}
                </div>
            </ColumnLayout>
        </ExpandableSection>
    );
}

export default Operation;
