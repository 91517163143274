import { Button, ButtonDropdown } from '@amzn/awsui-components-react';

export interface PageHeaderProps {
    text?: string;
    buttons?: PageHeaderButton[];
}

export interface PageHeaderButton extends Button.Props {
    items?: PageHeaderButtonItem[];
}

export interface PageHeaderButtonItem extends ButtonDropdown.Item {
    onClick: (e: CustomEvent<ButtonDropdown.ItemClick>) => void;
}

function PageHeader(props: PageHeaderProps): JSX.Element {
    const itemClicked = (e: CustomEvent<ButtonDropdown.ItemClick>, button: PageHeaderButton) => {
        const item = button.items?.find((item) => item.id == e.detail.id);
        item?.onClick(e);
    };
    return (
        <div className="awsui-util-mb-m awsui-util-mt-xs">
            <div className="awsui-util-action-stripe-large">
                <div className="awsui-util-action-stripe-title">
                    <h1>{props.text}</h1>
                </div>
                <div className="awsui-util-action-stripe-group awsui-util-pv-n">
                    {props.buttons?.map((button, key) =>
                        !button.items ? (
                            <Button {...button} key={key} />
                        ) : (
                            <ButtonDropdown items={button.items} key={key} onItemClick={(e) => itemClicked(e, button)}>
                                {button.text}
                            </ButtonDropdown>
                        ),
                    )}
                </div>
            </div>
        </div>
    );
}

export default PageHeader;
