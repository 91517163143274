const STAGE_MAPPING = {
  'beta.forge.music.amazon.dev': 'beta',
  'gamma.forge.music.amazon.dev': 'gamma',
  'forge.music.amazon.dev': 'prod',
};

export default () => {
  if (process.env.REACT_APP_FORGE_ENVIRONMENT) {
    return process.env.REACT_APP_FORGE_ENVIRONMENT;
  }
  const currentHostName = window.location.hostname;
  return STAGE_MAPPING[currentHostName] ?? 'beta';
};